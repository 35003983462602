import { useState, useEffect, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ISearchBarProps,
  SelectedLocation,
} from '../../components/utils/SearchBar/SearchBar';
import { LandingPageBottomBanner } from './components/LandingPageBottomBanner';
import { LandingPageHeroSection } from './components/LandingPageHeroSection';
import { LandingPageLearnMoreCard } from './components/LandingPageLearnMoreCard';
import { LandingPagePhotoBanner } from './components/LandingPagePhotoBanner';
import { LandingPageTakeActionCard } from './components/LandingPageTakeActonCard';
import { LandingPageTestimonailCarousel } from './components/LandingPageTestimonialCarousel';
import { LandingPageTophealthcareNeeds } from './components/LandingPageTopHealthcareNeeds';
import { useContentful } from '../../hooks/Contentful/useContentful';
import { useTrackPageName } from '../../hooks/GoogleAnalytics/googleAnalytics';
import backgroundImg from '../../assets/images/BackgroundImages/LandingPageBgBlue.png';
import { Popup, PopupFormData } from '../../components/utils/MedicalDebtPopUp';

export interface LandingPageProps {}

export function LandingPage(props: LandingPageProps) {
  useTrackPageName();

  const navigate = useNavigate();
  const sectionRef = useRef<HTMLDivElement | null>(null);

  const queries = useMemo(
    () => [
      'heroSection',
      'firstImageBanner',
      'blobCta',
      'closingCta',
      'testimonials',
    ],
    []
  );

  const { contentfulData, isLoading } = useContentful(queries);

  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem('hasShownPopup')) {
      const timer = setTimeout(() => {
        setShowPopup(true);
        localStorage.setItem('hasShownPopup', 'true');
      }, 5000); // Show popup after 5 seconds

      return () => clearTimeout(timer);
    }
  }, []);

  function handleSearchSubmit(healthNeed: string, location: SelectedLocation) {
    const state: ISearchBarProps = {
      healthNeed,
      searchLocation: location,
    };
    navigate('/marketplace', { state: state });
  }

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const scrollToQuickLinks = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();
    sectionRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div
      className="flex flex-col items-center w-full animate-fadeIn"
      style={{
        backgroundImage: `url(${backgroundImg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed',
        backgroundPosition: 'center',
      }}
    >
      {!isLoading && (
        <div className="flex flex-col items-center w-full animate-fadeIn relative">
          <LandingPageHeroSection
            onSubmit={handleSearchSubmit}
            heroSectionData={contentfulData.heroSection}
          />
          <i
            onClick={scrollToQuickLinks}
            className="fa-solid fa-chevron-down hover:cursor-pointer hover:animate-bounce font-bold text-xl"
          ></i>
          {showPopup && (
            <div className="fixed top-20 right-4 flex items-center justify-center">
              <Popup onClose={handleClosePopup} />
            </div>
          )}
          <LandingPageTophealthcareNeeds sectionRef={sectionRef} />
          <LandingPagePhotoBanner
            photoBannerData={contentfulData.firstImageBanner}
          />
          <LandingPageTakeActionCard data={contentfulData.blobCta} />
          <LandingPageTestimonailCarousel data={contentfulData.testimonials} />
          <LandingPageLearnMoreCard data={contentfulData.closingCta} />

          <LandingPageBottomBanner />
        </div>
      )}
    </div>
  );
}
