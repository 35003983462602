import betterHelpLogo from '../../../assets/images/Logos/BetterHelp.svg';
import calmerryLogo from '../../../assets/images/Logos/Calmerry.svg';
import cerebralLogo from '../../../assets/images/Logos/CerebralLogo.svg';
import onlineTherapyLogo from '../../../assets/images/Logos/OnlineTherapy.svg';

import amazonClinicLogo from '../../../assets/images/Logos/AmazonClinicLogo.svg';
import cvsLogo from '../../../assets/images/Logos/CVS.svg';
import doctorsOnDemandLogo from '../../../assets/images/Logos/DoctorsOnDemand.svg';
import lemonaidHealthLogo from '../../../assets/images/Logos/LemonaidHealthLogo.svg';
import plushCareLogo from '../../../assets/images/Logos/PlushCareLogo.svg';
import walmartLogo from '../../../assets/images/Logos/WalmartIcon.svg';

export interface MentalHealthAndTelemedicine {
  name: string;
  website: string;
  description: string;
  icon: string;
}

const telemedicineResults: MentalHealthAndTelemedicine[] = [
  {
    name: 'Lemonaid Health',
    website: 'https://www.lemonaidhealth.com/',
    description:
      'Access premier online doctor and telehealth services from anywhere. Meet With Our Medical Team And Get Lemonaid Prescriptions Delivered To Your Door, for Free.',
    icon: lemonaidHealthLogo,
  },
  {
    name: 'Amazon Clinic',
    website: 'https://clinic.amazon.com/',
    description:
      "The doctor's orders, made easier. Amazon Clinic is a convenient way to find treatment online from a doctor or nurse practitioner for many common conditions.",
    icon: amazonClinicLogo,
  },
  {
    name: 'PlushCare',
    website: 'https://plushcare.com/',
    description:
      'Convenient telehealth visits. Book a virtual care visit, chat via video on your smartphone, and pick up your prescription from your pharmacy.',
    icon: plushCareLogo,
  },
  {
    name: 'Doctors On Demand',
    website: 'https://www.doctorondemand.com/',
    description:
      'See a doctor now! Get 24/7 online urgent care or schedule online therapy, all from your home. Your visit may be free, we accept insurance.',
    icon: doctorsOnDemandLogo,
  },
  {
    name: 'CVS Minute Clinic Virtual Care',
    website: 'https://www.cvs.com/minuteclinic/virtual-care',
    description:
      'MinuteClinic offers affordable, quality health care for you and your family. Make an appointment for your in-person or virtual visit today.',
    icon: cvsLogo,
  },
  {
    name: 'Walmart Virtual Care',
    website: 'https://www.walmarthealth.com/care/getVirtualCare',
    description:
      'Walmart Health Virtual Care (WHVC) offers medical and talk therapy services via telehealth to patients nationwide. Telehealth services may vary by state.',
    icon: walmartLogo,
  },
];

const mentalHealthResults: MentalHealthAndTelemedicine[] = [
  {
    name: 'BetterHelp',
    website: 'https://www.betterhelp.com/',
    description:
      'Get matched easily with a licensed, board-accredited therapist. More than 35000 therapists with 372 million+ counseling sessions are ready to help you!',
    icon: betterHelpLogo,
  },
  {
    name: 'Online Therapy',
    website: 'https://www.onlinetherapy.com/',
    description:
      'OnlineTherapy.com is an international online therapy directory connecting people with therapists worldwide. Whether you’re looking for counseling from the comfort of home, or a therapist seeking resources and guidance to take your practice online, we can help.',
    icon: onlineTherapyLogo,
  },
  {
    name: 'Cerebral',
    website: 'https://www.getcerebral.com/',
    description:
      'Cerebral takes a team approach to your care. Virtual telehealth in Arizona. Regular phone or video sessions, unlimited messaging, & prescriptions delivered if needed. Online. Psychiatry.',
    icon: cerebralLogo,
  },
  {
    name: 'Calmerry',
    website: 'https://www.calmerry.com/',
    description:
      'Improving well-being starts from taking care of your mental health. Begin making positive changes in your life with online therapy Calmerry.',
    icon: calmerryLogo,
  },
];

/**
 * This function is used to bypass the normal google search results and provide the user with a list of telemedicine and mental health services.
 *
 *
 * @param query is the type of service the user is looking for - specifically telemedicine or mental health
 * @returns MentalHealthAndTelemedicine[] - an array of objects containing the name, website, description, and icon of the service
 */
export function getMentalHealthAndTelemedicine(
  query: 'Telemedicine' | 'Mental Health'
): MentalHealthAndTelemedicine[] {
  if (query === 'Telemedicine') {
    // lemonaid, amazon pharmacy, cost plus, doctors on demand, cvs, walmart
    return telemedicineResults;
  } else {
    // better help, online therapy, cerebral, calmerry
    return mentalHealthResults;
  }
}
