import React, { useState, useEffect, useCallback } from 'react';

import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { useUserLocation } from '../../../providers/UserLocationProvider';
import { getHumanReadableLocation, healthNeedsList } from './services';
import { HealthNeedInput } from './components/HealthNeedInput';
import { LocationInput } from './components/LocationInput';

export interface ISearchBarProps {
  healthNeed?: string;
  searchLocation?: SelectedLocation;
  onSubmit?: (healthNeed: string, location: SelectedLocation) => void;
  getUserLocationMethod?: () => void;
}

export interface SelectedLocation {
  description: string;
  latLong: google.maps.LatLngLiteral;
}

export function SearchBar(props: ISearchBarProps) {
  let { getUserLocation } = useUserLocation();
  const userLocation = useUserLocation().location;

  // Primary fields for the search bar
  const [selectedHealthNeed, setSelectedHealthNeed] = useState('');
  const [selectedLocation, setSelectedLocation] = useState<{
    description: string;
    latLong: google.maps.LatLngLiteral;
  }>({ description: '', latLong: { lat: 0, lng: 0 } });

  // Validation states
  const [healthNeedValid, setHealthNeedValid] = useState(true);
  const [locationValid, setLocationValid] = useState(true);

  // Location state
  const [locationInput, setLocationInput] = useState('');
  const [locationListOpen, setLocationListOpen] = useState(false);

  // Health need state
  const [healthNeedInput, setHealthNeedInput] = useState('');
  const [healthNeedsOpen, setHealthNeedsOpen] = useState(false);

  const initializeUserLocation = useCallback(() => {
    const defaultLocation = {
      description: 'San Francisco, CA',
      latLong: { lat: 37.7749, lng: -122.4194 },
    };

    if (props.searchLocation) {
      setSelectedLocation(props.searchLocation);
      setLocationInput(props.searchLocation.description);
    } else {
      if (userLocation) {
        getHumanReadableLocation(userLocation).then((location) => {
          // get "City, State" from the user's location
          setLocationInput(location === 'undefined, undefined' ? '' : location);
          setSelectedLocation({
            description: location,
            latLong: userLocation,
          });
        });
      } else {
        // if not, set the default location to San Francisco, CA
        setLocationInput(defaultLocation.description);
        setSelectedLocation(defaultLocation);
      }
    }
  }, [props.searchLocation, userLocation]);

  useEffect(() => {
    // If the user location was passed in as a prop, set it as the selected location
    initializeUserLocation();

    // check to see if a health need and location were passed in as props and set them if they were
    if (props.healthNeed) {
      setHealthNeedInput(props.healthNeed);
      setSelectedHealthNeed(props.healthNeed);
    }
  }, [
    initializeUserLocation,
    props.healthNeed,
    props.searchLocation,
    userLocation,
  ]);

  /**
   * handles chaning the visual text of the health need input
   */
  function handleLocationInputChange(value: string): void {
    setLocationInput(value);

    // If the user changes the input, clear the selected location
    if (value !== selectedLocation.description) {
      setSelectedLocation({ description: '', latLong: { lat: 0, lng: 0 } });
    }
  }

  function handleSearchSubmit(): void {
    let isHealthNeedValid = true;
    let isLocationValid = true;

    if (!selectedHealthNeed) {
      isHealthNeedValid = false;
    }

    if (!selectedLocation.description) {
      isLocationValid = false;
    }

    setHealthNeedValid(isHealthNeedValid);
    setLocationValid(isLocationValid);

    if (isHealthNeedValid && isLocationValid) {
      // Call the callback function with the field values
      props.onSubmit?.(selectedHealthNeed, selectedLocation);
    } else {
      console.error('Invalid search');
    }
  }

  function handleLocationSelected(location: string): void {
    setLocationInput(location);

    // Geocode the selected location to get latitude and longitude
    geocodeByAddress(location)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        setSelectedLocation({
          description: location,
          latLong: {
            lat: latLng.lat,
            lng: latLng.lng,
          },
        });
      })
      .catch((error) => console.error('Error', error));

    setLocationListOpen(false);
  }

  function handleHealthNeedSelected(selectedHealthNeed: string): void {
    setHealthNeedInput(selectedHealthNeed);
    setSelectedHealthNeed(selectedHealthNeed);
    setHealthNeedsOpen(false);
  }

  async function onCurrentLocationSelected(): Promise<void> {
    let location = userLocation;

    // If the user does not have a location, attempt to get it
    if (location === null || location === undefined) {
      try {
        const updatedLocation = await getUserLocation();

        getHumanReadableLocation(updatedLocation).then((locationString) => {
          setLocationInput(
            locationString === 'undefined, undefined' ? '' : locationString
          );
          setSelectedLocation({
            description: locationString,
            latLong: updatedLocation,
          });
        });
      } catch (error) {
        console.error(error);
        alert('Please allow location access to use this feature');
        return;
      }
    }
  }

  return (
    <div className='text-left bg-white flex justify-between rounded-full shadow-custom3 border border-gray-200 w-full'>
      <HealthNeedInput
        healthNeedInput={healthNeedInput}
        setHealthNeedInput={setHealthNeedInput}
        healthNeedValid={healthNeedValid}
        setHealthNeedValid={setHealthNeedValid}
        healthNeedsOpen={healthNeedsOpen}
        setHealthNeedsOpen={setHealthNeedsOpen}
        handleHealthNeedSelected={handleHealthNeedSelected}
        healthNeeds={healthNeedsList}
      />

      <div className='border border-gray-100 h-full'></div>

      <LocationInput
        locationInput={locationInput}
        handleLocationInputChange={handleLocationInputChange}
        locationValid={locationValid}
        setLocationValid={setLocationValid}
        handleLocationSelected={handleLocationSelected}
        onCurrentLocationSelected={onCurrentLocationSelected}
        locationListOpen={locationListOpen}
        setLocationListOpen={setLocationListOpen}
      />

      <button
        onClick={handleSearchSubmit}
        className='text-white border-none h-full ml-8 text-3xl rounded-r-full w-[20%] xl:w-[10%] bg-rokketmedMain hover:bg-orange-500 hover:outline hover:outline-gray-300 hover:outline-2 ease-in-out transition-all duration-200'
      >
        <i className='fa fa-search' aria-hidden='true'></i>
      </button>
    </div>
  );
}
