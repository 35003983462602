import * as React from 'react';
import { NavLink } from 'react-router-dom';

export interface ILandingPageBottomBannerProps {}

export function LandingPageBottomBanner(props: ILandingPageBottomBannerProps) {
  return (
    <div className='bg-[#F63468] w-full py-4 lg:py-2 text-white flex text-center justify-center align-middle items-center flex-col lg:flex-row text-base lg:text-xl'>
      <p className='font-normal'>
        Interested in becoming a Health Card holder?
      </p>
      <NavLink
        to='/about-healthcard'
        className='font-bold ml-10 p-2 rounded-lg ease-in-out hover:font-extrabold transition-all duration-200 flex justify-center align-middle items-center'
      >
        Learn More <i className='fas fa-arrow-right text-white ml-2 '></i>
      </NavLink>
    </div>
  );
}
