import { BecomeACardHolder } from '../../../hooks/Contentful/Types/ContentfulTypes';

export interface IHealthCardInfoProps {
  data: BecomeACardHolder;
}

export function HealthCardInfo(props: IHealthCardInfoProps) {
  const {
    title,
    subtitle,
    bulletTitle,
    bulletDescription,
    bulletTitle2,
    bulletDescription2,
    bulletTitle3,
    bulletDescription3,
    bulletTitle4,
    bulletDescription4,
    bulletTitle5,
    bulletDescription5,
  } = props.data;

  return (
    <div>
      <div className="mb-5">
        <h1 className="text-white text-3xl lg:text-4xl font-bold pb-2">
          {title}
        </h1>
        <h3 className="text-white text-lg lg:text-2xl">{subtitle}</h3>
      </div>

      <ul className="list-none md:pl-5 text-white w-full md:lg:w-9/12 mb-10 md:mb-0">
        <div className="my-6">
          <li className='relative pl-5 before:content-["•"] before:absolute before:left-0 before:top-1/2 before:-translate-y-1/2 before:text-[#E30C79] before:text-2xl'>
            <h5 className="text-lg lg:text-xl font-medium">{bulletTitle}</h5>
          </li>
          <p className="text-sm ml-5 mt-2">{bulletDescription}</p>
        </div>
        <div className="my-6">
          <li className='relative pl-5 before:content-["•"] before:absolute before:left-0 before:top-1/2 before:-translate-y-1/2 before:text-[#E30C79] before:text-2xl'>
            <h5 className="text-lg lg:text-xl font-medium">{bulletTitle2}</h5>
          </li>
          <p className="text-sm ml-5 mt-2">{bulletDescription2}</p>
        </div>
        <div className="my-6">
          <li className='relative pl-5 before:content-["•"] before:absolute before:left-0 before:top-1/2 before:-translate-y-1/2 before:text-[#E30C79] before:text-2xl'>
            <h5 className="text-lg lg:text-xl font-medium">{bulletTitle3}</h5>
          </li>
          <p className="text-sm ml-5 mt-2">{bulletDescription3}</p>
        </div>
        <div className="my-6">
          <li className='relative pl-5 before:content-["•"] before:absolute before:left-0 before:top-1/2 before:-translate-y-1/2 before:text-[#E30C79] before:text-2xl'>
            <h5 className="text-lg lg:text-xl font-medium">{bulletTitle4}</h5>
          </li>
          <p className="text-sm ml-5 mt-2">{bulletDescription4}</p>
        </div>
        <div className="my-6">
          <li className='relative pl-5 before:content-["•"] before:absolute before:left-0 before:top-1/2 before:-translate-y-1/2 before:text-[#E30C79] before:text-2xl'>
            <h5 className="text-lg lg:text-xl font-medium">{bulletTitle5}</h5>
          </li>
          <p className="text-sm ml-5 mt-2">{bulletDescription5}</p>
        </div>
      </ul>
    </div>
  );
}
