import {
  SearchBar,
  SelectedLocation,
} from '../../../components/utils/SearchBar/SearchBar';

import '../../../App.css';
import { HeroSection } from '../../../hooks/Contentful/Types/ContentfulTypes';

export interface ILandingPageHeroSectionProps {
  onSubmit: (healthNeed: string, location: SelectedLocation) => void;
  heroSectionData?: HeroSection;
}

const styles = {
  preHeader: {
    // fontSize: '1.75rem',
    // fontWeight: 500,
    // borderColor: '#F63468',
  },
  hero: {
    // fontSize: '4.25rem',
    fontWeight: 700,
  },
  heroAccent: {
    color: '#FE794F',
  },
  subHeader: {
    color: '#233A65',
    opacity: 0.65,
    fontWeight: 500,
  },
};

export function LandingPageHeroSection(props: ILandingPageHeroSectionProps) {
  function addAccentToHeroText(text: string, accentText: string) {
    const accentTextIndex = text.indexOf(accentText);
    if (accentTextIndex === -1) {
      return text;
    }
    return (
      <span>
        {text.slice(0, accentTextIndex)}
        <span style={styles.heroAccent}>{accentText}</span>
        {text.slice(accentTextIndex + accentText.length)}
      </span>
    );
  }

  if (!props.heroSectionData) {
    return null;
  } else {
    return (
      <div className='flex w-full flex-col text-center items-center justify-center lg:px-20 px-5 pt-8 lg:pt-16 animate-fadeIn'>
        <div
          style={styles.preHeader}
          className='lg:text-2xl xs:text-xs text-base border-[#F63468] mx-auto px-10 py-4 rounded-full border border-solid m-10 bg-white flex items-center justify-center font-medium leading-none'
        >
          {props.heroSectionData.floatingTitle}
        </div>

        <h1
          style={styles.hero}
          className='xs:text-3xl md:text-4xl lg:text-7xl m-0 lg:w-10/12'
        >
          {addAccentToHeroText(props.heroSectionData.title, 'Affordable Care')}
        </h1>

        <h2
          style={styles.subHeader}
          className='xs:text-base lg:text-2xl mb-10 lg:mb-20 mt-6'
        >
          {props.heroSectionData?.subtitle}
        </h2>

        <div className='flex flex-col w-full lg:w-8/12 mb-16'>
          <SearchBar onSubmit={props.onSubmit} />
        </div>
      </div>
    );
  }
}
