import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';

import './App.css';

import Layout from './components/ui/Layout/Layout';
import MarketPlace from './pages/MarketPlace/MarketPlace';
import BecomeACardholder from './pages/Become A Cardholder/BecomeACardholder';
import AboutUs from './pages/About-Us/AboutUs';
import { UserLocationProvider } from './providers/UserLocationProvider';
import { ContactUs } from './pages/Contact-Us/ContactUs';
import { AboutHealthcard } from './pages/About-Healthcard/AboutHealthcard';
import { useEffect } from 'react';
import { Blog } from './pages/Blog/Blog';
import { LandingPage } from './pages/Landing Page/LandingPage';
import { PrivacyPolicy } from './pages/PrivacyPolicy/PrivacyPolicy';
import { TermsOfService } from './pages/TermsOfService/TermsOfService';
import ReactGA from 'react-ga4';
import { PageNotFound } from './pages/pageNotFound/PageNotFound';

const analyticsId = process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID || '';

ReactGA.initialize([
  {
    trackingId: analyticsId,
  },
]);

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <div className="appWrapper">
      <UserLocationProvider>
        <BrowserRouter>
          <ScrollToTop />
          <Layout>
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/marketplace" element={<MarketPlace />} />
              <Route path="/health-card" element={<BecomeACardholder />} />
              <Route
                path="/become-cardholder"
                element={<BecomeACardholder />}
              />
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/contact-us" element={<ContactUs />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/about-healthcard" element={<AboutHealthcard />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/terms-of-service" element={<TermsOfService />} />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </Layout>
        </BrowserRouter>
      </UserLocationProvider>
    </div>
  );
}

export default App;
