import { Carousel } from 'flowbite-react';
import * as React from 'react';

export interface CustomCarouselControls {
  leftControl: React.ReactNode;
  rightControl: React.ReactNode;
}

export interface ITestimonialCarouselProps {
  width?: string;
  rounded?: boolean;
  children: React.ReactNode;
  customControls?: CustomCarouselControls;
  backgroundColor?: string;
  textColor?: string;
}

export function TestimonialCarousel(props: ITestimonialCarouselProps) {
  return (
    <div
      style={{
        width: props.width ? props.width : '100%',
        backgroundColor: props.backgroundColor
          ? props.backgroundColor
          : 'white',
        color: props.textColor ? props.textColor : 'black',
      }}
      className={`items-center justify-center flex flex-col lg:mb-20 text-center  ${
        props.rounded ? 'rounded-3xl' : 'rounded-none'
      }`}
    >
      <h3 className="sm:text-3xl md:text-3xl lg:text-4xl font-semibold xs:mx-2 xs:text-2xl mt-10">
        See Why Customers Love RokketMed
      </h3>
      <Carousel
        pauseOnHover={true}
        indicators={false}
        draggable={true}
        theme={{
          root: {
            base: `relative h-full w-full items-center justify-center overflow-hidden`,
            leftControl: props.customControls
              ? 'absolute top-[40%] left-[25%] lg:right-[40%] flex h-full items-center justify-center px-4 focus:outline-none'
              : 'absolute top-[40%] left-[25%] lg:right-[40%] flex h-full items-center justify-center px-4 focus:outline-none',

            rightControl: props.customControls
              ? 'absolute top-[40%] right-[25%] lg:right-[40%] flex h-full items-center justify-center px-4 focus:outline-none'
              : 'absolute top-[40%] right-[25%] lg:right-[40%] flex h-full items-center justify-center px-4 focus:outline-none',
          },
          indicators: {
            active: {
              off: 'bg-white/50 hover:bg-white dark:bg-gray-800/50 dark:hover:bg-gray-800',
              on: 'bg-white dark:bg-gray-800',
            },
            base: 'h-3 w-3 rounded-full',
            wrapper:
              'absolute bottom-5 left-1/2 flex -translate-x-1/2 space-x-3',
          },
          item: {
            base: 'absolute md:top-1/2 left-1/2 block w-full -translate-x-1/2 -translate-y-1/2',
            wrapper: {
              off: 'w-full flex-shrink-0 transform cursor-default snap-center',
              on: 'w-full flex-shrink-0 transform cursor-grab snap-center',
            },
          },
          control: {
            base: 'inline-flex h-8 w-8 items-center justify-center rounded-full bg-white/30 group-hover:bg-white/50 group-focus:outline-none group-focus:ring-4 group-focus:ring-white dark:bg-gray-800/30 dark:group-hover:bg-gray-800/60 dark:group-focus:ring-gray-800/70 sm:h-10 sm:w-10',
            icon: 'h-5 w-5 text-white dark:text-gray-800 sm:h-6 sm:w-6',
          },
          scrollContainer: {
            base: 'flex h-full snap-mandatory overflow-y-hidden overflow-x-scroll scroll-smooth',
            snap: 'snap-x',
          },
        }}
        slideInterval={10000}
        leftControl={props.customControls?.leftControl ?? null}
        rightControl={props.customControls?.rightControl ?? null}
      >
        {props.children}
      </Carousel>
    </div>
  );
}
