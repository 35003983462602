import { NavLink } from 'react-router-dom';
import { ClosingCta } from '../../../hooks/Contentful/Types/ContentfulTypes';

export interface ILandingPageLearnMoreCardProps {
  data?: ClosingCta;
}

export function LandingPageLearnMoreCard(
  props: ILandingPageLearnMoreCardProps
) {
  if (!props.data) {
    return null;
  } else {
    const { title, description, featuredImage } = props.data;
    return (
      <div className=" md:mt-0 lg:mb-10 flex flex-col lg:flex-row lg:w-2/3 lg:h-[36em] justify-center items-center">
        <img
          src={featuredImage}
          alt=""
          className="aspect-square h-1/4 md:h-2/3 lg:h-2/3 bg-red-100 shadow-custom2 rounded-xl object-cover"
        />

        <div className="pl-10 text-center flex flex-col p-5 items-start md:lg:justify-center">
          <h5 className="text-3xl font-medium start-0 text-start pb-7">
            {title}
          </h5>
          <p className="text-start text-graySubtitle text-base lg:text-xl font-normal">
            {description}
          </p>

          <div className="flex flex-col mt-5 gap-2">
            <p className="text-start text-graySubtitle text-base lg:text-xl font-normal mb-5">
              Putting a RokketMed card in your employee's hands saves you and
              them money!
            </p>

            <NavLink
              to="/about-healthcard"
              className="bg-rokketmedMain p-2 rounded-lg text-white mr-4 w-36 hover:shadow-custom2"
            >
              See how
            </NavLink>
          </div>
        </div>
      </div>
    );
  }
}
