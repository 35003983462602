'use client';

import {
  APIProvider,
  AdvancedMarker,
  InfoWindow,
  Map,
  Pin,
} from '@vis.gl/react-google-maps';

import { useEffect, useState } from 'react';
import { PlaceResultWithDistance } from '../../Services/services';
import {
  IInfoWindowDisplayProps,
  InfoWindowDisplay,
} from './InfoWindowDisplay';

export interface IMapsProps {
  center: google.maps.LatLng | google.maps.LatLngLiteral | null | undefined;
  places: PlaceResultWithDistance[];
  onInfoWindowClicked: (placeId: string) => void;
}

export function MarketplaceResultsMap(props: IMapsProps) {
  const [infoWindowOpen, setInfoWindowOpen] = useState(false);
  const [position, setPosition] = useState<google.maps.LatLng | null>();
  const [zoom, setZoom] = useState<number | null>(12);

  useEffect(() => {
    setZoom(12); // This will trigger a re-render of the Map component
  }, [props.center, props.places]); // Re-run the effect when these props change

  function handleMarkerClick(marker: google.maps.LatLng | null) {
    setPosition(marker);
    setInfoWindowOpen(true);
  }

  function handleInfoWindowClose() {
    setPosition(null);
    setInfoWindowOpen(false);
  }

  const apiKey = process.env.REACT_APP_GOOGLE_API_KEY || '';
  if (!apiKey) {
    console.error('Google API key not found');
  }

  const mapId = process.env.REACT_APP_GOOGLE_MAPS_ID || '';
  if (!mapId) {
    console.error('Google Maps ID not found');
  }

  function getInfoWindowData(): IInfoWindowDisplayProps {
    const place = props.places.find(
      (place) =>
        place.geometry?.location?.lat() === position?.lat() &&
        place.geometry?.location?.lng() === position?.lng()
    );

    return {
      name: place?.name || '',
      address: place?.formatted_address || '',
      phone: place?.formatted_phone_number || '',
      imageUrl: place?.photos?.[0].getUrl() || '',
      icon: place?.icon || '',
      placeId: place?.place_id || '',
      websiteURL: place?.website || '',
    };
  }

  return (
    <APIProvider apiKey={apiKey}>
      <div className="animate-fadeIn w-full h-full">
        <Map
          defaultCenter={
            props.center instanceof google.maps.LatLng
              ? { lat: props.center.lat(), lng: props.center.lng() }
              : props.center || { lat: 0, lng: 0 }
          }
          zoom={zoom}
          onZoomChanged={() => setZoom(null)}
          mapId={mapId}
          gestureHandling={'greedy'}
          zoomControl={true}
          isFractionalZoomEnabled={true}
          mapTypeControl={true}
        >
          {props.places?.map((marker, index) => (
            <AdvancedMarker
              key={index}
              position={{
                lat: marker.geometry?.location?.lat() || 0,
                lng: marker.geometry?.location?.lng() || 0,
              }}
              onClick={(marker) => handleMarkerClick(marker.latLng)}
            >
              <Pin
                background={'#FF5B0F'}
                borderColor={'#F63468'}
                glyphColor={'#ffffff'}
              />
            </AdvancedMarker>
          ))}

          {infoWindowOpen && (
            <InfoWindow
              position={position}
              onCloseClick={handleInfoWindowClose}
            >
              <InfoWindowDisplay
                {...getInfoWindowData()}
                key={position?.lat()}
                onClick={props.onInfoWindowClicked}
              />
            </InfoWindow>
          )}
        </Map>
      </div>
    </APIProvider>
  );
}
