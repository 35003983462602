import { BlogAuthor } from './BlogAuthor';

export interface IBlogPostProps {
  title?: string;
  content?: string;
  author?: string;
  date?: string;
  img?: string;
  link?: string;
}

export function BlogPost(props: IBlogPostProps) {
  const { title, content, author, date, img } = props;
  const defaultImg =
    'https://images.unsplash.com/photo-1587854692152-cbe660dbde88?q=80&w=1469&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D';

  return (
    <div
      className="flex flex-col bg-white rounded-lg w-[369px] h-[29rem] p-5 shadow-custom2 hover:shadow-custom3 hover:bg-gray-100 cursor-pointer transition-all duration-300 ease-in-out"
      onClick={() =>
        window.open(props.link || 'https://rokketmed.com', '_blank')
      }
    >
      <img
        className="bg-[#FF5B0F29] rounded-lg h-44 w-full object-cover border-none outline-none"
        src={img || defaultImg}
        alt=""
      />

      <div className="flex flex-col justify-between flex-grow">
        <div className="mt-8 gap-2 flex flex-col items-start">
          <h2
            className="text-xl font-bold"
            style={{
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 2,
              overflow: 'hidden',
            }}
          >
            {title || ''}
          </h2>
          <p
            style={{
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 4,
              overflow: 'hidden',
            }}
            className="text-sm text-grayText font-normal w-full"
          >
            {content ?? ''}
          </p>
        </div>

        <BlogAuthor author={author} date={date} />
      </div>
    </div>
  );
}
