import { useMemo } from 'react';
import { useContentful } from '../../hooks/Contentful/useContentful';
import { AboutHealthcardInfo } from './components/AboutHealthcardInfo';
import { AboutHealthCardVideoBanner } from './components/AboutHealthcardVideoBanner';
import { AssociatedCompanies } from './components/AssociatedCompanies';
import { GetDemoCard } from './components/GetDemoCard';
import { useTrackPageName } from '../../hooks/GoogleAnalytics/googleAnalytics';

export interface IAboutHealthcardProps {}

export function AboutHealthcard(props: IAboutHealthcardProps) {
  useTrackPageName();

  const queries = useMemo(
    () => [
      'aboutHealthCardHero',
      'aboutSections',
      'rokketMedCustomers',
      'endOfAboutHealthCardCta',
    ],
    []
  ); // No dependencies as the array contents are static - will cause infine loop if not empty

  const { contentfulData, isLoading } = useContentful(queries);

  if (!isLoading) {
    return (
      <div className='lg:mt-16 flex flex-col w-full animate-fadeIn'>
        <AboutHealthCardVideoBanner data={contentfulData.aboutHealthCardHero} />
        <AboutHealthcardInfo data={contentfulData.aboutSections} />
        <AssociatedCompanies data={contentfulData.rokketMedCustomers} />
        <GetDemoCard data={contentfulData.endOfAboutHealthCardCta} />
      </div>
    );
  } else {
    return null;
  }
}
