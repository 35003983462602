import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

export function useTrackPageName() {
  let location = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      title: location.pathname,
    });
  }, [location]);
}

export function useTrackMarktetplaceSearch(searchTerm: string) {
  useEffect(() => {
    ReactGA.event({
      category: 'Marketplace',
      action: 'Marketplace Search',
      label: searchTerm,
    });
  }, [searchTerm]);
}

export function useTrackEvent(event: string, data: any) {
  // ReactGA.event({
  //   category: 'Healthcare Grid',
  //   action: 'Healthcare Grid Button Clicked',
  //   label: searchTerm,
  // });
}
