import { NavLink } from 'react-router-dom';

import { BlobCta } from '../../../hooks/Contentful/Types/ContentfulTypes';

export interface ILandingPageTakeActionCardProps {
  data?: BlobCta;
}

export function LandingPageTakeActionCard(
  props: ILandingPageTakeActionCardProps
) {
  if (!props.data) {
    return null;
  } else {
    const { title, description, circleImage } = props.data;
    return (
      <div className="lg:my-36 my-24 bg-rokketmedMain rounded-3xl flex w-10/12 justify-items-start p-2 shadow-custom2">
        <div className="mx-auto flex flex-col lg:flex-row py-5 lg:py-10 items-center justify-center align-middle w-11/12 lg:w-8/12 text-center lg:text-start">
          <img
            src={circleImage}
            alt=""
            className="rounded-full h-40 w-auto lg:h-72 bg-white text-center justify-center flex items-center object-cover"
          />

          <div className="mt-5 lg:mt-0 lg:ml-20 flex-col h-full w-full flex items-center lg:items-start justify-center lg:text">
            <p className="text-xl lg:text-2xl text-white font-bold mb-4">
              {title}
            </p>
            <p className="text-white">{description}</p>

            <NavLink
              to="/become-cardholder"
              className="bg-white py-3 px-10 rounded-lg mt-5 text-rokketmedMain xs:text-sm lg:text-md"
            >
              Become a health card holder
            </NavLink>
          </div>
        </div>
      </div>
    );
  }
}
