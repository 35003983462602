import { useState } from 'react';

export interface ITeamMemberCardProps {
  photo?: string;
  name?: string;
  position?: string;
  description?: string;
  className?: string;
  linkedInUrl?: string;
}

export function TeamMemberCard(props: ITeamMemberCardProps) {
  const [isFocused, setIsFocused] = useState(false);

  function getMaxHeight() {
    const descriptionLength = props.description?.length ?? 0;

    const screenSize = window.innerWidth;

    const aproxLines = descriptionLength / (screenSize > 1024 ? 50 : 42);

    const height = aproxLines * 3.15;

    if (screenSize < 1024) {
      // mobile view
      if (height < 35 || aproxLines < 10) return '45rem';
      return `${height}rem`;
    } else {
      // desktop view
      if (height < 35 || aproxLines < 10) return '35rem';
      return `${height}rem`;
    }
  }

  return (
    <div
      className={`${props.className} m-5 p-5 rounded-lg shadow-custom2 outline outline-gray-100 min-w-60 w-[22rem] overflow-hidden hover:shadow-custom3 hover:bg-gray-100 cursor-pointer transition-all duration-300 ease-in-out`}
      style={{
        cursor: 'pointer',
        height: isFocused ? getMaxHeight() : '35rem',
      }}
      onClick={() => setIsFocused(!isFocused)}
      onBlur={() => setIsFocused(false)}
      tabIndex={0}
    >
      <img
        src={
          props.photo ??
          'https://images.unsplash.com/photo-1582213782179-e0d53f98f2ca?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
        }
        alt=''
        className='mx-auto rounded-lg mb-5 h-64 w-64 object-cover object-center hover:bg-whitetransition-all duration-300 ease-in-out'
      />
      <div>
        <div className='mb-3'>
          <h6 className='font-bold text-xl'>{props.name ?? 'Tracy Baldwin'}</h6>{' '}
          <div className='flex center items-center'>
            <p className='text-graySubtitle text-sm'>{props.position ?? ''}</p>

            {props.linkedInUrl && (
              <a
                href={props.linkedInUrl}
                target='_blank'
                rel='noopener noreferrer'
              >
                <i className='fab fa-linkedin text-rokketmedMain ml-3'></i>
              </a>
            )}
          </div>
        </div>

        <p
          className='text-graySubtitle mb-2 text-sm line-clamp-[1]'
          style={{
            overflow: 'hidden',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: isFocused ? '100' : '9',
          }}
        >
          {props.description ?? ''}
        </p>
      </div>
    </div>
  );
}
