import { useState, useEffect, useRef } from 'react';

export interface ICustomDropDownProps {
  label: string;
  children: React.ReactNode;
  inLine?: boolean | undefined;
}

export function CustomDropDown(props: ICustomDropDownProps) {
  const [isOpen, setIsOpen] = useState(false);
  const { label, children, inLine } = props;

  const node = useRef<HTMLDivElement>(null);

  const handleBlur = (e: FocusEvent) => {
    if (node.current && !node.current.contains(e.relatedTarget as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    const currentNode = node.current;
    if (currentNode) {
      currentNode.addEventListener('blur', handleBlur, true);
    }
    return () => {
      if (currentNode) {
        currentNode.removeEventListener('blur', handleBlur, true);
      }
    };
  }, []);

  if (inLine) {
    return (
      <div
        className='relative inline-block text-left text-inherit bg-white '
        ref={node}
        tabIndex={-1}
      >
        <span
          onClick={() => setIsOpen(!isOpen)}
          className={`cursor-pointer hover:text-rokketmedMain no-underline ${
            isOpen ? 'text-rokketmedMain' : ''
          }`}
        >
          {label}
        </span>
        {isOpen && (
          <div className='origin-top-right absolute right-0 left-0 mt-2 w-fit rounded-md shadow-lg z-50'>
            <div className='rounded-md bg-white shadow-xs border border-gray-200'>
              <div
                className='py-1 flex flex-col items-start'
                role='menu'
                aria-orientation='vertical'
                aria-labelledby='options-menu'
              >
                {children}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <div
      className='relative inline-block text-left bg-white '
      style={{ zIndex: 9999 }}
    >
      <span className='rounded-md shadow-sm'>
        <button
          onClick={() => setIsOpen(!isOpen)}
          type='button'
          className={`inline-flex justify-center w-full rounded-md border border-gray-200 px-4 py-2 bg-white leading-5 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150 ${
            isOpen ? 'text-rokketmedMain' : ''
          }`}
        >
          {label}
        </button>
      </span>

      {isOpen && (
        <div className='origin-top-right absolute right-0 left-0 mt-2 w-fit rounded-md shadow-lg z-50'>
          <div className='rounded-md bg-white shadow-xs px-4 border border-gray-200'>
            <div
              className='py-1 flex flex-col items-start'
              role='menu'
              aria-orientation='vertical'
              aria-labelledby='options-menu'
            >
              {children}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
