const API_URL = process.env.REACT_APP_AWS_SES_URL || '';

export interface EmailData {
  subject: string;
  data: Record<string, string>;
}

export async function sendEmail(emailData: EmailData) {
  try {
    const response = await fetch(API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(emailData),
    });

    if (!response.ok) {
      throw new Error(
        `There was an error sending the email. Status: ${response.status} ${response.statusText}`
      );
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}
