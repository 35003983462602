import { useTrackPageName } from '../../hooks/GoogleAnalytics/googleAnalytics';
import { ContactUsForm } from './components/ContactUsForm';
import { ContactUsInfo } from './components/ContactUsInfo';

export interface IContactUsProps {}

export function ContactUs(props: IContactUsProps) {
  useTrackPageName();
  return (
    <div className="bg-rokketmedMain w-full flex flex-col items-center h-full animate-fadeIn">
      <div className="flex items-start flex-col lg:flex-row content-between justify-between py-20 px-5 lg:px-36 w-full ">
        <ContactUsInfo />

        <ContactUsForm />
      </div>
    </div>
  );
}
