export interface IBlogAuthorProps {
  author?: string;
  date?: string;
}

export function BlogAuthor(props: IBlogAuthorProps) {
  const { author, date } = props;

  return (
    <div className='flex align-middle'>
      <div className='flex flex-col'>
        <h3 className='text-xs font-medium text-black'>{author || 'Author'}</h3>
        <p className='text-xs text-grayText'>{date || 'MMM DD, YYYY'}</p>
      </div>
    </div>
  );
}
