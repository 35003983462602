import { Testimonial } from '../../../components/utils/Testimonial';
import { TestimonialCarousel } from '../../../components/utils/TestimonialCarousel';
import { TestimonialData } from '../../../hooks/Contentful/Types/ContentfulTypes';

export interface ILandingPageTestimonailCarouselProps {
  data: TestimonialData[];
}

export function LandingPageTestimonailCarousel(
  props: ILandingPageTestimonailCarouselProps
) {
  const testimonials = props.data;

  return (
    <TestimonialCarousel
      rounded={false}
      width='100%'
      backgroundColor='#12B0FB'
      textColor='white'
      customControls={{
        leftControl: (
          <div className='p-5 h-8 w-8 rounded-full flex items-center justify-center hover:bg-gray-100 text-black ease-in-out transition-all duration-200'>
            <i className='fas fa-arrow-left text-white text-3xl'></i>
          </div>
        ),
        rightControl: (
          <div className='p-5 h-8 w-8 rounded-full flex items-center justify-center hover:bg-gray-100 text-black ease-in-out transition-all duration-200'>
            <i className='fas fa-arrow-right text-white text-3xl'></i>
          </div>
        ),
      }}
    >
      {testimonials.map((testimonial: TestimonialData, index) => (
        <Testimonial
          key={index}
          image={testimonial.logo}
          quote={testimonial.testimonial}
          author={testimonial.name}
          authorTitle={testimonial.position}
          company={testimonial.companyName}
          textColor='white'
        />
      ))}
    </TestimonialCarousel>
  );
}
