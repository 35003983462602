import { RokketMedCustomers } from '../../../hooks/Contentful/Types/ContentfulTypes';

export interface IAssociatedCompaniesProps {
  data: RokketMedCustomers;
}

export function AssociatedCompanies(props: IAssociatedCompaniesProps) {
  const title = props.data.title;
  const images = props.data.logos;

  return (
    <div className="w-full flex flex-col items-center justify-center pt-14 pb-10 px-5">
      <h1 className="text-2xl lg:text-5xl underline font-medium mb-8">
        {title}
      </h1>

      <div className="flex flex-wrap items-center justify-center">
        {images.map((image, index) => (
          <img
            src={image}
            key={index}
            alt=""
            className="m-4 self-center xs:w-24 h-auto lg:w-40"
          />
        ))}
      </div>
    </div>
  );
}
