import { loadingType } from '../../MarketPlace';
import { MarketplaceResultsMap } from '../marketplaceResultsMap/MarkeplaceResultsMap';
import { MarketplaceResultsList } from '../marketplaceResultsList/MarketplaceResultsList';
import { PlaceResultWithDistance } from '../../Services/services';
import { MarketplaceViewType, View } from '../MarketplaceResults';
import { useState } from 'react';

export interface IMarketplaceMobileViewProps {
  view: View.Mobile | View.Desktop;
  showSpinner: boolean;
  loading: loadingType;
  userLocation: google.maps.LatLngLiteral;
  results: PlaceResultWithDistance[];
  viewType: MarketplaceViewType;
  setViewType: (viewType: MarketplaceViewType) => void;
}

/**
 * This is the component that contains both the map and result list view.
 * If the view is currently on mobile, it will display a top bar with a toggle button on the top
 * to switch between the map and list view.
 *
 * If the view is on desktop, it will display the map and list view side by side.
 *
 * @param view View.Mobile | View.Desktop view
 *
 */
export function MarketplaceMobileView(props: IMarketplaceMobileViewProps) {
  const { results, viewType, userLocation } = props;

  const [selectedPlaceId, setSelectedPlaceId] = useState<string | null>(null);

  const handleToggleView = (placeId: string) => {
    setSelectedPlaceId(placeId);
    if (viewType === MarketplaceViewType.Map) {
      props.setViewType(MarketplaceViewType.List);
    } else {
      props.setViewType(MarketplaceViewType.Map);
    }
  };

  return (
    <div className='w-full h-full flex flex-col'>
      {viewType === MarketplaceViewType.Map && (
        <MarketplaceResultsMap
          center={userLocation}
          places={results}
          onInfoWindowClicked={handleToggleView}
        />
      )}

      {viewType === MarketplaceViewType.List && (
        <MarketplaceResultsList
          results={results}
          focusPlaceId={selectedPlaceId || undefined}
        />
      )}
    </div>
  );
}
