import PlacesAutocomplete from 'react-places-autocomplete';
import { SearchBarDropDown } from './SearchBarDropDown';

export interface LocationInputProps {
  locationInput: string;
  handleLocationInputChange: (value: string) => void;
  locationValid: boolean;
  setLocationValid: (value: boolean) => void;
  locationListOpen: boolean;
  setLocationListOpen: (value: boolean) => void;
  handleLocationSelected: (location: string) => void;
  onCurrentLocationSelected: () => void;
}

export function LocationInput(props: LocationInputProps) {
  return (
    <div className="flex py-1 w-[35%] overflow-hidden">
      <div className="ml-6 lg:ml-8">
        <label htmlFor="locationInput" className="color-textLight text-xs p-0">
          Location
        </label>
        <PlacesAutocomplete
          value={props.locationInput}
          onChange={props.handleLocationInputChange}
          searchOptions={{
            types: ['(regions)'],
            componentRestrictions: { country: 'us' },
          }}
          onError={(status, clearSuggestions) => {
            console.error('Error fetching location predictions:', status);
          }}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div>
              <input
                {...getInputProps({
                  placeholder: 'Select A Location',
                  className: `flex-1 h-8 xs:text-xs lg:md:text-sm lg:text-lg min-w-80 outline-none pl-1 m-0 font-normal focus:border-red-500 overflow-hidden 
                    ${
                      !props.locationValid
                        ? 'border border-red-500 bg-red-50'
                        : 'border-none focus:ring-0'
                    }`,
                  onFocus: () => {
                    props.setLocationValid(true);
                    props.setLocationListOpen(true);
                  },
                  onBlur: () =>
                    setTimeout(() => props.setLocationListOpen(false), 200),
                })}
              />

              {props.locationListOpen && (
                <SearchBarDropDown
                  filterText={props.locationInput}
                  onDropDownItemSelected={props.handleLocationSelected}
                  dropDownItems={suggestions.map((suggestion) => ({
                    description: suggestion.description,
                    props: getSuggestionItemProps(suggestion),
                  }))}
                  isLocation={true}
                  onCurrentLocationSelected={props.onCurrentLocationSelected}
                />
              )}
            </div>
          )}
        </PlacesAutocomplete>
      </div>
    </div>
  );
}
