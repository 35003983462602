import { PlaceResultWithDistance } from '../../../Services/services';
import { forwardRef, useEffect, useState } from 'react';
import { MentalHealthAndTelemedicine } from '../../../Services/telemedicineAndMentalHealthResults';
import { GoogleMarketplaceResult } from './components/GoogleMarketplaceResult';
import { MentalHealthAndTelemedicineResult } from './components/MentalHealthAndTelemedicineResult';

export interface IMarketplaceResultProps {
  resultDetails: PlaceResultWithDistance | MentalHealthAndTelemedicine;
  isClosestMatch: boolean;
  isFocused: boolean;
}

export const MarketplaceResult = forwardRef<
  HTMLDivElement,
  IMarketplaceResultProps
>((props, ref) => {
  const [isTemporarilyFocused, setIsTemporarilyFocused] = useState(false);

  useEffect(() => {
    //to show the background color of the focused result for 5 seconds
    if (props.isFocused) {
      setIsTemporarilyFocused(true);
      const timer = setTimeout(() => {
        setIsTemporarilyFocused(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [props.isFocused]);

  function getClosingTime(
    place: google.maps.places.PlaceResult
  ): string | undefined {
    // check to see that details?.opening_hours?.weekday_text is not undefined
    if (place.opening_hours?.weekday_text) {
      // get the current day of the week
      const day = new Date().getDay();
      const hours = place.opening_hours?.weekday_text[day];

      // if the current day returns 'Weekday: Closed' then return 'Closed'
      if (hours?.includes('Closed')) {
        return 'Closed';
      }
      const closingTime: string | undefined = hours?.split('–')[1];
      // format 'Weekday: 8:30 AM – 5:00 PM' to '5pm' unless it is not closing on the hour

      if (!closingTime) {
        return '';
      }

      return `Closes at ${
        closingTime?.includes(':00')
          ? `${closingTime.split(':')[0]}pm`
          : closingTime
      }`;
    }
  }

  return (
    <div
      ref={ref}
      className={`flex flex-col max-h-80 w-full overflow-x-hidden align-middle border-b border-gray-300 lg:px-10 p-5 text-blue-gray-600 animate-fadeIn 
      ease-in transition-all duration-100
      ${isTemporarilyFocused ? ' bg-[rgba(255,91,15,0.08)]' : ''}`}
    >
      {'place_id' in props.resultDetails ? ( // if the result has a place_id, it is a google result
        <GoogleMarketplaceResult
          resultDetails={props.resultDetails}
          isClosestMatch={props.isClosestMatch}
          closingTime={getClosingTime(props.resultDetails)}
        />
      ) : (
        // if the result does not have a place_id, it is a MentalHealthAndTelemedicine result
        <MentalHealthAndTelemedicineResult
          resultDetails={props.resultDetails as MentalHealthAndTelemedicine}
        />
      )}
    </div>
  );
});
