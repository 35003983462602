import { NavLink } from 'react-router-dom';

// import backgroundImage from '../../../assets/images/OnDemandHealthCare.png';
import { FirstImageBanner } from '../../../hooks/Contentful/Types/ContentfulTypes';

export interface LandingPagePhotoBannerProps {
  photoBannerData: FirstImageBanner;
}

let whiteBoardAnimationVideo = 'https://www.youtube.com/watch?v=_CVdshm53Vk';

export function LandingPagePhotoBanner(props: LandingPagePhotoBannerProps) {
  if (!props.photoBannerData) {
    return null;
  } else {
    const { title, backgroundImage, description } = props.photoBannerData;

    return (
      <div
        className='w-full h-[36rem] flex items-end lg:mt-32 mt-24 '
        style={{
          backgroundImage: 'url(' + backgroundImage + ')',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <div className='w-full h-full bg-slate-600 bg-opacity-20 flex items-end lg:mt-32 mt-24'>
          <div className='relative lg:w-2/5 rounded-xl z-10 p-5 lg:pl-20 lg:pb-[6rem]'>
            <h2 className='text-left text-2xl lg:text-3xl my-6 font-bold text-white'>
              {title}
            </h2>
            <p className='font-semibold text-white'>{description}</p>
            <div className='pt-5'>
              <NavLink
                to='/about-us'
                className='bg-rokketmedMain p-3 rounded-lg text-white mr-5'
              >
                Learn more
              </NavLink>
              <button
                onClick={() => window.open(whiteBoardAnimationVideo, '_blank')}
                className='bg-white p-3 rounded-lg text-rokketmedMain'
              >
                <i className='fas fa-video text-rokketmedMain mx-2'></i>
                How it works
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
