import { useState, useEffect, useMemo } from 'react';
import { BlogPost } from './components/BlogPost/BlogPost';
import { useTrackPageName } from '../../hooks/GoogleAnalytics/googleAnalytics';
import { useContentful } from '../../hooks/Contentful/useContentful';
import { BlogPostData } from '../../hooks/Contentful/Types/ContentfulTypes';

export interface IBlogProps {}

export function Blog(props: IBlogProps) {
  const queries = useMemo(() => ['blogPost'], []);

  const { contentfulData, isLoading } = useContentful(queries);

  useTrackPageName();

  const [posts, setPosts] = useState<BlogPostData[]>([]);

  useEffect(() => {
    if (contentfulData) {
      const blogPostData = contentfulData['blogPost'];
      if (blogPostData) {
        setPosts(blogPostData);
      }
    }
  }, [contentfulData]);

  return (
    <div className="flex flex-col w-full my-12 lg:mx-28 items-center animate-fadeIn">
      <h1 className="xs:text-4xl lg:text-5xl font-bold pb-5 mx-auto">
        Our Blog
      </h1>
      <p className="xs:text-xl lg:text-2xl font-normal leading-8 text-center text-grayText">
        Empowering Your Health Journey: Inside
        <span className="text-rokketmedMain"> RokketMeds'</span> Innovative
        Solutions
      </p>

      <div className="flex w-full flex-wrap items-center justify-center my-10 gap-10">
        {/* Loading */}
        {isLoading && (
          <div className="flex items-center justify-center my-10">
            <p className="text-xl font-bold">Loading...</p>
          </div>
        )}

        {/* Success */}
        {!isLoading &&
          posts.length > 0 &&
          posts.map((post, index) => (
            <BlogPost
              key={index}
              title={post.title}
              content={toText(post.description)}
              author={post.author}
              date={post.date}
              img={post.featuredImage}
              link={post.linkUrl}
            />
          ))}

        {/* No posts found */}
        {!isLoading && posts.length === 0 && (
          <div className="flex items-center justify-center my-10">
            <p className="text-xl font-bold">No posts found...</p>
          </div>
        )}
      </div>
    </div>
  );
}

function toText(node: any) {
  let tag = document.createElement('div');
  tag.innerHTML = node;
  node = tag.innerText;
  return node;
}
