import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import HeaderNavLink from './HeaderNavLink';
import { CustomDropDown } from '../../../utils/CustomDropDown';

function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const checkMobile = () => {
    setIsMobile(window.innerWidth <= 1024);
  };

  useEffect(() => {
    checkMobile();
    window.addEventListener('resize', checkMobile);

    return () => {
      window.removeEventListener('resize', checkMobile);
    };
  }, []);

  useEffect(() => {
    // Close the menu when the user clicks outside
    const handleClick = (e: MouseEvent) => {
      if (isOpen) {
        if (
          e.target instanceof Element &&
          !e.target.closest('.text-gray-500')
        ) {
          setIsOpen(false);
        }
      }
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [isOpen]);

  return (
    <nav className="shadow-custom2 flex flex-col px-2 lg:px-12 py-4 items-center h-[4.5rem] w-full relative p-1 text-gray-500 text-sm font-medium">
      <div className="flex justify-between relative p-1 text-gray-500 gap-6 text-sm font-medium w-full items-center">
        <NavLink
          to="/"
          className="bg-inherit border-none text-rokketmedMain text-center align-middle text-3xl"
        >
          <img
            src={require('../../../../assets/Icons/RokketMedLogo.svg').default}
            alt="RokketMed"
          />
        </NavLink>

        {!isMobile && (
          <div className="flex items-center gap-10">
            <CustomDropDown label="I'm a Health Card Holder" inLine>
              <a
                href="https://rokketmed.summitfor.me/"
                rel="noreferrer"
                target="_blank"
              >
                <button className="py-2 px-4 w-full hover:bg-gray-100 hover:text-rokketmedMain flex items-start text-start whitespace-nowrap">
                  Check your Balance
                </button>
              </a>
              <NavLink
                to="/contact-us"
                className="py-2 px-4 w-full hover:bg-gray-100 hover:text-rokketmedMain flex items-start text-start whitespace-nowrap"
              >
                Contact Us
              </NavLink>
            </CustomDropDown>
            <CustomDropDown label="Become a Health Card Holder" inLine>
              <NavLink
                to="/about-healthcard"
                className="py-2 px-4 w-full hover:bg-gray-100 hover:text-rokketmedMain flex items-start text-start whitespace-nowrap"
              >
                About the Health Card
              </NavLink>
              <NavLink
                to="/become-cardholder"
                className="py-2 px-4 w-full hover:bg-gray-100 hover:text-rokketmedMain flex items-start text-start whitespace-nowrap"
              >
                Sign Up
              </NavLink>
            </CustomDropDown>
            <CustomDropDown label="About Us" inLine>
              <NavLink
                to="/about-us"
                className="py-2 px-4 w-full hover:bg-gray-100 hover:text-rokketmedMain flex items-start text-start whitespace-nowrap"
              >
                Learn More
              </NavLink>
              <NavLink
                to="/blog"
                className="py-2 px-4 w-full hover:bg-gray-100 hover:text-rokketmedMain flex items-start text-start whitespace-nowrap"
              >
                Blog
              </NavLink>
              <NavLink
                to="/contact-us"
                className="py-2 px-4 w-full hover:bg-gray-100 hover:text-rokketmedMain flex items-start text-start whitespace-nowrap"
              >
                Contact Us
              </NavLink>
            </CustomDropDown>
          </div>
        )}

        {isMobile && (
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="lg:hidden p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-gray-200"
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-5 h-5"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>
        )}
      </div>

      {isMobile && isOpen && (
        <div className="px-10 py-5 z-50 mx-5 text-gray-500 flex flex-col items-start gap-6 text-sm font-medium shadow-custom2 bg-white w-[100vw]">
          <CustomDropDown label="Health Card" inLine>
            <NavLink
              onClick={() => setIsOpen(false)}
              to="/about-healthcard"
              className="py-2 px-4 w-full hover:bg-gray-100 hover:text-rokketmedMain flex items-start text-start whitespace-nowrap"
            >
              About
            </NavLink>
            <a
              href="https://rokketmed.summitfor.me/"
              rel="noreferrer"
              target="_blank"
            >
              <button className="py-2 px-4 w-full hover:bg-gray-100 hover:text-rokketmedMain flex items-start text-start whitespace-nowrap">
                Cardholder Portal
              </button>
            </a>
          </CustomDropDown>

          <NavLink
            to="/become-cardholder"
            className={'hover:text-rokketmedMain'}
            onClick={() => setIsOpen(false)}
          >
            Become a Health Card Holder
          </NavLink>
          <CustomDropDown label="About Us" inLine>
            <NavLink
              onClick={() => setIsOpen(false)}
              to="/about-us"
              className="py-2 px-4 w-full hover:bg-gray-100 hover:text-rokketmedMain flex items-start text-start whitespace-nowrap"
            >
              Learn More
            </NavLink>
            <NavLink
              onClick={() => setIsOpen(false)}
              to="/blog"
              className="py-2 px-4 w-full hover:bg-gray-100 hover:text-rokketmedMain flex items-start text-start whitespace-nowrap"
            >
              Blog
            </NavLink>
            <NavLink
              onClick={() => setIsOpen(false)}
              to="/contact-us"
              className="py-2 px-4 w-full hover:bg-gray-100 hover:text-rokketmedMain flex items-start text-start whitespace-nowrap"
            >
              Contact Us
            </NavLink>
          </CustomDropDown>
        </div>
      )}
    </nav>
  );
}

export default Header;
