import { PlaceResultWithDistance } from '../../../../Services/services';
import { StarRating } from './StarRating';

export interface IGoogleResultProps {
  resultDetails: PlaceResultWithDistance;
  isClosestMatch: boolean;
  closingTime: string | undefined;
}

export function GoogleMarketplaceResult(props: IGoogleResultProps) {
  return (
    <>
      {props.isClosestMatch && (
        <div className="bg-[#FF5B0F29] w-32 mb-2 p-0.5 ml-2 text-sm rounded-lg text-center text-rokketmedMain font-semibold">
          Closest Match
        </div>
      )}

      <div className="flex w-full p-2 overflow-x-hidden">
        <div className="h-20 w-20 aspect-square rounded-full overflow-hidden flex-shrink-0">
          {props.resultDetails?.photos &&
          props.resultDetails?.photos?.length > 0 ? (
            <img
              src={props.resultDetails?.photos[0].getUrl()}
              alt=""
              className="object-cover w-full h-full outline-none bg-gray-200"
            />
          ) : (
            <img
              src={props.resultDetails.icon}
              alt=""
              className="object-cover w-full h-full outline-none bg-gray-200"
            />
          )}
        </div>

        <div className="flex-col ml-4 lg:mx-2 text-greyText w-full">
          <p className="font-sans text-base text-darkText font-semibold leading-6 align-left max-w-full text-wrap">
            {props.resultDetails?.name}
          </p>
          <div className="text-sm">
            <p className="w-3/6">{props.closingTime}</p>
          </div>
          <div className="flex gap-3 my-2 text-sm">
            <p className="font-medium text-darkText">
              {props.resultDetails?.distance || ''}
            </p>
            <a
              href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                props.resultDetails?.formatted_address || ''
              )}`}
              className="text-blue-700 text-sm hover:underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              {props.resultDetails?.formatted_address}
            </a>
          </div>
          <div className="flex gap-4 items-center my-1 text-sm">
            <i className="fa-solid fa-phone text-darkText"></i>
            <a
              href={`tel:+1${props.resultDetails?.formatted_phone_number}`}
              className="text-blue-700 text-sm hover:underline"
            >
              {props.resultDetails?.formatted_phone_number}
            </a>
          </div>

          {props.resultDetails?.website && (
            <div className="flex gap-4 items-center my-1 text-sm">
              <a
                href={props.resultDetails?.website}
                target="_blank"
                rel="noreferrer"
                className="align-middle text-center text-blue-700 hover:underline"
              >
                <i className="fa-regular fa-calendar text-xl pr-3 text-darkText " />
                Schedule Now
              </a>
            </div>
          )}

          <div className="flex gap-4 items-center my-1 text-sm">
            <StarRating rating={props.resultDetails?.rating || 0} />
            <span>
              {'('}
              {(props.resultDetails?.reviews?.length || 0) + ' '}
              {(props.resultDetails?.reviews?.length || 0) === 1
                ? 'rating'
                : 'ratings'}
              {')'}
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
