import { useState, useEffect } from 'react';
import { formatContentfulData } from '../../hooks/Contentful/Types/ContentfulTypes';
import { fetchEntries } from './contenful';

export const useContentful = (queries: string[]) => {
  const [contentfulData, setContentfulData] = useState<{ [key: string]: any }>(
    {}
  );
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const entries = await Promise.all(
          queries.map((query) =>
            fetchEntries(query).then((rawData) => {
              return formatContentfulData(rawData, query);
            })
          )
        );
        const newData = Object.assign(
          {},
          ...entries.map((entry, index) => ({ [queries[index]]: entry }))
        );
        setContentfulData(newData);
      } catch (err) {
        setError(err as Error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [queries]);

  return { contentfulData, isLoading, error };
};
