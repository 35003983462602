import { TeamMemberCard } from './TeamMemberCard';
import { TeamMember } from '../../../../hooks/Contentful/Types/ContentfulTypes';

interface OurTeamProps {
  data: TeamMember[];
}

export default function OurTeam(props: OurTeamProps) {
  const sortedData = props.data?.sort((a, b) => {
    const order = [
      'Tracy Baldwin',
      'James Coyle',
      'Vrenae Daly',
      "Bob O'Brien",
      'Tyler Florence',
    ];
    return order.indexOf(a.name) - order.indexOf(b.name);
  });

  const teamMembers = sortedData?.map((teamMember) => (
    <TeamMemberCard
      key={teamMember.name}
      photo={teamMember.featuredImage}
      name={teamMember.name}
      position={teamMember.position}
      description={teamMember.description}
      linkedInUrl={teamMember.linkedInUrl}
    />
  ));

  return (
    <div className="flex flex-col my-20">
      <h1 className="font-bold xs:text-4xl text-5xl w-100 lg:mb-10 xs:mb-4">
        Our Team
      </h1>

      <div className="flex flex-wrap justify-center">{teamMembers}</div>
    </div>
  );
}
