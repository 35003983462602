import { NavLink } from 'react-router-dom';
import { AboutSection } from '../../../hooks/Contentful/Types/ContentfulTypes';
export interface IGetDemoCardProps {
  data: AboutSection;
}

export function GetDemoCard(props: IGetDemoCardProps) {
  const { title, description, featuredImage } = props.data;
  return (
    <div className="bg-gray-100 w-full px-10 lg:px-20 lg:py-12">
      <div className="flex flex-col lg:flex-row gap-20 w-full mt-8 mb-16 pt-8">
        <div className="lg:order-last lg:w-1/2">
          <img
            src={featuredImage}
            alt=""
            className="rounded-xl w-full lg:p-0"
          />
        </div>
        <div className="lg:w-1/2 flex flex-col justify-center h-100">
          <h3 className="text-4xl xs:text-3xl font-medium mb-4">{title}</h3>
          <p className="text-lg font-normal xs:text-sm text-graySubtitle leading-7 align-left">
            {description}
          </p>
          <NavLink
            to="/become-cardholder"
            className="text-white font-bold bg-rokketmedMain px-5 py-4 mt-8 lg:mt-16 lg:w-8/12 rounded-lg text-center hover:shadow-custom3 ease-in-out transition-all duration-200"
          >
            <i className="fa-regular fa-message mr-2"></i>
            Get a personalized demo
          </NavLink>
        </div>
      </div>
    </div>
  );
}
