import { useTrackPageName } from '../../hooks/GoogleAnalytics/googleAnalytics';

export interface ITermsOfServiceProps {}

export function TermsOfService(props: ITermsOfServiceProps) {
  useTrackPageName();
  return (
    <div className='container px-16 py-5 text-lg'>
      <p className='my-10'>Last updated: December 2016</p>

      <p className='my-10'>
        THESE ROKKETMED TERMS OF SERVICE <strong>("Terms") </strong>ARE A LEGAL
        AGREEMENT BETWEEN YOU AND ROKKETMED.COM AND ITS AFFILIATES
        (collectively, <strong>"RokketMed," "Us," "Our"</strong> or{' '}
        <strong>"We"</strong>), THE OWNER AND OPERATOR OF ROKKETMED.COM AND ALL
        SITE-RELATED SERVICES AND MOBILE APPLICATIONS (collectively, the{' '}
        <strong>"RokketMed Service"</strong>). THESE TERMS APPLY TO ALL VISITORS
        TO OR USERS OF THE ROKKETMED SERVICE.
      </p>

      <p className='my-10'>
        THESE TERMS SET FORTH THE CONDITIONS THAT GOVERN YOUR USE OF THE
        ROKKETMED SERVICE. PLEASE READ THESE TERMS CAREFULLY BEFORE ACCESSING OR
        USING THE ROKKETMED SERVICE.
      </p>

      <p className='my-10'>
        BY ACCESSING OR USING THE ROKKETMED SERVICE, YOU ACCEPT AND AGREE TO BE
        BOUND BY THESE TERMS AND BY OUR PRIVACY POLICY WHETHER OR NOT YOU ARE A
        REGISTERED USER OF THE ROKKETMED SERVICE.&nbsp; THESE TERMS APPLY TO ALL
        VISITORS, REGISTERED USERS, AND OTHER WHO ACCESS OR USE THE ROKKETMED
        SERVICE.&nbsp; IF YOU DO NOT AGREE TO ANY OF THESE TERMS, DO NOT ACCESS
        OR USE THE ROKKETMED SERVICE.
      </p>

      <ol className='list-decimal my-5 gap-2 flex flex-col'>
        <li>
          <strong>MEDICAL ADVICE DISCLAIMER.</strong> The RokketMed Service is
          not designed to, and does not provide, medical advice. All content,
          including text, graphics, images and information available on or
          through the RokketMed Service is for general information or scheduling
          purposes only. The content is not intended to be a substitute for
          professional medical advice, diagnosis or treatment. RokketMed is not
          affiliated with any medical establishment and does not warrant or
          represent that use of the RokketMed Service is appropriate or safe for
          your particular health needs. The RokketMed Service is not intended
          for use by individuals under 18 years of age, pregnant women, or those
          whose physicians advise against using the RokketMed Service. The
          health conditions, procedures, or services described in the RokketMed
          Service are for general reference only. NEVER DISREGARD PROFESSIONAL
          MEDICAL ADVICE, OR DELAY IN SEEKING IT BECAUSE OF SOMETHING YOU HAVE
          READ ON THE ROKKETMED SERVICE. NEVER RELY ON INFORMATION ON THE
          ROKKETMED SERVICE IN PLACE OF SEEKING PROFESSIONAL MEDICAL ADVICE. IF
          YOU ARE HAVING A MEDICAL EMERGENCY, CALL YOUR DOCTOR OR 911
          IMMEDIATELY.
        </li>
        <li>
          <strong>HEALTHCARE PROVIDER RELATIONSHIP; DISCLAIMER.</strong>{' '}
          Although some Site Information may be provided by a healthcare
          provider and the RokketMed Service may help you to locate or contact a
          healthcare provider, the RokketMed Service does not provide healthcare
          services and using the RokketMed Service will not create a
          provider-patient relationship. RokketMed has no control over and does
          not guarantee the availability, effectiveness, or appropriateness of
          any healthcare provider or of any Site Information. Nor does RokketMed
          recommend or endorse any specific healthcare provider, procedure, or
          treatment for a specific condition. RokketMed does not warrant or
          represent that the healthcare services provided by any healthcare
          provider listed in the RokketMed Service will be of a certain quality,
          cost, or will be appropriate or safe for your particular health needs.
          RokketMed is not responsible or liable for any charges or fees
          associated with services you obtain from a healthcare provider,
          including charges in connection with cancelled appointments. Under no
          circumstances will RokketMed be liable to you or any other person for
          any reliance on the RokketMed Service. The healthcare provider
          information featured in the RokketMed Service is strictly available
          for general informational and appointment scheduling purposes only.
          You are solely responsible for selecting your own healthcare provider.
          RokketMed does not verify the licensure or certifications of
          healthcare providers listed in the RokketMed Service; you are
          responsible for verifying the credentials or qualifications of any
          healthcare provider you visit. Most providers on RokketMed pay a small
          fee to be listed&nbsp;on the RokketMed platform. In doing so&nbsp;we
          are able to provide this valuable information free of charge to the
          consumer.
        </li>
        <li>
          <strong>ELIGIBILITY; USE OF THE ROKKETMED SERVICE.</strong>&nbsp;You
          agree not to use the RokketMed Service in any way that would interfere
          with or disrupt the operation of the RokketMed Service or the networks
          or servers connected to the RokketMed Service in any manner; to
          impersonate any person or entity or otherwise misrepresent your
          affiliation with any person or entity; to upload, post or otherwise
          transmit any content that infringes the proprietary rights of any
          third party, or is otherwise unlawful or offensive; to harass or
          otherwise harm any other person or entity; or to engage in commercial
          activities of any kind. Any unauthorized use of the RokketMed Service
          may result in termination of all rights to use the site and may be
          subject to legal action. You represent and warrant that you are at
          least 18 years of age. If you are under age 18, you may not download
          or use the RokketMed Service. If we discover or have any reason to
          suspect that you are not at least 18 years of age, we reserve the
          right to suspend or terminate your access to the RokketMed Service
          immediately and without notice.
        </li>
        <li>
          <strong>MODIFICATIONS AND CHANGES.</strong>&nbsp; We may modify these
          Terms to, for example, reflect changes in the law or changes to
          the&nbsp; RokketMed Service.&nbsp; You should look at these Terms
          regularly.&nbsp; We’ll post notice of modifications to these Terms on
          this page and we may provide notice by other means, including sending
          an email to your last email address of record if you have provided an
          email address to us.&nbsp; Changes will not apply retroactively and
          will become effective no sooner than fourteen days after they are
          posted.&nbsp; However, changes addressing new functions for the
          RokketMed Service or changes made for legal reasons will be effective
          immediately.&nbsp; If you do not agree to the modified Terms, you
          should discontinue your use of the RokketMed Service. If there is a
          conflict between these Terms and the additional terms, the additional
          terms will control.&nbsp; These Terms control the relationship between
          RokketMed and you. They do not create any third party beneficiary
          rights.&nbsp; If you do not comply with these Terms, and We don’t take
          action right away, this doesn’t mean that We are giving up any right
          We may have (such as taking action in the future).
        </li>
        <li>
          <strong>YOUR ROKKETMED ACCOUNT.&nbsp;</strong> You may access certain
          portions of the RokketMed Service without registering your personal
          information with RokketMed. To access other areas of the RokketMed
          website, and to use the RokketMed Service, you must register with Us.
          Each registration is for a single user only. RokketMed does not permit
          you to share your RokketMed username and password with any other
          person or with multiple users on a network.
          <ol className='list-decimal my-5 gap-2 flex flex-col' type='A'>
            <li>
              <strong>Account Security.</strong> You are entirely responsible
              for all activities that occur under your account and for
              maintaining the confidentiality of your username, password, and
              your account. You also agree to promptly notify us via email at
              [info@RokketMed.com] of any unauthorized use of your username,
              password, other account information, or any other breach of
              security that you become aware of involving or relating to the
              RokketMed Service. In addition, you agree to take adequate
              measures to safeguard your information and account, including
              exiting from your account at the end of each session. RokketMed is
              not liable for any loss that you may incur as a result of any
              unauthorized use of your user account and password.
            </li>
            <li>
              <strong>Account Information.</strong> You understand and hereby
              acknowledge that you have no ownership rights in your RokketMed
              Service account, and that if you cancel your RokketMed Service
              account (or your account is terminated by RokketMed in accordance
              with these Terms), we may delete and remove all of your account
              information from any public area of the RokketMed Service.
              Information may continue to be stored on RokketMed's web servers
              or retained by third parties as cached copies for the convenience
              of those entities but will not be accessible to you after
              termination of your RokketMed account.
            </li>
            <li>
              <strong>Account Termination.</strong> You may terminate your
              account by contacting us at{' '}
              <a href='mailto:info@rokketmed.com'>info@rokketmed.com</a> and
              state the purpose of your email. Upon receipt of your notice,
              RokketMed will make commercially reasonable efforts to remove your
              information from its systems or to disable access to your
              information.
            </li>
          </ol>
        </li>
        <li>
          <strong>PERSONAL INFORMATION.&nbsp;</strong> You agree to provide and
          update &nbsp;true, accurate, current, and complete information about
          you, as prompted by our registration processes or as required for the
          use of the RokketMed Service, or any portion thereof. You acknowledge
          that, if you provide any information that does not satisfy this
          provision, or we have reasonable grounds to suspect as much, we have
          the right to immediately suspend or terminate your account and refuse
          any and all current or future use of the RokketMed Service.
          <ol className='list-decimal my-5 gap-2 flex flex-col' type='A'>
            <li>
              <strong>Use of Your Information.</strong> You also agree that
              RokketMed may store and use any personal information that you
              provide to us for use in accordance with the RokketMed Privacy
              Policy, including but not limited to processing your request or
              order, billing you for the Service (including charging fees to
              your credit card), providing the RokketMed Service, and as
              otherwise permitted or required under applicable law. Please refer
              to our Privacy Policy [INSERT LINK] (the "Privacy Policy"), which
              is incorporated by reference into these Terms, for more
              information regarding RokketMed's collection, use, disclosure and
              storage of your personal information.
            </li>
            <li>
              <strong>Privacy Policy.</strong> You hereby expressly agree that
              we may use your personal information in accordance with the terms
              of the Privacy Policy.&nbsp;
            </li>
            <li>
              <strong>Communications from RokketMed.</strong> By creating an
              account, you agree to receive certain communications by email or
              mobile phone in connection with the RokketMed Services, including
              daily or weekly emails regarding offerings, features, programs and
              services available through the RokketMed Service. You can opt-out
              of receiving certain of these communications through an
              unsubscribe mechanism, but you will still be required to receive
              certain essential communications regarding your account as long as
              you continue to be a registered user.
            </li>
            <li>
              <strong>Disclosure of Medical Information.&nbsp;</strong> You
              acknowledge that your use of the RokketMed Service may include
              your voluntary disclosure of personal health or medical
              information, including but not limited to the disclosure of
              information relating to alcohol or drug abuse, genetic testing,
              psychiatric care and/or HIV related information. You specifically
              authorize the release of such information to medical service
              providers via the RokketMed Service. Some information collected or
              disclosed by RokketMed may be considered protected health
              information (“PHI”) under the Health Insurance Portability and
              Accountability Act of 1996 (“HIPAA”). If we release, disclose, or
              transmit your PHI in conjunction with a service you request, we
              will do so in compliance with HIPAA to the extent HIPAA applies to
              Us and with your full permission.
            </li>
          </ol>
        </li>
        <li>
          <strong>OWNERSHIP OF SITE INFORMATION.</strong> You hereby expressly
          acknowledge that RokketMed or it licensors own all legal right, title
          and interest in the Site Information. RokketMed reserves all rights
          not expressly granted in and to the RokketMed Service and Site
          Information.&nbsp; As used in these Terms, "Site Information" means
          all information, text, pictures, sound, graphics, scripts, photos,
          software, designs, design rights, video, data, sounds, music,
          interactive features, content and the like that is displayed on the
          Site or the RokketMed Service and any and all other proprietary rights
          contained therein, including, without limitation, the trademarks,
          service marks, logos, patent rights, copy rights, other proprietary
          material, marks or rights, know-how, concept, methods, techniques,
          ideas and all other intellectual property rights and rights of a
          similar character whether registered or capable of registration. Site
          Information does not include User-Generated Content.
        </li>
        <li>
          <strong>USER-GENERATED CONTENT.&nbsp;</strong> The RokketMed Service
          may, now or in the future, permit users to submit content, such as
          comments and reviews and may also provide for the hosting, sharing or
          publishing of such content ("User-Generated Content"). We may use any
          User-Generated Content in a number of different ways, including
          publicly displaying it, reformatting it, incorporating it into
          advertisements and other works, creating derivative works from it,
          promoting it, distributing it, and allowing others to do the same in
          connection with their own websites and media platforms. You hereby
          grant RokketMed a perpetual, royalty-free, unlimited right to use,
          share, distribute, disseminate, duplicate and create derivative works
          from any User-Generated Content that you submit to the RokketMed
          Service.
          <ol className='list-decimal my-5 gap-2 flex flex-col' type='A'>
            <li>
              <strong>Healthcare Provider Reviews.</strong> You may only submit
              honest reviews or feedback about healthcare providers with whom
              you have had a personal experience.
            </li>
            <li>
              <strong>Non-confidential and Non-proprietary.</strong> Any
              User-Generated Content you submit may be publicly available to
              other RokketMed Service users as well as members of the general
              public. You agree and acknowledge that, without regard to whether
              such content is published, RokketMed cannot and does not guarantee
              any confidentiality or security of any User-Generated Content. You
              further understand and agree that RokketMed does not warrant or
              verify the accuracy of any User-Generated Content. Your decision
              to submit, accept or rely on any User-Generated Content is at your
              own risk.
            </li>
            <li>
              <strong>Liability for User-Generated Content.</strong> You shall
              also be solely responsible for any User-Generated Content you
              submit and the consequences of posting or publishing such,
              including anyone's reliance on its quality, accuracy, or
              reliability, or any disclosure by you of information in Your
              Content that makes you personally identifiable. With respect to
              any User-Generated Content you submit, you agree you will not: (i)
              submit any content that is copyrighted, protected by trade secret
              or otherwise subject to any third party intellectual property
              rights or proprietary rights, including any privacy and publicity
              rights, unless you are the owner of such rights or have permission
              from the rightful owner of such rights to post such content and to
              grant RokketMed all of the license rights and other rights granted
              herein; (ii) upload, post, e-mail or otherwise transmit any
              content that is unlawful, harmful, threatening, abusive,
              harassing, tortious, defamatory, vulgar, obscene, libelous,
              invasive of another's privacy, hateful, or racially, ethnically or
              otherwise objectionable; (iii) use the RokketMed Service to harm
              minors in any way; (iv) impersonate any person or entity,
              including but not limited to, a representative of RokketMed, or
              falsely state or otherwise misrepresent your affiliation with a
              person or entity; (v) forge headers or otherwise manipulate
              identifiers in order to disguise the origin of any content
              transmitted to or through the RokketMed Service; (vi) upload,
              post, e-mail or otherwise transmit any unsolicited or unauthorized
              advertising, promotional materials, "junk mail", "spam", "chain
              letters", "pyramid schemes" or any other form of solicitation;
              (vii) upload, post, e-mail or otherwise transmit any content that
              contains computer viruses or any other computer code, files or
              programs designed to interrupt, destroy, or limit the
              functionality of any computer software or hardware or
              telecommunications equipment; (viii) intentionally or
              unintentionally violate any applicable local, state, national or
              international law; and (ix) collect or store personal data about
              other users.
            </li>
            <li>
              <strong>Removal of Content.</strong> While we are not obligated to
              do so, we reserve the right to refuse or remove any User-Generated
              Content from the RokketMed Service that we reasonably consider to
              violate these Terms or to otherwise be illegal or objectionable.
              Further, if we deem appropriate in our sole discretion, we reserve
              the right to preserve a record of any User-Generated Content that
              we reasonably believe may to be illegally posted or to otherwise
              violate the law or the rights of a third party, and to turn all or
              a portion thereof over to law enforcement officials.
            </li>
            <li>
              <strong>Site Monitoring.</strong> You hereby acknowledge and agree
              that RokketMed has the right, but not the obligation, to monitor
              the RokketMed Service, the Site Information and any User-Generated
              Content that you or any third party transmit or post, to alter or
              remove any such materials at its sole discretion, and to disclose
              such materials and the circumstances surrounding their
              transmission to any third party in order to operate the RokketMed
              Service properly, to protect itself, its sponsors, its registered
              users and visitors, and to comply with legal obligations or
              governmental requests. RokketMed also reserves the right to,
              without advance notice, suspend, block or deny any user access to
              the RokketMed Service when in RokketMed's sole discretion we
              believe the user has violated any of these Terms.
            </li>
          </ol>
        </li>
        <li>
          <strong>CODE OF CONDUCT FOR THE ROKKETMED SERVICE.&nbsp;</strong>{' '}
          While using the RokketMed Service (including in connection with any
          User-Generated Content), you will not, and will not induce any third
          party to: (i) use the RokketMed Service or any of the Site Information
          in a way that is unlawful, harms RokketMed or any user of the
          RokketMed Service, or otherwise violates the rights of any party; (ii)
          use the RokketMed Service or any of the Site Information in a way that
          could overburden, or impair the RokketMed Service (or the networks or
          systems connected to such); (iii) attempt to disable or circumvent any
          security mechanisms used by the RokketMed Service or Site Information,
          or otherwise attempt to gain unauthorized access to any portion or
          feature of the RokketMed Service or Site Information or any other
          systems or networks connected to the RokketMed Service, or to any
          server of RokketMed or its third party service providers, by hacking,
          password "mining", or any other illegal means; (iv) use any
          "deep-link", "page-scrape", "robot", "spider", or other automatic
          device, program, algorithm or methodology, or any comparable manual
          process, to access, acquire, copy, or monitor any portion of the
          RokketMed Service or Site Information; (v) use any device, software,
          instrumentality or routine to interrupt or interfere with, or attempt
          to interrupt or interfere with, the proper operation and working of
          the RokketMed Service, or with any other person's use of the RokketMed
          Service; (vi) breach any security measures implemented on the
          RokketMed Service or in the Site Information; (vii) track or seek to
          trace any information on any other person who visits or used the
          RokketMed Service; (viii) use the RokketMed Service or Site
          Information for, or in connection with, any illegal purpose, to
          solicit, facilitate, encourage, condone, or induce any illegal
          activity, or as otherwise prohibited by these Terms or applicable
          laws, rules or regulations, including, without limitation, laws
          applicable to the export of software and data; or (ix) copy, modify,
          create a derivative work of, reverse engineer, decompile, or otherwise
          attempt to extract the source code of any proprietary software used to
          provide, maintain, or otherwise applicable to, the RokketMed Service
          or Site Information, or otherwise made available to you in connection
          with the RokketMed Service or Site Information; or (x) use the
          RokketMed Service or Site Information in any manner that otherwise
          violates these Terms.
          <ol className='list-decimal my-5 gap-2 flex flex-col' type='A'>
            <li>
              <strong>Intellectual Property Rights.</strong> RokketMed respects
              the intellectual property of others, and we expect you and all
              users to do the same. We may, in appropriate circumstances and at
              our sole discretion, disable and/or terminate the accounts of
              users of the RokketMed Services who may infringe the intellectual
              property rights of RokketMed or others.
            </li>
            <li>
              <strong>Cooperation with Law Enforcement.</strong> RokketMed will
              fully cooperate with any law enforcement authorities or court
              order requesting or directing RokketMed to disclose the identity
              or locate anyone who uses the RokketMed in violation of any
              applicable laws or these Terms.
            </li>
          </ol>
        </li>
        <li>
          <strong>ASSUMPTION OF RISK; WARRANTY DISCLAIMER.</strong> You agree
          that your use of the RokketMed Service is entirely at your own
          risk.&nbsp; RokketMed will undertake all commercially reasonable
          efforts to secure any health information that you disclose through
          your use of the RokketMed Service. NOTWITHSTANDING, THE ROKKETMED
          SERVICE, ALL SITE INFORMATION AND USER-GENERATED CONTENT IS PROVIDED
          ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT WARRANTIES OF ANY
          KIND, EXPRESS, STATUTORY, IMPLIED OR OTHERWISE, OF ANY KIND. YOUR USE
          OF THE ROKKETMED SERVICE IS ENTIRELY AT YOUR OWN DISCRETION AND RISK,
          AND YOU ASSUME TOTAL RESPONSIBILITY FOR YOUR USE OF THE ROKKETMED
          SERVICE. ROKKETMED, ON BEHALF OF ITSELF AND ITS PARENT, SUBSIDIARIES,
          AFFILIATES, LICENSORS, DISTRIBUTORS, AGENTS, REPRESENTATIVES, AND
          THIRD-PARTY SERVICES PROVIDERS, INCLUDING ANY RESELLERS, DISTRIBUTORS,
          SERVICE PROVIDERS AND SUPPLIERS THEREOF, AND ANY OF THE FOREGOING
          ENTITIES' RESPECTIVE OFFICERS, DIRECTORS, OWNERS, EMPLOYEES, AGENTS,
          PARTNERS, CONTRACTORS, REPRESENTATIVES, SUCCESSORS AND ASSIGNS
          (COLLECTIVELY, THE <strong>"ROKKETMED PARTIES"</strong>): (A)
          EXPRESSLY DISCLAIMS ALL REPRESENTATIONS, WARRANTIES AND CONDITIONS,
          WHETHER EXPRESS, IMPLIED OR STATUTORY, INCLUDING, WITHOUT LIMITATION,
          ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
          PURPOSE, NON-INFRINGEMENT OR NON-MISAPPROPRIATION OF INTELLECTUAL
          PROPERTY RIGHTS OF A THIRD PARTY, TITLE, CUSTOM, TRADE, QUIET
          ENJOYMENT, ACCURACY OF INFORMATION CONTENT, OR SYSTEM INTEGRATION.
          ROKKETMED DOES NOT WARRANT THAT THE ROKKETMED SERVICE WILL BE
          AVAILABLE OR OPERATE IN AN UNINTERRUPTED, ERROR-FREE OR COMPLETELY
          SECURE MANNER OR THAT ERRORS OR DEFECTS WILL BE CORRECTED; (B) DOES
          NOT REPRESENT OR WARRANT THAT THE ROKKETMED SERVICE, OR ANY CONTENTS
          THEREIN, WILL BE AVAILABLE OR OPERATE IN AN UNINTERRUPTED, TIMELY,
          ERROR-FREE OR COMPLETELY SECURE MANNER, OR THAT ERRORS OR DEFECTS WILL
          BE CORRECTED; (C) DOES NOT REPRESENT OR WARRANT THAT THE ROKKETMED
          SERVICE OR ANY CONTENTS THEREOF WILL MEET YOUR REQUIREMENTS; AND (D)
          DOES NOT MAKE ANY REPRESENTATIONS, WARRANTIES, OR CONDITIONS REGARDING
          THE USE OR THE RESULTS OF THE USE OF THE ROKKETMED SERVICE OR ANY
          CONTENT THEREIN, IN TERMS OF THEIR ACCURACY, RELIABILITY, TIMELINESS,
          COMPLETENESS, OR OTHERWISE. Some jurisdictions may not allow the
          exclusion or limitation of implied warranties or conditions, or allow
          limitations on how long an implied warranty lasts, so the above
          limitations or exclusions may not apply to you. In such event,
          RokketMed's warranties and conditions with respect to the RokketMed
          Service will be limited to the greatest extent permitted by applicable
          law in such jurisdiction.
        </li>
        <li>
          <strong>LIMITATION OF LIABILITY. </strong>UNDER NO CIRCUMSTANCES WILL
          THE ROKKETMED PARTIES BE LIABLE TO YOU OR ANY OTHER PERSON FOR ANY
          SPECIAL, INDIRECT, INCIDENTAL, PUNITIVE, EXEMPLARY, RELIANCE, OR
          CONSEQUENTIAL DAMAGES ARISING OUT OF OR RELATING TO THESE TERMS OR
          YOUR USE OF THE ROKKETMED SERVICE, EVEN IF A ROKKETMED PARTY HAS BEEN
          ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IF, NOTWITHSTANDING THE
          FOREGOING, A ROKKETMED PARTY IS FOUND TO BE LIABLE TO YOU OR ANY OTHER
          PERSON FOR ANY DAMAGE OR LOSS ARISING OUT OF OR RELATING TO THESE
          TERMS OR YOUR USE OF THE ROKKETMED SERVICE, THE RELEVANT ROKKETMED
          PARTIES' TOTAL CUMULATIVE LIABILITY SHALL IN NO EVENT EXCEED THE
          LESSER OF: (A) THE AMOUNT YOU PAID ROKKETMED FOR YOUR USE OF THE
          ROKKETMED SERVICE AND ANY CONTENT THEREIN; OR (B) THE SUM OF ONE
          HUNDRED (100) US DOLLARS. Some jurisdictions may not allow the
          exclusion or limitation of incidental, special, consequential, or
          other damages, so the above limitations or exclusions may not apply to
          you. In such event, RokketMed's liability for such damages with
          respect to the RokketMed Service will be limited to the greatest
          extent permitted by applicable law in such jurisdiction.
        </li>
        <li>
          <strong>INDEMNITY.</strong>&nbsp; You agree to indemnify, defend and
          hold harmless, RokketMed, its affiliates, and their respective
          officers, directors, employees, agents, licensors, representatives,
          and third party providers to the RokketMed Service from and against
          any and all claims or liability, including costs and attorney's fees,
          arising from or in connection with your violation of these Terms and
          your use of the RokketMed Service. RokketMed reserves the right to
          assume, at its sole expense, the exclusive defense and control of any
          matter subject to indemnification by you, in which event you will
          fully cooperate with RokketMed in asserting any available defenses.
        </li>
        <li>
          <strong>TERMINATION.</strong>&nbsp; You agree that RokketMed, in its
          sole discretion, may terminate your use of the RokketMed Service, and
          remove and discard your User-Generated Content, for any reason, if
          RokketMed believes that you have or may have violated or acted
          inconsistently with these Terms. RokketMed may also in its sole
          discretion and at any time discontinue providing the RokketMed
          Service, or any part thereof, with or without notice and for any
          reason or for no reason. You agree that any termination of your access
          to the RokketMed Service, under any provision of these Terms, may be
          effected without prior notice to you, and acknowledge and agree that
          RokketMed may bar any further access to the RokketMed Service.
          Further, you agree that RokketMed shall not be liable to you or any
          third-party for any termination of access to the RokketMed Service.
        </li>
        <li>
          <strong>LIMITED TIME TO BRING CLAIM.</strong>&nbsp; Where permitted by
          law, you and RokketMed agree that any cause of action arising out of
          or related to the RokketMed Service or any Site Information must be
          commenced within one (1) year after the cause of action accrues.
          Otherwise, such cause of action is permanently barred.
        </li>
        <li>
          <strong>DMCA NOTICE.</strong>&nbsp; If you are a copyright owner or an
          agent thereof and believe any Site Information or User-Generated
          Content infringes upon your copyrights, you may submit a notification
          of claimed infringement under the Digital Millennium Copyright Act (
          <strong>"DMCA")</strong> by providing notice to our Designated
          Copyright Agent (<strong>"Designated Copyright Agent"</strong>)
          containing the following information:
          <ol className='list-decimal my-5 gap-2 flex flex-col' type='A'>
            <li>
              A physical or electronic signature of a person authorized to act
              on behalf of the owner of a copyright that is allegedly infringed;
            </li>
            <li>
              Identification of the copyrighted work claimed to have been
              infringed, or, if multiple copyrighted works at a single online
              site are covered by a single notification, a representative list
              of such works at that site;
            </li>
            <li>
              Identification of the material that is claimed to be infringing or
              to be the subject of infringing activity and that is to be removed
              or access to which is to be disabled and information reasonably
              sufficient to permit us to locate the material;
            </li>
            <li>
              Information reasonably sufficient to permit us to contact you,
              such as an address, telephone number, and, if available, an
              electronic mail address;
            </li>
            <li>
              A statement that you have a good faith belief that use of the
              material in the manner complained of is not authorized by the
              copyright owner, its agent, or the law; and
            </li>
            <li>
              A statement that the information in the notification is accurate,
              and under penalty of perjury, that you are authorized to act on
              behalf of the owner of an exclusive right that is allegedly
              infringed.
            </li>
          </ol>
        </li>
      </ol>

      <p className='last first'>
        RokketMed's Designated Copyright Agent to receive notifications of
        claimed infringement is:
      </p>

      <p className='last first'>
        Please send only DMCA notices to our Designated Copyright Agent. You
        acknowledge that if you fail to comply with all of the above
        requirements of this Section 14, your DMCA notice may not be valid.
      </p>

      <ol className='list-decimal my-5 gap-2 flex flex-col' start={16}>
        <li value='16'>
          <strong>AVAILABILITY OF THE ROKKETMED SERVICE.</strong>&nbsp; While
          RokketMed endeavors to ensure that the RokketMed Service continue to
          be available, we cannot guarantee that the RokketMed Service shall
          operate or be available in an uninterrupted manner. RokketMed shall
          not be liable if for any reason the Site or Service is unavailable at
          any time or for any period.
          <ol className='list-decimal my-5 gap-2 flex flex-col' type='A'>
            <li>
              <strong>Downtime or Outage. </strong>Access to the RokketMed
              Service may be suspended temporarily and without notice in the
              case of system failure, maintenance or repair or for reasons
              beyond RokketMed's control.
            </li>
            <li>
              <strong>Reporting Errors.</strong> You may report Site problems
              and errors by email to info@rokketmed.com
            </li>
          </ol>
        </li>
        <li value='17'>
          <b>SUPPORT AND UPDATES.</b> RokketMed has no obligation to provide
          support for the RokketMed Service. If RokketMed does provide updates,
          supplements, or add-on components to the RokketMed Service after the
          date you obtain your initial copy of the RokketMed Service
          (collectively, <strong>“Updates”</strong>), these Terms will apply to
          the Updates. If RokketMed provides additional terms along with any
          Update, those terms will apply to the Update. You agree that RokketMed
          may automatically check your version of any application you download
          and may automatically send your device updates to the application.
          RokketMed reserves the right to discontinue your access to the
          RokketMed Service and RokketMed may, in its sole discretion, release
          subsequent versions of the RokketMed Service and require you to obtain
          and use the most current version. RokketMed, at its sole discretion,
          may update, change or remove its software, including but not limited
          to web based software and mobile software applications, at any time
          and without notice and in any manner that RokketMed deems
          appropriate.&nbsp; RokketMed may, at its sole discretion, cease or
          fail to commence support or compatibility of the RokketMed software
          with any operating system or any web browser or any versions thereof.
        </li>
        <li value='18'>
          <b>THIRD-PARTY PRODUCTS, SERVICES, OR DEVICES.&nbsp;</b>&nbsp;You may
          need to use or obtain additional products or services in order to use
          the RokketMed Service, such as a web browser, internet access or a
          data connection. You must obtain or use such products or services
          separately and pay all associated charges (including for internet
          access or data transmission).
        </li>
        <li value='19'>
          <strong>LINKS TO THIRD-PARTY WEBSITES.</strong>&nbsp; Links to third
          party websites on the RokketMed Service are provided solely for your
          convenience. If you use these links, you leave the RokketMed Service
          and may be subject to the terms and conditions of the third party
          websites. RokketMed has not reviewed these third party websites and
          does not control and is not responsible for these websites or their
          content or availability. RokketMed therefore does not endorse or make
          any representations about, any material or content found within or any
          results that may be obtained by using, them. Links to the websites of
          third party healthcare provider(s) do not constitute an endorsement of
          any such healthcare provider(s) by RokketMed. If you decide to access
          any of the third party websites linked from the RokketMed Service, you
          do so entirely at your own risk.&nbsp; To the maximum extent permitted
          by law, under no circumstances will RokketMed be liable for any
          direct, indirect, incidental or special loss or other damage, whether
          arising from negligence, breach of contract, defamation, infringement
          of copyright or other intellectual property rights, caused by the
          exhibition, distribution or exploitation of any information or content
          contained within any third party hyperlinked sites.
        </li>
        <li value='20'>
          <strong>
            RSERVATION OF RIGHTS; COPYRIGHT AND TRADEMARK NOTICES.&nbsp;
          </strong>
          &nbsp;RokketMed owns the title, copyright, and other intellectual
          property rights in the RokketMed Service and reserves all rights not
          expressly granted to you in these Terms. The RokketMed Service is
          protected by copyright and other intellectual property laws and
          treaties. RokketMed, the RokketMed logo, and all other RokketMed
          product or service names referenced in the RokketMed Service are the
          trademarks of RokketMed. Any other company names, product names,
          service names, and logos referenced in connection with the RokketMed
          Service may be the trademarks of their respective owners.
        </li>
        <li value='21'>
          <strong>COMPLIANCE WITH LAW.&nbsp;</strong>&nbsp; You shall obey all
          federal, state, and local laws, regulations and rules that apply to
          your activities when you use the RokketMed Service. RokketMed reserves
          the right to terminate your account and to prevent your use of the
          RokketMed Service if your account is used to engage in illegal
          activity or to violate these Terms.
        </li>
        <li value='22'>
          <strong>SECURITY.</strong>&nbsp; You shall not violate or attempt to
          violate the security of the RokketMed Service. Violations of system or
          network security may result in civil or criminal liability. RokketMed
          reserves the right to investigate occurrences which may involve such
          violations and may involve, and cooperate with, law enforcement
          authorities in prosecuting users who have participated in such
          violations.
        </li>
        <li value='23'>
          <strong>
            ENTIRE AGREEMENT, NOTICE, WAIVER, AND SEVERABILITY.&nbsp;
          </strong>
          &nbsp;These Terms constitutes the entire agreement between you and
          RokketMed in connection with the RokketMed Service and supersedes all
          prior agreements between you and RokketMed regarding the subject
          matter contained herein. If any provision of these Terms is found
          invalid or unenforceable, that provision shall be enforced to the
          maximum extent possible, and the other provisions contained herein
          will remain in full force and effect. No failure of either party to
          exercise or enforce any of its rights under these Terms will act as a
          waiver of such rights. Nothing in these Terms shall be construed as
          creating or constituting a partnership, joint venture, agency or other
          like relationship between you and RokketMed or any of the RokketMed
          Parties. The failure of RokketMed to insist upon performance of any of
          the terms and conditions of these Terms, or the waiver of any breach
          of, or the decision to not exercise any of its rights under, any of
          the terms or conditions of these Terms, shall not be construed as
          thereafter waiving any such terms and conditions, or any other terms
          and conditions of these terms. Any waiver must in writing and signed
          by RokketMed.
        </li>
        <li value='24'>
          <strong>JURISDICTION, VENUE; WAIVER OF JURY TRIAL.</strong>&nbsp;
          These Terms and all claims or issues regarding the RokketMed Service
          shall be governed according to the laws of the State of Washington, in
          the United States of America, except for the conflict or choice-of-law
          principles thereof.&nbsp; You consent to the exclusive jurisdiction of
          the state and federal courts of King County, Washington, for any
          claims or other causes of action arising out of or relating to these
          Terms or the RokketMed Service.&nbsp;TO THE EXTENT NOT OTHERWISE
          PROHIBITED BY APPLICABLE LAW, THE PARTIES HERETO AGREE TO WAIVE TRIAL
          BY JURY WITH RESPECT TO ANY MATTERS ARISING UNDER OR RELATING TO THESE
          TERMS.
        </li>
        <li value='25'>
          <strong>MODIFICATION OF TERMS.</strong>&nbsp; RokketMed may change its
          Terms at any time and at its sole discretion and without notice to
          you.&nbsp; The current Terms shall be available to review at Our
          website www.rokketmed.com subject to You being able to access Our
          website.
        </li>
        <li value='26'>
          <strong>CONTACT US.</strong>&nbsp; Any questions or comments about the
          RokketMed Service may be sent to [info@rokketmed.com].
        </li>
      </ol>
    </div>
  );
}
