import { AboutUsHero } from '../../../hooks/Contentful/Types/ContentfulTypes';

export interface IAboutMainBannerProps {
  data: AboutUsHero;
}

export function AboutMainBanner(props: IAboutMainBannerProps) {
  const { featuredImage, title, description } = props.data;

  return (
    <div className="w-full grid md:grid-cols-12 gap-5 mb-10 pb-4">
      <img
        src={featuredImage}
        alt=""
        className="rounded-lg md:col-span-5 md:order-last"
      />
      <div className="lg:mt-16 xs:mt-4 md:col-span-7">
        <h1 className="lg:text-5xl xs:text-4xl font-bold pb-5">{title}</h1>
        <p className="xs:text-sm lg:text-xl text-graySubtitle font-medium">
          {description}
        </p>
      </div>
    </div>
  );
}
