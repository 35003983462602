import { createClient } from 'contentful';

const space = process.env.REACT_APP_CONTENTFUL_SPACE_ID ?? '';
const accessToken = process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN ?? '';

export const fetchEntries = async (query: string) => {
  try {
    const client = createClient({
      space: space,
      accessToken: accessToken,
    });

    const entries = await client.getEntries({
      content_type: query,
    });

    if (entries.items) return entries.items;
    throw new Error('No entries found');
  } catch (error) {
    console.error('Error fetching Contentful entries', error);
    throw new Error('Error fetching Contentful entries: ' + error);
  }
};
