import { loadingType } from '../../MarketPlace';
import { MarketplaceResultsMap } from '../marketplaceResultsMap/MarkeplaceResultsMap';
import { MarketplaceResultsList } from '../marketplaceResultsList/MarketplaceResultsList';
import { PlaceResultWithDistance } from '../../Services/services';
import { useState } from 'react';

export interface IMarketplaceDesktopViewProps {
  showSpinner: boolean;
  loading: loadingType;
  userLocation: google.maps.LatLngLiteral;
  results: google.maps.places.PlaceResult[];
  sortedResults: PlaceResultWithDistance[];
}

export function MarketplaceDesktopView(props: IMarketplaceDesktopViewProps) {
  const [selectedPlaceId, setSelectedPlaceId] = useState<string | null>(null);

  const handleInfoWindowClick = (placeId: string) => {
    setSelectedPlaceId(placeId);
  };

  return (
    <div className='w-full h-full'>
      {!props.showSpinner &&
        props.loading !== loadingType.Error &&
        props.sortedResults.length > 0 && (
          <div className='grid grid-cols-2 w-full h-full'>
            <MarketplaceResultsList
              results={props.results}
              focusPlaceId={selectedPlaceId || undefined}
            />

            <MarketplaceResultsMap
              center={props.userLocation}
              places={props.sortedResults}
              onInfoWindowClicked={handleInfoWindowClick}
            />
          </div>
        )}
    </div>
  );
}
