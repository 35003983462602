import { MentalHealthAndTelemedicine } from '../../../../Services/telemedicineAndMentalHealthResults';

export interface IMentalHealthAndTelemedicineResultProps {
  resultDetails: MentalHealthAndTelemedicine;
}

export function MentalHealthAndTelemedicineResult(
  props: IMentalHealthAndTelemedicineResultProps
) {
  const { resultDetails } = props;
  return (
    <div className="flex w-full p-2 overflow-x-hidden">
      <div className="h-20 w-20 aspect-square rounded-full overflow-hidden flex-shrink-0">
        <img
          src={resultDetails.icon}
          alt=""
          className="object-cover w-full h-full outline-none"
        />
      </div>

      <div className="flex-col ml-4 lg:mx-2 text-greyText w-full">
        <p className="font-sans text-base text-darkText font-semibold leading-6 align-left max-w-full text-wrap">
          {resultDetails?.name}
        </p>
        <div className="mt-1 text-sm"> {resultDetails?.description}</div>

        {props.resultDetails?.website && (
          <div className="flex gap-4 items-center my-1 text-sm">
            <a
              href={props.resultDetails?.website}
              target="_blank"
              rel="noreferrer"
              className="align-middle text-center text-blue-700 hover:underline"
            >
              <i className="fa fa-external-link text-l pr-3 text-darkText " />
              Get Healthcare Now
            </a>
          </div>
        )}
      </div>
    </div>
  );
}
