import { EntrySkeletonType } from 'contentful';

// Mixed Use Types
export interface TestimonialData {
  logo?: string;
  testimonial?: string;
  name?: string;
  position?: string;
  companyName?: string;
}
function formatTestimonialData(data: EntrySkeletonType[]): TestimonialData[] {
  return data.map((testimonial) => {
    return {
      logo: testimonial.fields.logo?.fields.file.url || '',
      testimonial: testimonial.fields?.testimonial || '',
      name: testimonial.fields?.name || '',
      position: testimonial.fields?.position || '',
      companyName: testimonial.fields?.companyName || '',
    };
  });
}

export interface BlogPostData {
  title: string;
  author: string;
  description: string;
  featuredImage: string;
  linkUrl: string;
  date: string;
}
function formatMediumPostUrl(data: EntrySkeletonType[]): BlogPostData[] {
  return data.map((post) => {
    const { title, author, description, featuredImage, linkUrl, date } =
      post.fields;
    return {
      title,
      author,
      description,
      featuredImage: featuredImage.fields.file.url,
      linkUrl,
      date,
    };
  });
}

// Landing Page
export interface HeroSection {
  floatingTitle: string;
  subtitle: string;
  title: string;
}
function formatHeroSectionData(data: EntrySkeletonType): HeroSection {
  return {
    floatingTitle: data.fields.floatingTitle,
    subtitle: data.fields.subtitle,
    title: data.fields.title,
  };
}

export interface FirstImageBanner {
  title: string;
  backgroundImage: string;
  description: string;
}
function formatFirstImageBannerData(data: EntrySkeletonType): FirstImageBanner {
  return {
    title: data.fields.title,
    backgroundImage: data.fields.backgroundImage.fields.file.url,
    description: data.fields.description,
  };
}

export interface BlobCta {
  circleImage: string;
  description: string;
  title: string;
}
function formatBlobCtaData(data: EntrySkeletonType): BlobCta {
  return {
    circleImage: data.fields.circleImage.fields.file.url,
    description: data.fields.description,
    title: data.fields.title,
  };
}
export interface ClosingCta {
  title: string;
  subtitle: string;
  description: string;
  featuredImage: string;
}
function formatClosingCtaData(data: EntrySkeletonType): ClosingCta {
  return {
    title: data.fields.title,
    subtitle: data.fields.subtitle,
    description: data.fields.description,
    featuredImage: data.fields.featuredImage.fields.file.url,
  };
}

// About Health Card
export interface AboutHealthCardHero {
  title: string;
  subtitle: string;
  youtubeVideo: string;
}
function formatAboutHealthCardHeroData(
  data: EntrySkeletonType
): AboutHealthCardHero {
  return {
    title: data.fields.title,
    subtitle: data.fields.subtitle,
    youtubeVideo: data.fields.youtubeVideo,
  };
}

export interface AboutSection {
  featuredImage: string;
  title: string;
  description: string;
  position: number;
}
function formatAboutSectionsData(data: EntrySkeletonType[]): AboutSection[] {
  return data
    .map((section) => {
      return {
        featuredImage: section.fields.featuredImage.fields.file.url,
        title: section.fields.title,
        description: section.fields.description,
        position: section.fields.position,
      };
    })
    .reverse();
}

export interface RokketMedCustomers {
  title: string;
  logos: string[];
}
function formatRokketMedCustomersData(
  data: EntrySkeletonType
): RokketMedCustomers {
  return {
    title: data.fields.title,
    logos: data.fields.logos.map((logo: any) => logo.fields.file.url),
  };
}

// Become A Cardholder
export interface BecomeACardHolder {
  title: string;
  subtitle: string;
  bulletTitle: string;
  bulletDescription: string;
  bulletTitle2: string;
  bulletDescription2: string;
  bulletTitle3: string;
  bulletDescription3: string;
  bulletTitle4: string;
  bulletDescription4: string;
  bulletTitle5: string;
  bulletDescription5: string;
}
function formatBecomeACardHolderData(
  data: EntrySkeletonType
): BecomeACardHolder {
  return {
    title: data.fields.title,
    subtitle: data.fields.subtitle,
    bulletTitle: data.fields.bulletTitle,
    bulletDescription: data.fields.bulletDescription,
    bulletTitle2: data.fields.bulletTitle2,
    bulletDescription2: data.fields.bulletDescription2,
    bulletTitle3: data.fields.bulletTitle3,
    bulletDescription3: data.fields.bulletDescription3,
    bulletTitle4: data.fields.bulletTitle4,
    bulletDescription4: data.fields.bulletDescription4,
    bulletTitle5: data.fields.bulletTitle5,
    bulletDescription5: data.fields.bulletDescription5,
  };
}

// About Us Page
export interface AboutUsHero {
  featuredImage: string;
  title: string;
  description: string;
}
function formatAboutUsHeroData(data: EntrySkeletonType): AboutUsHero {
  console.log(data);
  return {
    featuredImage: data.fields.featuredImage.fields.file.url,
    title: data.fields.title,
    description: data.fields.description,
  };
}

export interface TeamMember {
  featuredImage: string;
  name: string;
  position: string;
  description: string;
  linkedInUrl: string;
}
function formatTeamMembers(data: EntrySkeletonType[]): TeamMember[] {
  return data.map((member) => {
    return {
      featuredImage: member.fields.featuredImage.fields.file.url,
      name: member.fields.name,
      position: member.fields.position,
      description: member.fields.description,
      linkedInUrl: member.fields.linkedInUrl ?? '',
    };
  });
}

// Helper methods
function getMostRecentData(data: EntrySkeletonType[]): EntrySkeletonType {
  const mostRecent = data.reduce((prev: any, current: any) => {
    return prev.sys.updatedAt > current.sys.updatedAt ? prev : current;
  });

  return mostRecent;
}
export function formatContentfulData(
  data: EntrySkeletonType[] | any,
  query: string
):
  | HeroSection
  | FirstImageBanner
  | BlobCta
  | ClosingCta
  | AboutHealthCardHero
  | AboutSection[]
  | AboutSection
  | RokketMedCustomers
  | BecomeACardHolder
  | TestimonialData[]
  | AboutUsHero
  | TeamMember[]
  | BlogPostData[]
  | null {
  const mostRecentData = getMostRecentData(data);

  try {
    switch (query) {
      case 'heroSection':
        return formatHeroSectionData(mostRecentData);
      case 'firstImageBanner':
        return formatFirstImageBannerData(mostRecentData);
      case 'blobCta':
        return formatBlobCtaData(mostRecentData);
      case 'closingCta':
        return formatClosingCtaData(mostRecentData);
      case 'aboutHealthCardHero':
        return formatAboutHealthCardHeroData(mostRecentData);
      case 'aboutSections':
        return formatAboutSectionsData(data);
      case 'endOfAboutHealthCardCta':
        return formatAboutSectionsData(data)[0];
      case 'rokketMedCustomers':
        return formatRokketMedCustomersData(mostRecentData);
      case 'becomeACardHolder':
        return formatBecomeACardHolderData(mostRecentData);
      case 'testimonials':
        return formatTestimonialData(data);
      case 'aboutRokketMed':
        return formatAboutUsHeroData(mostRecentData);
      case 'teamMembers':
        return formatTeamMembers(data);
      case 'blogPost':
        return formatMediumPostUrl(data);

      default:
        return data;
    }
  } catch (error) {
    console.error(error);
    return null;
  }
}
