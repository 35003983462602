import { Dropdown } from 'flowbite-react';
import { useEffect, useState } from 'react';

export interface IHealthcareQuickLinksProps {
  size?: 'sm' | 'lg';
}

export function HealthcareQuickLinks({
  size = 'sm',
}: IHealthcareQuickLinksProps) {
  const sizeStyles = {
    sm: {
      container: 'rounded-xl py-2 px-3 h-10',
      text: 'text-xs',
    },
    lg: {
      container: 'rounded-xl py-2 px-2 md:py-2 md:px-10 h-12',
      text: 'text-sm',
    },
  };

  const currentSizeStyles = sizeStyles[size];

  const goodRxLink = 'https://www.goodrx.com/';
  const goodRxLogo =
    require('../../assets/images/Logos/GoodRxIcon.svg').default;

  const costPlusDrugsLink = 'https://costplusdrugs.com/';
  const costPlusDrugsLogo =
    require('../../assets/images/Logos/CostPlusIcon.svg').default;

  const walmartPharmacyLink = 'https://www.walmart.com/cp/pharmacy/5431';
  const walmartPharmacyLogo =
    require('../../assets/images/Logos/WalmartIcon.svg').default;

  const amazonPharmacyLink = 'https://pharmacy.amazon.com/';
  const amazonPharmacyLogo =
    require('../../assets/images/Logos/AmazonPharmacyIcon.svg').default;

  const hsaStoreLink =
    'https://hsastore.com/?AFID=563158&ParentId=&GroupName=TPA&CID=437559&utm_source=RokketMed+Inc&utm_medium=TPA+Public+Link&utm_campaign=TPA+Partner';
  const hsaStoreLogo = require('../../assets/images/Logos/hsaLogo.svg').default;

  const strideLink = 'https://www.stridehealth.com/';
  const strideLogo =
    require('../../assets/images/Logos/strideLogo.svg').default;

  const coveredCaLink = 'https://www.coveredca.com/';
  const coveredCaLogo =
    require('../../assets/images/Logos/coveredCaLogo.svg').default;

  const healthCareLink = 'https://www.healthcare.gov/';
  const healthCareLogo =
    require('../../assets/images/Logos/healthCareLogo.svg').default;

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      if (window.innerWidth < 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    checkMobile();

    window.addEventListener('resize', checkMobile);

    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  return (
    <div
      className={`w-full flex items-center justify-center align-middle text-darkText ${currentSizeStyles.text}`}
    >
      <div className='lg:mt-10 flex my-6 gap-2 lg:gap-5 items-center justify-center'>
        <Dropdown
          inline
          label={
            <div
              className={`flex justify-center items-center bg-white shadow-main border border-gray-300 hover:text-rokketmedMain ease-in-out transition-all duration-200  ${currentSizeStyles.container}`}
            >
              <p>{!isMobile ? 'Get health insurance' : 'Insurance'}</p>
              <i className='fas fa-chevron-down ml-2 text-xs'></i>
            </div>
          }
          size={size}
          arrowIcon={false}
          className='rounded-xl'
        >
          <a href={strideLink} target='_blank' rel='noreferrer'>
            <Dropdown.Item className='hover:text-rokketmedMain'>
              <img src={strideLogo} alt='' className='mr-2' />
              Stride Health
            </Dropdown.Item>
          </a>
          <a href={coveredCaLink} target='_blank' rel='noreferrer'>
            <Dropdown.Item className='hover:text-rokketmedMain'>
              <img src={coveredCaLogo} alt='' className='mr-2' />
              Covered California
            </Dropdown.Item>
          </a>
          <a href={healthCareLink} target='_blank' rel='noreferrer'>
            <Dropdown.Item className='hover:text-rokketmedMain'>
              <img src={healthCareLogo} alt='' className='mr-2' />
              Healthcare.gov
            </Dropdown.Item>
          </a>
        </Dropdown>
        <Dropdown
          inline
          label={
            <div
              className={`flex justify-center items-center align-middle bg-white rounded-xl shadow-main border border-gray-300 hover:text-rokketmedMain ease-in-out transition-all duration-200  ${currentSizeStyles.container}`}
            >
              <p>{!isMobile ? 'Save on medications' : 'Medications'}</p>
              <i className='fas fa-chevron-down ml-2 text-xs'></i>
            </div>
          }
          color=''
          size={size}
          arrowIcon={false}
          className='rounded-xl text-darkText'
        >
          <a href={goodRxLink} target='_blank' rel='noreferrer'>
            <Dropdown.Item className='hover:text-rokketmedMain'>
              <img src={goodRxLogo} alt='' className='mr-2' />
              GoodRx
            </Dropdown.Item>
          </a>
          <a href={costPlusDrugsLink} target='_blank' rel='noreferrer'>
            <Dropdown.Item className='hover:text-rokketmedMain'>
              <img src={costPlusDrugsLogo} alt='' className='mr-2' />
              CostPlus Drugs
            </Dropdown.Item>
          </a>
          <a href={walmartPharmacyLink} target='_blank' rel='noreferrer'>
            <Dropdown.Item className='hover:text-rokketmedMain'>
              <img src={walmartPharmacyLogo} alt='' className='mr-2' />
              Walmart Pharmacy
            </Dropdown.Item>
          </a>
          <a href={amazonPharmacyLink} target='_blank' rel='noreferrer'>
            <Dropdown.Item className='hover:text-rokketmedMain'>
              <img src={amazonPharmacyLogo} alt='' className='mr-2' />
              Amazon Pharmacy
            </Dropdown.Item>
          </a>
        </Dropdown>

        <button
          onClick={() => window.open(hsaStoreLink, '_blank')}
          className={`flex flex-shrink-0 justify-center items-center bg-white ${currentSizeStyles.container}  shadow-main border border-gray-300 hover:text-rokketmedMain ease-in-out transition-all duration-200`}
        >
          <img
            className='w-20 lg:w-auto h-6 lg:h-auto'
            src={hsaStoreLogo}
            alt=''
          />
        </button>
      </div>
    </div>
  );
}
