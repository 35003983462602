import { healthNeedsSearchList } from "../../../pages/MarketPlace/Services/services";

export const healthNeedsList = healthNeedsSearchList.map((item) => {
  return item.display;
}
);

/**
 * Users to translate a LatLngLiteral object to a human readable location (e.g. "New York, NY")
 */
export function getHumanReadableLocation(location: google.maps.LatLngLiteral): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ location }, (results, status) => {
      if (status === 'OK') {
        if (results && results[0]) {
          resolve(parseAddress(results[0].formatted_address));
        } else {
          reject('No results found');
        }
      } else {
        reject('Geocoder failed due to: ' + status);
      }
    });
  });
}

export function parseAddress(address: string): string {
  // Split the address into parts
  const parts = address.split(',');

  // The city is the second to last part, and the state is the last part
  const city = parts[parts.length - 3].trim();
  const stateAndZip = parts[parts.length - 2].trim();
  const state = stateAndZip.split(' ')[0];

  return `${city}, ${state}`;
}

export async function getUserLocation(): Promise<google.maps.LatLngLiteral | null> {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        (error) => {
          console.error(error);
          reject(null);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
      reject(null);
    }
  });
}