import { NavLink } from 'react-router-dom';
import { AboutHealthCardHero } from '../../../hooks/Contentful/Types/ContentfulTypes';

export interface IAboutHealthCardVideoPannerProps {
  data: AboutHealthCardHero;
}

export function AboutHealthCardVideoBanner(
  props: IAboutHealthCardVideoPannerProps
) {
  const { title, subtitle, youtubeVideo } = props.data;

  let videoId = youtubeVideo.split('v=')[1];
  const ampersandPosition = videoId.indexOf('&');
  if (ampersandPosition !== -1) {
    videoId = videoId.substring(0, ampersandPosition);
  }

  return (
    <div className="flex flex-col md:flex-row justify-between mb-20 lg:mx-24">
      <div className="md:order-last flex-grow lg:basis-1/2 pt-10 mx-10 lg:mx-20 h-64 lg:h-auto">
        <iframe
          width="100%"
          height="100%"
          src={`https://www.youtube.com/embed/${videoId}`}
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>

      <div className="flex flex-col justify-between flex-grow basis-1/2 px-10 lg:px-0 lg:py-10">
        <div className="mb-5 lg:mb-10 self-center flex flex-col mt-16">
          <h1 className="text-3xl lg:text-6xl mb-5 font-bold">{title}</h1>
          <h3 className="text-base lg:text-lg font-medium text-graySubtitle">
            {subtitle}
          </h3>
        </div>
        <NavLink
          to="/become-cardholder"
          className="text-white font-bold bg-rokketmedMain lg:px-5 py-4 lg:w-8/12 rounded-lg text-center hover:shadow-custom3 ease-in-out transition-all duration-200"
        >
          Become a health card holder
        </NavLink>
      </div>
    </div>
  );
}
