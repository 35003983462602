export interface IStarRatingProps {
  rating: number;
}

const styles = { starIcon: { paddingRight: '0.25rem' } };

export function StarRating(props: IStarRatingProps) {
  const fullStars = Math.floor(props.rating);
  const remainder = props.rating - fullStars;

  const stars = [];

  for (let i = 0; i < 5; i++) {
    if (i < fullStars) {
      stars.push(<i key={i} className='fa-solid fa-star text-amber-500'></i>);
    } else if (i === fullStars && remainder >= 0.75) {
      stars.push(<i key={i} className='fa-solid fa-star text-amber-500'></i>);
    } else if (i === fullStars && remainder >= 0.5) {
      stars.push(
        <i key={i} className='fa-solid fa-star-half text-amber-500'></i>
      );
    } else if (i === fullStars && remainder >= 0.25) {
      stars.push(
        <i key={i} className='fa-solid fa-star-quarter text-amber-500'></i>
      );
    } else {
      stars.push(<i key={i} className='fa-regular fa-star text-amber-500'></i>);
    }
  }

  return (
    <div className='text-amber-500 flex gap-2'>
      <div className='text-amber-500 font-medium'>
        <i
          style={styles.starIcon}
          className='fa-solid fa-star text-amber-500 gap-2'
        ></i>
        {props.rating}
      </div>
      <div>{stars}</div>
    </div>
  );
}
