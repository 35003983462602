import { loadingType } from '../MarketPlace';
import { PlaceResultWithDistance } from '../Services/services';
import { useState, useEffect } from 'react';
import { MarketplaceDesktopView } from './views/MarketplaceDesktopView';
import { MarketplaceMobileView } from './views/MarketplaceMobileView';
import { MentalHealthAndTelemedicine } from '../Services/telemedicineAndMentalHealthResults';

export interface IMarketplaceResultsProps {
  results?: (PlaceResultWithDistance | MentalHealthAndTelemedicine)[];
  loading: loadingType;
  userLocation: google.maps.LatLngLiteral;
}

export enum View {
  Mobile,
  Desktop,
}

export enum MarketplaceViewType {
  Map,
  List,
}

export function MarketplaceResults(props: IMarketplaceResultsProps) {
  const [showSpinner, setShowSpinner] = useState(false);

  const [results, setResults] = useState<
    (PlaceResultWithDistance | MentalHealthAndTelemedicine)[]
  >([]);

  const [view, setView] = useState<View>(View.Desktop);

  const [viewType, setViewType] = useState<MarketplaceViewType>(
    MarketplaceViewType.List ?? MarketplaceViewType.List // this is to get rid of the browser console warning even though it's not necessary
  );

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1000) {
        setView(View.Mobile);
      } else {
        setView(View.Desktop);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | null = null;

    if (props.loading === loadingType.Complete) {
      // if loading is complete, wait 1 second to hide the spinner
      timeoutId = setTimeout(() => {
        if (props.results) {
          setResults(sortResultsByDistance(props.results));
        }

        setShowSpinner(false);
      }, 1000);
    } else if (props.loading === loadingType.Loading) {
      // if loading is true, show the spinner
      setShowSpinner(true);
    } else {
      // if there is an error, show the error message
      setShowSpinner(false);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [props.loading, props.results]);

  function sortResultsByDistance(places: PlaceResultWithDistance[]) {
    return places.sort((a, b) => {
      if (a.distance && b.distance) {
        return parseFloat(a.distance) - parseFloat(b.distance);
      }
      return 0;
    });
  }

  return (
    <div className='flex flex-col items-center w-full h-full'>
      <div className='w-full h-full'>
        <div className='bg-[#E30C66] w-full pl-10 min-h-8 flex items-center text-white text-sm font-semibold justify-between align-middle '>
          <div className='inline-flex items-center cursor-pointer my-auto self-start justify-center'>
            {props.loading === loadingType.Loading && 'Loading...'}

            {props.loading === loadingType.Complete &&
              !showSpinner &&
              `${results?.length} Locations to display`}
          </div>

          {view === View.Mobile && (
            <label className='inline-flex items-center cursor-pointer m-5 gap-2 self-end'>
              <span className='ms-3 text-sm font-medium text-white'>List</span>
              <input
                type='checkbox'
                className='sr-only peer'
                checked={viewType === MarketplaceViewType.Map}
                onChange={() =>
                  setViewType(
                    viewType === MarketplaceViewType.List
                      ? MarketplaceViewType.Map
                      : MarketplaceViewType.List
                  )
                }
              />
              <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-rokketmedMain"></div>
              <span className='ms-3 text-sm font-medium text-white'>Map</span>
            </label>
          )}
        </div>

        {showSpinner && (
          <div className='flex flex-col-reverse justify-center items-center p-10 animate-fadeIn'>
            <p className='py-5'>Fetching Results...</p>
            <div className='animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-blue-500'></div>
          </div>
        )}

        {view === View.Mobile &&
          props.loading === loadingType.Complete &&
          results.length > 0 &&
          !showSpinner && (
            <MarketplaceMobileView
              view={view}
              showSpinner={showSpinner}
              loading={props.loading}
              userLocation={props.userLocation}
              results={props.results || []}
              viewType={viewType}
              setViewType={setViewType}
            />
          )}

        {view === View.Desktop &&
          props.loading === loadingType.Complete &&
          results.length > 0 &&
          !showSpinner && (
            <MarketplaceDesktopView
              showSpinner={showSpinner}
              loading={props.loading}
              userLocation={props.userLocation}
              results={props.results || []}
              sortedResults={results || []}
            />
          )}

        {props.loading === loadingType.Complete &&
          results?.length === 0 &&
          !showSpinner && (
            <div className='flex flex-col items-center justify-center w-full h-80'>
              <p className='text-center'>
                No results found... Please try another search.
              </p>
            </div>
          )}

        {props.loading === loadingType.Error && (
          <div className='flex flex-col items-center justify-center w-full h-80'>
            <p className='text-center text-red-500'>
              There was an error fetching the results. Please try again.
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
