import { NavLink } from 'react-router-dom';
import { AboutSection } from '../../../hooks/Contentful/Types/ContentfulTypes';

export interface AboutHealthcardInfoProps {
  data: AboutSection[];
}

export function AboutHealthcardInfo(props: AboutHealthcardInfoProps) {
  const sortedSections = props.data.sort((a, b) => a.position - b.position);

  if (sortedSections.length < 3) {
    return null;
  }

  return (
    <div className="bg-gray-100 w-full lg:px-20 lg:py-10 lg:mt-20">
      <div className="flex flex-col lg:flex-row lg:gap-20 w-full mt-10 xs:mb-6 lg:mb-16">
        <div className="lg:order-first w-full lg:w-1/2">
          <img
            src={sortedSections[0].featuredImage}
            alt=""
            className="rounded-xl w-full p-10 lg:p-0 lg:shadow-custom3"
          />
        </div>
        <div className="w-full lg:w-1/2 lg:px-0 px-10 flex flex-col justify-center">
          <h3 className="lg:text-4xl font-bold xs:text-3xl mb-6">
            {sortedSections[0].title}
          </h3>
          <p className="lg:text-lg xs:text-sm font-normal leading-7 align-left mb-5 text-graySubtitle">
            {sortedSections[0].description}
          </p>
        </div>
      </div>

      <div className="flex flex-col lg:flex-row lg:gap-20 w-full mt-10 xs:mb-6 lg:mb-16">
        <div className="lg:order-last w-full lg:w-1/2 ">
          <img
            src={sortedSections[1].featuredImage}
            alt=""
            className="lg:shadow-custom3 rounded-xl w-full p-10 lg:p-0"
          />
        </div>
        <div className="w-full lg:w-1/2 lg:px-0 px-10 flex flex-col justify-center">
          <h3 className="lg:text-4xl xs:text-3xl font-bold mb-6">
            {sortedSections[1].title}
          </h3>
          <p className="lg:text-lg xs:text-sm font-normal leading-7 align-left mb-5 text-graySubtitle">
            {sortedSections[1].description}
          </p>
          <NavLink
            to="/become-cardholder"
            className="font-bold w-72 text-sm text-rokketmedMain px-5 py-3 border border-rokketmedMain rounded-lg text-center hover:shadow-custom3 ease-in-out transition-all duration-200"
          >
            Become a health card holder
            <i className="fa-solid fa-arrow-right ml-2"></i>
          </NavLink>
        </div>
      </div>

      <div className="flex flex-col lg:flex-row lg:gap-20 w-full mt-10 xs:mb-6 lg:mb-16">
        <div className="lg:order-first w-full lg:w-1/2">
          <img
            src={sortedSections[2].featuredImage}
            alt=""
            className="rounded-xl w-full p-10 lg:p-0 lg:shadow-custom3"
          />
        </div>
        <div className="w-full lg:w-1/2 lg:px-0 px-10 flex flex-col justify-center">
          <h3 className="lg:text-4xl font-bold xs:text-3xl mb-6">
            {sortedSections[2].title}
          </h3>
          <p className="lg:text-lg xs:text-sm font-normal leading-7 align-left mb-5 text-graySubtitle">
            {sortedSections[2].description}
          </p>
        </div>
      </div>
    </div>
  );
}
