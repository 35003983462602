import { useTrackPageName } from '../../hooks/GoogleAnalytics/googleAnalytics';

export interface IPrivacyPolicyProps {}

export function PrivacyPolicy(props: IPrivacyPolicyProps) {
  useTrackPageName();
  return (
    <div className='container px-16 py-5 text-lg'>
      <p className='py-5'>Effective Date: September 2017</p>

      <p className='py-5'>
        RokketMed ("<strong>RokketMed</strong>," "<strong>Us</strong>," "
        <strong>Our</strong>" or "<strong>We</strong>") is committed to
        protecting your privacy. This Privacy Policy (“<strong>Policy</strong>
        ”) describes how we collect, use, store, process, and share your
        information in relation to RokketMed.com and all site-related services
        and mobile applications (collectively, the “
        <strong>RokketMed Service</strong>”). By accessing, browsing,
        downloading, or using the RokketMed Service, you expressly consent to
        the collection, use, storage, processing, and disclosure of your
        information, including personal information, as described in this
        Policy. IF YOU DO NOT AGREE WITH THIS POLICY, DO NOT ACCESS, DOWNLOAD,
        OR USE THE ROKKETMED SERVICE, REGISTER FOR AN ACCOUNT WITH ROKKETMED, OR
        CLICK TO INDICATE THAT YOU AGREE WITH THIS POLICY. This Policy is part
        of and incorporated into the{' '}
        <a className='text-rokketmedMain'>Terms of Use</a>&nbsp;governing your
        use of the RokketMed Service. Parts of the Terms of Use affect this
        Policy, so please be sure to review the Terms of Use prior to using the
        RokketMed Service. Capitalized terms not defined herein have the
        definitions set forth in the Terms of Use. THE CONTENTS OF THE ROKKETMED
        SERVICE ARE FOR INFORMATIONAL PURPOSES ONLY AND THE ROKKETMED SERVICE IS
        NOT DESIGNED TO, AND DOES NOT, PROVIDE MEDICAL ADVICE. IF YOU BELIEVE
        YOU ARE HAVING A MEDICAL EMERGENCY, CALL YOUR DOCTOR OR 911 IMMEDIATELY.
      </p>

      <ol className='list-decimal my-5 gap-2 flex flex-col'>
        <li>
          <strong>CHANGES TO THIS POLICY</strong>. RokketMed reserves the right
          to revise this Policy from time to time by updating it and posting it
          at [www.rokketmed.com], with the new Policy taking effect on the date
          of posting. The new Policy may be retroactive to a past date. You
          should periodically review this Policy to ensure you are familiar with
          the most current version. Your use of the RokketMed Service after the
          Effective Date of the updated version of this Policy will constitute
          your acceptance of the updated Policy.
        </li>
        <li>
          <strong>APPLICABILITY OF THIS POLICY. </strong>This Policy covers our
          treatment of information gathered by the RokketMed Service, including
          personally identifiable information and non-personally identifiable
          information. Personally identifiable information is information that
          can identify you. For example, your name and email address are
          personally identifiable information. This Policy does not apply to
          information collected by third party websites, services, or
          applications &nbsp;you may access from time to time via the RokketMed
          Service, \. We have no control over, and no responsibility or
          liability for any third party collection, use, disclosure, or
          retention of your information and that collection, use, disclosure,
          and retention is not subject to this Policy. Please review the privacy
          policies of any Linked Sites you may access.
        </li>
        <li>
          <strong>INFORMATION COLLECTION</strong>. We collect the following
          types of information:
          <ol className='list-decimal my-5 gap-2 flex flex-col'>
            <li>
              <strong>Account and Login Information</strong>. We collect
              personal information from you when you create an account to log
              into the RokketMed Service. If you create an account, you will be
              asked to provide information about yourself including but not
              limited to a user name, email address, and password selected by
              you. In order to access some features of the RokketMed Service,
              you may also be asked for some additional personally identifiable
              information, including but not limited to: (a) demographic data
              such as your gender or date of birth; (c) medical data such as
              your insurance information, health history, names of previous
              health care providers, or dates of medical visits; and (d)
              employer data such as your employer name and health plan
              identification information.
            </li>
            <li>
              <strong>User-Generated Content</strong>. The RokketMed Service may
              allow you to voluntarily submit User-Generated Content provided by
              or created by you, including healthcare provider reviews and
              comments. We may make this information publicly available in the
              RokketMed Service and we may save this information indefinitely
              and in a profile specific to you.
            </li>
            <li>
              <strong>Protected Health Information</strong>. Some of the
              information collected by RokketMed may be considered protected
              health information (“<strong>PHI</strong>”) under the Health
              Insurance Portability and Accountability Act of 1996 (“
              <strong>HIPAA</strong>”). HIPAA sets forth national standards for
              the protection of health information and applies to certain
              “Covered entities” or “Business associates” that transmit PHI in
              electronic form. PHI may include health, demographic, or
              health-related information that identifies an individual when the
              information is transmitted electronically, maintained
              electronically, or transmitted or maintained in any other format.
              45 C.F.R. § 160.103. RokketMed is not a Business Associate with
              you or a Covered Entity. RokketMed \ may release, disclose, or
              transmit your PHI&nbsp; as part of the RokketMed Services.
              &nbsp;You acknowledge that access to information submitted to
              RokketMed is vital to RokketMed’s efforts to develop, operate,
              promote, and evaluate the RokketMed Services. Therefore,
              notwithstanding any other provision hereof, you acknowledge that
              RokketMed will have access to and may use, create and use
              derivatives of, and disclose any data submitted by you ("Submitted
              Data"), whether or not it is PHI, &nbsp;for the following
              purposes: (a) analyzing, identifying, designing, and/or enabling
              current and future RokketMed Services; (b) administering,
              implementing, or evaluating RokketMed Services; (c) developing
              marketing or other promotional documentation for RokketMed
              Services; and (d) providing de-identified reports and information
              to RokketMed customers or to the public with respect to the
              RokketMed Services.
            </li>
            <li>&nbsp;</li>
            <li>
              <strong>Aggregate Information</strong>. RokketMed may provide to
              third parties, including to our provider or employer customers,
              information about you and other users that does not specifically
              identify you (“<strong>Aggregate Information</strong>”). For
              example, we might inform providers about [example: the search
              criteria used by you when seeking a healthcare provider for a
              specified medical condition; the distance you are willing to
              travel to visit a provider]. Depending on the circumstances of
              each instance, we may or may not charge third parties for this
              Aggregate Information. We also may not limit the third parties’
              use of Aggregate Information, except that we do require third
              parties to whom we disclose Aggregate Information to agree that
              they will not attempt to make this information personally
              identifiable by combining it with other databases.
            </li>
            <li>
              <strong>
                Support Requests or other Communications Submitted by You
              </strong>
              . If you send us any correspondence, such as emails or letters, we
              may use your contact information to respond to you. Any requests
              or communications you submit to us may be saved indefinitely and
              in a profile specific to you.
            </li>
            <li>
              <strong>Automatically Collected Information</strong>. Similar to
              other websites and applications, we may collect some information
              from you automatically and store it in log files. This information
              does not identify you.&nbsp; This collection may include, but is
              not limited to: [your IP address, browser type and language,
              referring/exit pages and URLs, other browser history, platform
              type, number of clicks, landing pages, cookie information, the
              pages you requested and viewed, the amount of time spent on
              particular pages, and the date and time]. We collect this
              non-identifying information in order to better understand our
              customer base, to more efficiently operate our business, to
              promote our products and services, and to improve the quality of
              the RokketMed Service. We may automatically collect information
              using the following mechanisms:
            </li>
          </ol>
        </li>
      </ol>

      <p className='py-5'>
        <em>Cookies</em>. A cookie is a small text file that is stored on a
        computer for record-keeping purposes.&nbsp; Our third-party analytics
        providers use cookies to record information about your activities on the
        RokketMed Service to “remember” you when you return to the RokketMed
        Service.&nbsp; Some cookies remain on your computer until you delete
        them.&nbsp; Others, like session ID cookies, expire when you close your
        browser.&nbsp; You may set your browser setting to attempt to reject
        cookies.&nbsp; See the section “Do-Not Track Settings” below for more
        information on how the RokketMed Service may respond to your browser
        settings.&nbsp; We do not control the use of cookies by third
        parties.&nbsp; See the section on “Third Party Analytics Providers”
        below.
      </p>

      <p className='py-5'>
        <em>Pixels (</em>
        <em>aka web beacons/web bugs/java script)</em>. Pixels are tiny graphics
        with a unique identifier that are used to track the online movements of
        web users. Unlike cookies, which are stored on a computer’s hard drive,
        pixels are small graphics that are about the size of the period at the
        end of the sentence that are embedded invisibly on web pages or in
        HTML-based emails. Our third-party analytics providers may place pixels
        on the RokketMed Service that track what other websites you visit (both
        before and after visiting the RokketMed Service). Our third-party
        analytics providers use information obtained from pixels to help us
        improve our business and the RokketMed Service. We do not control the
        use of pixels by third parties. See the section on "Third Party
        Analytics Providers” below.
      </p>

      <ol className='list-decimal my-5'>
        <li value='8'>
          <strong>Third Party Analytics Providers</strong>. We may use third
          parties to help us operate and improve the RokketMed Service. We may
          provide these third parties with information we collect and they may
          collect information from you about your use of the RokketMed Service.
          If we provide information to third parties, it will, unless
          specifically noted otherwise in this Policy, be governed by this
          Policy and may only be used by those third parties to help us operate
          or improve our business or the RokketMed Service and to provide
          services to us. We do not control information collected by third
          parties and are not responsible for their use of that information.
        </li>
        <li value='4'>
          <strong>INFORMATION USE</strong>.
          <ol className='list-decimal my-5 gap-2 flex flex-col'>
            <li>
              <strong>General</strong>. We may use your personally identifiable
              information to contact you, to administer your account, and to
              provide services that may be relevant to you, including to
              [recommend healthcare providers, to help you schedule appointments
              with healthcare providers, or to send you reminders about upcoming
              appointments.] We may also use your personally identifiable
              information to operate the RokketMed Service; provide you with
              support and a more personalized experience; communicate with you;
              monitor usage of the RokketMed Service; improve the RokketMed
              Service; develop new websites, applications, or other products;
              provide you information about products and services [(including
              the products and services of third parties)]; resolve disputes;
              detect and protect against errors, fraud, and criminal activity;
              assist law enforcement; enforce this Policy and the Terms of Use;
              or for any other purpose described in this Policy or that we
              describe to you at the time of collection.
            </li>
            <li>
              <strong>Communications</strong>. We may use your personally
              identifiable information to send you service-related and
              non-service related emails, push-notifications, or other
              communications. For example, if the RokketMed Service is
              temporarily unavailable for maintenance, we may send you an email
              notice. We may also send you communications related to your
              account or in response to your inquiries or requests. You may not
              opt-out of service-related messages which are not promotional in
              nature.&nbsp; We may also send you marketing emails to notify you
              about products or services that may be of interest to you. If you
              would like to stop receiving marketing email from us or from third
              parties, please [“click on the opt-out link in any non-service
              related email you receive or contact us directly at{' '}
              <a href='mailto:info@rokketmed.com'>info@rokketmed.com</a>{' '}
              requesting to opt out of marketing emails”]. If you opt out, you
              will still continue to receive service-related emails.
            </li>
            <li>
              <strong>Non-Personally Identifiable Information</strong>. We may
              use non-personally identifiable information for any lawful
              purpose, including, but not limited to, analyzing trends,
              administering the RokketMed Service, tracking users’ movements,
              and to improve our business and the RokketMed Service. We may
              share aggregate information with third parties to develop and
              deliver targeted advertising and to analyze and report on
              advertising you see. We may also combine non-personally
              identifiable information with non-personally identifiable
              information collected from other sources.
            </li>
            <li>
              <strong>Information Retention. </strong>We will retain your
              information indefinitely, or as long as legally required or
              allowed. Even if you cancel your account or your account is
              otherwise terminated, we may, but are not obligated to, delete
              information we have collected or obtained from you or third
              parties and we may continue to use and disclose your information
              in accordance with this Policy. We do not undertake any retention
              obligations through this statement.
            </li>
          </ol>
        </li>
        <li value='5'>
          <strong>INFORMATION DISCLOSURE.</strong>
          <ol className='list-decimal gap-2 my-5 flex flex-col'>
            <li>
              <strong>Affiliated Entities and Service Providers</strong>. We may
              disclose your information to any affiliated entity or organization
              and to agents and service providers. These third parties may
              include customer service providers, business or sales service
              providers, or providers that supply support for the RokketMed
              Service. Use of information by affiliated entities and
              organizations will be subject to this Policy or an agreement that
              is at least as restrictive as this Policy.&nbsp; For more
              information on the use of information by agents and service
              providers, please see the “Third Party Analytics Providers”
              Section above.&nbsp;
            </li>
            <li>
              <strong>User-Generated Content</strong>. If you submit
              User-Generated Content in connection with healthcare provider
              reviews or for any other purpose, we may make it publicly
              available through the RokketMed Service as well as directly to
              healthcare providers on a de-identified basis to help them improve
              their business and service based on customer feedback.
            </li>
            <li>
              <strong>Third Party Healthcare Providers and Employers</strong>.
              RokketMed may disclose your personally identifiable information,
              your PHI, and/or Aggregate Information (defined above) to third
              party healthcare providers or employers in order to provide
              services related to [scheduling an appointment online and or
              paying for a service in advance of an appointment].
            </li>
            <li>
              <strong>Legal Requirements. </strong>In some cases, we may
              disclose your information (i) as required by any applicable law or
              legal regulation; (ii) if we believe disclosure is needed to
              protect our rights, property, or safety; (iii) to government
              regulators or law enforcement authorities in connection with any
              judicial proceeding, court order, subpoena, or legal process
              served on us or the RokketMed Service; and (iv) to respond to a
              physical threat to you or another person.
            </li>
          </ol>
        </li>
      </ol>

      <p className='py-5'>
        <strong>E.&nbsp;&nbsp;&nbsp;&nbsp; </strong>
        <strong>Insolvency and Business Transitions.</strong> If we should ever
        file for bankruptcy or engage in a business transition such as a merger
        with another company, or if we purchase, sell, or reorganize all or part
        of the RokketMed Service or our business or assets, we may disclose your
        information, including personal information, to prospective or actual
        purchasers in connection with one of these transactions.
      </p>

      <ol className='list-decimal gap-2 my-5 flex flex-col'>
        <li value='6'>
          <strong>Disclaimer. </strong>We cannot ensure that all of your
          information will never be disclosed in ways not otherwise described in
          this Policy. For example, a third party may unlawfully intercept or
          access transmissions or private communications, or other users of the
          RokketMed Service may abuse or misuse your personal information. No
          transmission of data over the internet can be 100% secure.
        </li>
        <li value='6'>
          <strong>INFORMATION SECURITY MEASURES.</strong> Keeping your
          information secure is of great concern to us and we exercise care in
          facilitating the transmission of information between your device or
          computer and [our servers/ the third-party servers that operate and
          store information for the RokketMed Service]. The personal information
          we collect is stored in operating environments that are not available
          to the public. While we have mechanisms in place to safeguard your
          personal information once we receive it, no transmission of data over
          the Internet or any other public network can be guaranteed to be 100%
          secure.
        </li>
        <li value='7'>
          <strong>“DO NOT TRACK” SETTINGS.</strong> Because we track usage
          information through the use of cookies, pixels and other tracking
          mechanisms, your selection of the “Do Not Track” option provided by
          your browser may not have any effect on our collection of cookie
          information. The only way to completely “opt out” of the collection of
          any information through cookies or other tracking technology is to
          actively manage the settings on your browser to delete and disable
          cookies and other tracking/recording tools. However, getting a “Do Not
          Track” signal to work as you might want is difficult.&nbsp; Using
          browsers as an example, not all tracking technologies can be
          controlled by browsers; unique aspects of your browser might be
          recognizable even if you disable a tracking technology; not all
          settings will necessarily last or be effective; even if a setting is
          effective for one purpose, data still may be collected for another;
          and even if one web site observes a “Do Not Track” signal, that web
          site usually will not be able to control other web sites.
        </li>
        <li value='8'>
          <strong>CHILDREN’S PRIVACY.</strong>
        </li>
      </ol>

      <p className='py-5'>
        <strong>A.&nbsp;&nbsp;&nbsp; </strong>
        <strong>Services Not For or Directed Towards Children. </strong>
        Children under the age of 13 are prohibited from using or registering
        for an account for the RokketMed Service and we will not knowingly
        collect any information from children under the age of 13. If we learn
        that we have collected information from a child under the age of 13, we
        will remove that information immediately and delete it from our servers.
        If you believe a child under 13 is using the RokketMed Service, please
        notify us at: [info@rokketmed.com].
      </p>

      <p className='py-5'>
        <strong>B.&nbsp;&nbsp;&nbsp; </strong>
        <strong>California Minors. </strong>We may provide you the ability to
        post information in the RokketMed Service.&nbsp; If you are a California
        resident who is under age 18 and a registered user of the RokketMed
        Service, and you are unable to remove publicly-available content that
        you have submitted to the RokketMed Service, you may request removal by
        contacting us at: [info@rokketmed.com]. When requesting removal, you
        must be specific about the information or content you want removed and
        provide us with specific information, such as the URL for each page
        where the information is located, so that we can find it. We are not
        required to remove any content or information that: (1) federal or state
        law requires us or a third party to maintain; (2) the content or
        information was not posted by you, the registered user; (3) we anonymize
        the content or information so that you cannot be identified; (4) you
        don’t follow our instructions for removing or requesting removal; or (5)
        you received compensation or other consideration for providing the
        content or information. REMOVAL OF YOUR CONTENT OR INFORMATION FROM THE
        SITE OR APPLICATION DOES NOT ENSURE COMPLETE OR COMPREHENSIVE REMOVAL OF
        THAT CONTENT OR INFORMATION FROM OUR SYSTEMS OR THE SYSTEMS OF OUR
        SERVICE PROVIDERS.&nbsp; We are not required to delete the content or
        information posted by you; our obligations under California law are
        satisfied so long as we anonymize the content or information or render
        it invisible to other users and the public.&nbsp; Also, some content and
        information you post that has been copied or reposted by a third party
        is outside of our control and will not be removed.
      </p>

      <p className='py-5'>
        <strong>9.&nbsp;&nbsp;&nbsp;&nbsp; </strong>
        <strong>CALIFORNIA SHINE THE LIGHT LAW. </strong>California Civil Code
        Section 1798.83 permits California residents to request and obtain from
        us once a year, free of charge, a list of the third parties to whom we
        have disclosed personal information (as defined under that statute) of
        that California resident, for direct marketing purposes in the preceding
        calendar year and the categories of that kind of personal information
        that was disclosed to them. If you are a California resident and you
        wish to make such a request, you may do so by contacting us at:
        [info@rokketmed.com].
      </p>

      <p className='py-5'>
        <strong>10.&nbsp;&nbsp; </strong>
        <strong>
          ACCESSING, UPDATING, OR DELETING YOUR PERSONAL INFORMATION.{' '}
        </strong>
        If your personal information changes or if you would like to cancel your
        account, you may do so by contacting us at info@rokketmed.com. We will
        make good faith efforts to resolve requests to correct inaccurate
        information except where the request is unreasonable, requires
        disproportionate technical effort or expense, jeopardizes the privacy of
        others, or would be impractical. If you terminate your account, your
        information will no longer be accessible through your account or
        associated with your account. However, activity that occurred prior to
        deletion may still be accessible by the public and will continue to be
        stored on our servers.
      </p>

      <p className='py-5'>
        <strong>11.&nbsp;&nbsp; </strong>
        <strong>PRIVACY QUESTIONS.</strong> If you have questions or concerns
        about our privacy practices or about this Policy, please contact us at:
        [info@rokketmed.com].
      </p>
    </div>
  );
}
