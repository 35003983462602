import * as React from 'react';

export interface IMissionStatementProps {}

export function MissionStatement(props: IMissionStatementProps) {
  return (
    <div className="flex flex-col xs:text-sm lg:text-xl text-graySubtitle font-medium">
      <p className="mb-5">
        Driven by this ethos, RokketMed is dedicated to pioneering the
        Next-Generation Healthcare platform. Our primary focus lies in
        eliminating the inefficiencies entrenched within our healthcare system
        over the past century. We identify three critical areas of friction
        hindering the health and vitality of your workforce: paying for care,
        access to care, and health education.
      </p>

      <p className="my-5">
        Our unwavering objective is to assist our clients in furnishing their
        workforce with affordable health benefits that resonate with their
        business objectives and are easy to understand and utilize. By doing so,
        we aim to cultivate enduring employee satisfaction and loyalty, thereby
        fostering a thriving organizational culture.
      </p>
    </div>
  );
}
