export interface IInfoWindowDisplayProps {
  address: string;
  icon?: string;
  imageUrl?: string;
  name: string;
  phone: string;
  placeId: string;
  websiteURL: string;
}

export function InfoWindowDisplay(
  props: IInfoWindowDisplayProps & { onClick: (placeId: string) => void }
) {
  const { name, address, phone, imageUrl, icon, placeId, websiteURL } = props;
  return (
    <div
      className="flex w-64 items-center gap-5 p-2 animate-fadeIn cursor-pointer rounded-lg bg-white ease-in transition-all duration-100"
      onClick={() => props.onClick(placeId)}
    >
      {imageUrl ? (
        <img
          src={imageUrl}
          alt=""
          className="rounded-full object-cover outline-none h-16 w-16 bg-gray-200"
        />
      ) : (
        <img
          src={icon}
          alt=""
          className="rounded-full object-cover outline-none h-16 w-16 bg-gray-200"
        />
      )}
      <div className="flex flex-col gap-2">
        <h3 className="font-normal font-semibold hover:underline">{name}</h3>
        <a
          href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
            address
          )}`}
          className="text-blue-700 text-xs hover:underline"
          target="_blank"
          rel="noopener noreferrer"
        >
          {address}
        </a>

        <a
          href={`tel:+1${phone}`}
          className="text-blue-700 text-xs hover:underline"
        >
          <i className="fa-solid fa-phone pr-3 text-darkText"></i>
          {phone}
        </a>
        <a
          href={websiteURL}
          target="_blank"
          className="text-blue-700 text-xs hover:underline"
        >
          <i className="fa-regular fa-calendar text-lg pr-3 text-darkText " />
          Schedule Now
        </a>
      </div>
    </div>
  );
}
