import { useState } from 'react';
import { FormInput } from './SearchBar/FormInput';
import { EmailData, sendEmail } from '../../hooks/sendEmail';

export interface IPopupProps {
  onClose: () => void;
}

export interface PopupFormData {
  debtAmount: number;
  needHelpWithDebt?: boolean;
  workPlace?: string;
  email: string;
  age?: number;
}

export function Popup({ onClose }: IPopupProps) {
  const [debtAmount, setDebtAmount] = useState('');
  const [needHelpWithDebt, setNeedHelpWithDebt] = useState<string>('yes');
  const [workPlace, setWorkPlace] = useState('');
  const [email, setEmail] = useState('');
  const [age, setAge] = useState('');

  const [confirmed, setConfirmed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const [errors, setErrors] = useState({
    debtAmount: '',
    email: '',
    age: '',
  });

  function triggerConfirmation() {
    setConfirmed(true);
  }

  function resetForm() {
    setDebtAmount('');
    setNeedHelpWithDebt('yes');
    setWorkPlace('');
    setEmail('');
    setAge('');
  }

  function validateForm(): boolean {
    let formErrors = {
      debtAmount: '',
      email: '',
      age: '',
    };

    if (!debtAmount || isNaN(Number(debtAmount))) {
      formErrors.debtAmount = 'This field must be a number';
    }

    if (!email) {
      formErrors.email = 'This field is required';
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        formErrors.email = 'Invalid email format';
      }
    }

    if (age && isNaN(Number(age))) {
      formErrors.age = 'This field must be a number';
    }

    setErrors(formErrors);

    return !Object.values(formErrors).some((error) => error);
  }

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }

    try {
      const emailData: EmailData = {
        subject: 'Medical Debt Popup Form Submission',
        data: {
          debtAmount,
          needHelpWithDebt,
          workPlace,
          email,
          age,
        },
      };

      setLoading(true);
      await sendEmail(emailData);
      setLoading(false);
      triggerConfirmation();
      resetForm(); // Reset the form immediately after successful submission
    } catch (error) {
      setLoading(false);
      setHasError(true);
    }
  }

  return (
    <div className="popup animate-fadeInLeft animate-tada shadow-custom3 rounded-md w-80">
      <div className="popup-inner">
        <form
          onSubmit={handleSubmit}
          className="p-5 bg-white rounded-xl relative"
        >
          <div className="w-full flex justify-end">
            <button
              className="hover:pointer close-btn flex absolute top-2.5 right-3.5"
              onClick={onClose}
              type="button"
            >
              <i className="fas fa-times text-md flex-start text-graySubtitle"></i>
            </button>
          </div>
          <h2 className="text-center my-2.5 text-lg font-medium">
            Do you have medical debt?
          </h2>
          <label className="text-xs font-semibold">
            If yes, about how much is your medical debt?*
          </label>
          <FormInput
            onChange={(value) => setDebtAmount(value)}
            placeholder="e.g. 5000"
            errorMessage={errors.debtAmount}
            type="number"
            value={debtAmount} // Ensure input reflects state
            padding={2}
          />
          <div className="mt-4 mb-3">
            <label className="text-xs font-semibold">
              Need help paying off your medical debt?
            </label>
            <div className="flex items-center mt-2 text-xs">
              <input
                type="radio"
                id="helpYes"
                name="needHelpWithDebt"
                value="yes"
                checked={needHelpWithDebt === 'yes'}
                onChange={(e) => setNeedHelpWithDebt(e.target.value)}
                className="mr-2 "
              />
              <label htmlFor="helpYes" className="mr-4">
                Yes
              </label>
              <input
                type="radio"
                id="helpNo"
                name="needHelpWithDebt"
                value="no"
                checked={needHelpWithDebt === 'no'}
                onChange={(e) => setNeedHelpWithDebt(e.target.value)}
                className="mr-2"
              />
              <label htmlFor="helpNo">No</label>
            </div>
          </div>
          <label className="text-xs font-semibold">Where do you work?</label>
          <FormInput
            onChange={(value) => setWorkPlace(value)}
            placeholder="e.g. Microsoft"
            value={workPlace} // Ensure input reflects state
            padding={2}
          />
          <label className="text-xs font-semibold">Email*</label>
          <FormInput
            onChange={(value) => setEmail(value)}
            placeholder="e.g. example@example.com"
            type="email"
            errorMessage={errors.email}
            value={email} // Ensure input reflects state
            padding={2}
          />
          <label className="text-xs font-semibold">Age</label>
          <FormInput
            onChange={(value) => setAge(value)}
            placeholder="e.g. 30"
            type="number"
            errorMessage={errors.age}
            value={age} // Ensure input reflects state
            padding={2}
          />
          <button
            type="submit"
            className="bg-rokketmedMain text-sm mt-2 px-5 py-2 text-white w-full rounded-lg"
            disabled={loading}
          >
            Submit
          </button>
          {loading && (
            <p className="text-[#E30C79] font-bold w-full text-center p-2 animate-fadeIn">
              Loading...
            </p>
          )}
          {confirmed && (
            <p className="text-gray-500 text-xs text-center w-full p-2 animate-fadeIn">
              Sent Successfully! Learn about how you can save on medical
              expenses with the{' '}
              <a
                target="_blank"
                href="/about-healthcard"
                className="text-rokketmedMain font-bold hover:underline"
              >
                RokketMed Health Card
              </a>
            </p>
          )}
          {hasError && (
            <p className="text-red-500 font-bold w-full p-2 animate-fadeIn">
              There was an error sending your request. Please try again.
            </p>
          )}
        </form>
      </div>
    </div>
  );
}
