import { FooternavLink, Size } from './FooterNavLink';

const xLink = 'https://twitter.com/rokketmed';
const linkedInLink = 'https://www.linkedin.com/company/rokketmed/';
const facebookLink = 'https://www.facebook.com/rokketmed';

function Footer() {
  return (
    <footer
      style={{
        backgroundColor: 'rgba(60, 60, 60, 1)',
        position: 'relative',
        bottom: 0,
        left: 0,
        right: 0,
        padding: '1rem',
        width: '100%',
        boxSizing: 'border-box',
      }}
    >
      <div className="w-full flex justify-between gap-4 md:mr-10">
        <div
          className="xs:flex-col md:flex-row lg:flex-row xl:flex-row"
          style={{ display: 'flex', flexWrap: 'nowrap' }}
        >
          <FooternavLink to="/contact-us" display="Contact Us" />
          <FooternavLink to="/about-us" display="About Us" />
          <FooternavLink
            to="/become-cardholder"
            display="Become a Cardholder"
          />
          <FooternavLink to="/blog" display="Blog" />
        </div>
        <div className="flex items-center gap-4 flex-col md:flex-row mr-5 mt-5 md:mr-0 md:mt-0">
          <a href={xLink} target="_blank" rel="noreferrer">
            <img
              src={require('../../../../assets/Icons/xLogo.svg').default}
              alt=""
              className="mr-2"
            />
          </a>
          <a href={linkedInLink} target="_blank" rel="noreferrer">
            <img
              src={require('../../../../assets/Icons/linkedInLogo.svg').default}
              alt=""
              className="mr-2"
            />
          </a>
          <a href={facebookLink} target="_blank" rel="noreferrer">
            <img
              src={require('../../../../assets/Icons/facebookLogo.svg').default}
              alt=""
              className="mr-2"
            />
          </a>
        </div>
      </div>
      <div className="flex flex-col w-full">
        <div className="flex justify-center lg:justify-start self-center w-full whitespace-nowrap text-white text-xs lg:text-sm box-border ">
          <div className="flex gap-1 flex-nowrap">
            <FooternavLink
              to="/privacy-policy"
              display="Privacy Policy"
              size={Size.small}
            />
            <div
              style={{
                border: '1px solid rgba(255, 255, 255, 1)',
                display: 'inline-block',
                margin: '5% 0.25%',
              }}
            ></div>
            <FooternavLink
              to="/terms-of-service"
              display="Terms of Service"
              size={Size.small}
            />
          </div>
        </div>
        <div className="flex justify-center self-center w-full whitespace-nowrap text-white text-xs lg:text-sm">
          2024 &copy; RokketMed. All Rights Reserved.
        </div>
      </div>
    </footer>
  );
}

export default Footer;
