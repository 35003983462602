import { useState } from 'react';
import { SearchableDropDownInput } from '../../../components/utils/SearchableDropDownInput';
import { EmailData, sendEmail } from '../../../hooks/sendEmail';
import {
  FormErrorMessage,
  FormInput,
} from '../../../components/utils/SearchBar/FormInput';
import { listOfEveryUsState } from '../../../assets/Data/StateData';

export interface BecomeACardholderInfo {
  companyName: string;
  numberOfEmployees: number;
  firstName: string;
  lastName: string;
  email: string;
  city: string;
  state: string;
  additionalInformation: string;
}

export interface IHealthCardFormProps {}

export function HealthCardForm(props: IHealthCardFormProps) {
  const [stateValue, setStateValue] = useState('');

  const [loading, setLoading] = useState(false);
  const [formKey, setFormKey] = useState(0);

  const [confirmed, setConfirmed] = useState(false);
  const [hasError, setHasError] = useState(false);

  const [companyName, setCompanyName] = useState('');
  const [numberOfEmployees, setNumberOfEmployees] = useState(0);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [city, setCity] = useState('');
  const [additionalInformation, setAdditionalInformation] = useState('');

  const [errors, setErrors] = useState({
    companyName: '',
    numberOfEmployees: '',
    firstName: '',
    lastName: '',
    email: '',
    city: '',
    stateValue: '',
    additionalInformation: '',
  });

  function triggerConfirmation() {
    setConfirmed(true);
    setTimeout(() => {
      setConfirmed(false);
    }, 5000);
  }

  function resetForm() {
    setCompanyName('');
    setNumberOfEmployees(0);
    setFirstName('');
    setLastName('');
    setEmail('');
    setCity('');
    setStateValue('');
    setAdditionalInformation('');
  }

  const handleLocationSelected = (location: string) => {
    setStateValue(location);
  };

  function validateForm() {
    let formErrors = {
      companyName: '',
      numberOfEmployees: '',
      firstName: '',
      lastName: '',
      email: '',
      city: '',
      stateValue: '',
      additionalInformation: '',
    };

    setErrors(formErrors);
    setHasError(false);

    if (!companyName) formErrors.companyName = 'Company name is required';
    if (!numberOfEmployees)
      formErrors.numberOfEmployees = 'Number of employees is required';
    if (!firstName) formErrors.firstName = 'First name is required';
    if (!lastName) formErrors.lastName = 'Last name is required';
    if (!email) formErrors.email = 'Email is required';
    if (!city) formErrors.city = 'City is required';
    if (!stateValue) formErrors.stateValue = 'State is required';

    setErrors(formErrors);

    return Object.values(formErrors).every((x) => !x);
  }

  async function handleSubmit(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    e.preventDefault();

    // Validate form fields
    if (!validateForm()) {
      return;
    }

    try {
      const emailData: EmailData = {
        subject: 'Become A Cardholder Request',
        data: {
          companyName,
          numberOfEmployees: numberOfEmployees.toString(),
          firstName,
          lastName,
          email,
          city,
          state: stateValue,
          additionalInformation: additionalInformation,
        },
      };

      setLoading(true);
      await sendEmail(emailData);
      setLoading(false);
      triggerConfirmation();
      resetForm();
      setFormKey((prevKey) => prevKey + 1);
    } catch (error) {
      setLoading(false);
      setHasError(true);
      console.error(error);
    }
  }

  return (
    <form
      key={formKey}
      className="bg-white p-10 rounded-xl mx-auto lg:ml-20 inline-block w-[22rem] lg:w-96"
    >
      <FormInput
        onChange={setCompanyName}
        placeholder="Company Name*"
        errorMessage={errors.companyName}
      />

      <FormInput
        onChange={(value) => setNumberOfEmployees(parseInt(value))}
        placeholder="Number of Employees*"
        errorMessage={errors.numberOfEmployees}
        type="number"
      />

      <div className="flex gap-2">
        <FormInput
          onChange={setFirstName}
          placeholder="First Name*"
          errorMessage={errors.firstName}
          width="1/2"
        />

        <FormInput
          onChange={setLastName}
          placeholder="Last Name*"
          errorMessage={errors.lastName}
          width="1/2"
        />
      </div>

      <FormInput
        onChange={setEmail}
        placeholder="Email*"
        errorMessage={errors.email}
      />

      <div className="flex gap-2">
        <FormInput
          onChange={setCity}
          placeholder="City*"
          errorMessage={errors.city}
          width="1/2"
        />

        <div className="w-1/2 mb-3">
          <SearchableDropDownInput
            inputPlaceholder="State*"
            handleDropDownItemSelected={handleLocationSelected}
            dropDownItems={listOfEveryUsState}
          />
          <FormErrorMessage message={errors.stateValue} />
        </div>
      </div>

      <textarea
        onChange={(e) => setAdditionalInformation(e.target.value)}
        className="w-full border-2 border-gray-200 rounded-md mb-3 px-5 py-2 text-xs"
        placeholder="Additional Information*"
        cols={30}
        rows={4}
      />

      <button
        onClick={handleSubmit}
        disabled={loading}
        className="bg-rokketmedMain px-5 py-2 text-white w-full rounded-lg"
      >
        Submit
      </button>

      {loading && (
        <p className="text-[#E30C79] font-bold w-full text-center p-2 animate-fadeIn">
          Loading...
        </p>
      )}

      {confirmed && (
        <p className="text-[#E30C79] font-bold w-full text-center p-2 animate-fadeIn">
          Your request has been sent!
        </p>
      )}

      {hasError && (
        <p className="text-red-500 font-bold w-full text-center p-2 animate-fadeIn">
          There was an error sending your request. Please try again.
        </p>
      )}
    </form>
  );
}
