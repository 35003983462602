import Header from './Header/Header';
import Footer from './Footer/Footer';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

type LayoutProps = {
  children: React.ReactNode;
};

function Layout({ children }: LayoutProps) {
  const [showFooter, setShowFooter] = useState(true);
  const location = useLocation();

  // set show footer to false if the current page is the marketplace and the display is mobile
  useEffect(() => {
    const path = location.pathname;
    if (path === '/marketplace') {
      setShowFooter(false);
    } else {
      setShowFooter(true);
    }
  }, [location]);

  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}
    >
      <Header />
      <main style={{ flex: 1, display: 'flex' }}>{children}</main>

      {showFooter && <Footer />}
    </div>
  );
}

export default Layout;
