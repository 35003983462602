import {
  MentalHealthAndTelemedicine,
  getMentalHealthAndTelemedicine,
} from './telemedicineAndMentalHealthResults';

export interface HealthNeed {
  display: string;
  searchString: string;
}

export const healthNeedsSearchList: HealthNeed[] = [
  { display: 'A1C test', searchString: 'ac1 test near me' },
  {
    display: 'Acid Reflux',
    searchString: 'urgent care | urgent care',
  },
  { display: 'Acne', searchString: 'dermatologist | urgent care' },
  {
    display: 'Administrative and College Physical',
    searchString: 'physicals | urgent care',
  },
  {
    display: 'Allergy Symptoms',
    searchString: 'allergy treatment |  pharmacy | urgent care',
  },
  { display: 'Anger issues', searchString: 'Mental Health' },
  { display: 'Anxiety', searchString: 'Mental Health' },
  { display: 'Asthma', searchString: 'urgent care | walk-in clinics' },
  {
    display: 'Athlete’s Foot',
    searchString: 'dermatologist | urgent care',
  },
  {
    display: 'Back & Joint Pain',
    searchString: 'chiropractor | urgent care',
  },
  { display: 'Birth Control', searchString: "women's clinic | pharmacy" },
  { display: 'Blood glucose test', searchString: 'blood test | urgent care' },
  { display: 'Breast Screening', searchString: "women's clinic" },
  { display: 'Breast infection', searchString: "women's clinic" },
  { display: 'Bronchitis & Cough', searchString: 'urgent care' },
  { display: 'Bug bites & Stings', searchString: 'urgent care | pharmacy' },
  { display: 'Burns', searchString: 'dermatology | urgent care' },
  { display: 'COPD', searchString: 'COPD | urgent care' },
  { display: 'Camp Physicals', searchString: 'physicals | urgent care' },
  {
    display: 'Chicken Pox Vaccines',
    searchString: 'vaccines | urgent care',
  },
  { display: 'Chicken Pox', searchString: 'urgent care' },
  { display: 'Cholesterol Screening', searchString: 'urgent care' },
  { display: 'Chronic Bronchitis', searchString: 'urgent care' },
  { display: 'Cold, Cough, & Flu', searchString: 'urgent care' },
  { display: 'Cold Sores', searchString: 'Dermatologist' },
  {
    display: 'Complete Blood Count (CBS)',
    searchString: 'urgent care',
  },
  { display: 'DOT Physicals', searchString: 'physicals | urgent care' },
  { display: 'Diabetes monitoring', searchString: 'diabetes | urgent care' },
  { display: 'Diarrhea', searchString: 'pharmacy | urgent care' },
  { display: 'Dizziness', searchString: 'dizziness | urgent care' },
  { display: 'Ear pain', searchString: 'urgent care' },
  { display: 'Ear wax removal', searchString: 'urgent care' },
  { display: 'Ear Infection', searchString: 'urgent care' },
  { display: 'Eczema', searchString: 'Dermatologist' },
  { display: 'Emphysema', searchString: 'urgent care' },
  { display: 'Erectile Dysfunction', searchString: 'urgent care' },
  { display: 'Emergency Contraception', searchString: 'Gynecologist' },
  { display: 'Flu A&B test', searchString: 'urgent care' },
  { display: 'Flu shot', searchString: 'Immunization Clinic' },
  { display: 'Genital Herpes', searchString: 'Sexual Health Clinic' },
  { display: 'Gout', searchString: 'urgent care' },
  { display: 'HPV vaccine', searchString: 'vaccines | urgent care' },
  { display: 'Health Screening', searchString: 'urgent care' },
  { display: 'Heartburn', searchString: 'urgent care' },
  { display: 'Hepatitis A&B Vaccine', searchString: 'vaccines | urgent care' },
  {
    display: 'High blood pressure monitoring',
    searchString: 'urgent care',
  },
  {
    display: 'High cholesterol monitoring',
    searchString: 'urgent care',
  },
  { display: 'Hives & Rashes', searchString: 'dermatologist | urgent care' },
  { display: 'Joint pain', searchString: 'urgent care' },
  { display: 'Laryngitis', searchString: 'urgent care' },
  { display: 'Lice', searchString: 'urgent care' },
  { display: 'MMR vaccine', searchString: 'vaccines | urgent care' },
  { display: 'Mental Health', searchString: 'Mental Health' },
  { display: 'Minor back pain', searchString: 'urgent care' },
  { display: 'Minor burns', searchString: 'urgent care | pharmacy' },
  {
    display: 'Minor cuts including stitches',
    searchString: 'urgent care',
  },
  { display: 'Minor cuts and burns', searchString: 'urgent care' },
  { display: 'Mono', searchString: 'urgent care' },
  {
    display: 'Motion sickness prevention',
    searchString: 'urgent care | pharmacy',
  },
  { display: 'Nausea', searchString: 'pharmacy | urgent care' },
  { display: 'Obesity/Weight loss', searchString: 'weight loss center' },
  {
    display: 'One-time medication renewal',
    searchString: 'urgent care | pharmacy',
  },
  { display: 'Pink eye or Stye', searchString: 'urgent care' },
  { display: 'Pneumonia Vaccine', searchString: 'vaccines | urgent care' },
  {
    display: 'Poison Ivy & Poison Oak',
    searchString: 'urgent care | pharmacy',
  },
  { display: 'Pregnancy Test', searchString: 'pharmacy' },
  { display: 'Rapid Test', searchString: 'urgent care' },
  { display: 'Rapid Step Test', searchString: 'urgent care' },
  {
    display: 'Rash',
    searchString: 'dermatologist | urgent care',
  },
  { display: 'Ringworm', searchString: 'dermatologist | urgent care' },
  { display: 'STD Test', searchString: 'urgent care' },
  { display: 'STI', searchString: 'urgent care' },
  { display: 'Shingles', searchString: 'urgent care' },
  {
    display: 'Sinus Infection & Congestion',
    searchString: 'urgent care',
  },
  { display: 'Skin tag removal', searchString: 'dermatologist | urgent care' },
  { display: 'Smoking Cessation', searchString: 'urgent care' },
  { display: 'Splinter removal', searchString: 'urgent care' },
  { display: 'Sore Throat', searchString: 'urgent care | pharmacy' },
  { display: 'Severe Cuts', searchString: 'urgent care | hospital' },
  { display: 'Sports physical', searchString: 'physicals | urgent care' },
  {
    display: 'Sprains & Strains',
    searchString: 'urgent care',
  },
  {
    display: 'Stitches and Staples removal',
    searchString: 'urgent care',
  },
  { display: 'Strep Throat', searchString: 'urgent care' },
  { display: 'Stress', searchString: 'Mental Health' },
  { display: 'Sunburn', searchString: 'dermatologist | urgent care' },
  { display: 'Swimmers ear', searchString: 'urgent care' },
  { display: 'TB skin test', searchString: 'urgent care' },
  { display: 'Tick bites', searchString: 'urgent care' },
  { display: 'Telemedicine', searchString: 'Telemedicine' },
  {
    display: 'Upper Respiratory Infection',
    searchString: 'urgent care',
  },
  { display: 'Urinary Tract Infection (UTI)', searchString: 'urgent care' },
  { display: 'Weight Loss', searchString: 'weight loss center' },
  { display: 'Wheezing', searchString: 'urgent care' },
  { display: 'X-ray', searchString: 'radiology center | urgent care' },
  { display: 'Yeast infection', searchString: 'womens clinic | urgent care' },
  { display: 'Shingles Vaccine', searchString: 'vaccines | urgent care' },
];

export interface PlaceResultWithDistance
  extends google.maps.places.PlaceResult {
  distance?: string;
}

export async function getMarketplaceResults(
  userLocation: google.maps.LatLngLiteral,
  searchLocation: google.maps.LatLngLiteral,
  healthNeedSearch: string
): Promise<PlaceResultWithDistance[] | MentalHealthAndTelemedicine[]> {
  if (
    healthNeedSearch === 'Telemedicine' ||
    healthNeedSearch === 'Mental Health'
  ) {
    switch (healthNeedSearch) {
      case 'Telemedicine':
        return getMentalHealthAndTelemedicine('Telemedicine');
      case 'Mental Health':
        return getMentalHealthAndTelemedicine('Mental Health');
      default:
        break;
    }
    return [];
  } else {
    let injectableResults: MentalHealthAndTelemedicine[] = []; // Default to empty array

    if (
      healthNeedSearch === 'Anger issues' ||
      healthNeedSearch === 'Anxiety' ||
      healthNeedSearch === 'Stress'
    ) {
      injectableResults = getMentalHealthAndTelemedicine('Mental Health');
    }

    const map = new google.maps.Map(document.createElement('div')); // Create a dummy map
    const service = new google.maps.places.PlacesService(map);

    const types = ['hospital', 'health', 'pharmacy'];

    const searchLocationString = healthNeedsSearchList.find(
      (healthNeed) =>
        healthNeed.display.toLowerCase() === healthNeedSearch.toLowerCase()
    )?.searchString;

    if (!searchLocationString) {
      throw new Error('Invalid health need search');
    }

    const createRequest = (
      type: string
    ): google.maps.places.PlaceSearchRequest => ({
      location: new google.maps.LatLng(
        searchLocation?.lat ?? 0,
        searchLocation?.lng ?? 0
      ),
      radius: 50000.0,
      keyword: searchLocationString, // get the associated search string for the health need
      type: type,
    });

    let allResults: google.maps.places.PlaceResult[] = [];

    for (const type of types) {
      try {
        const results = await new Promise<google.maps.places.PlaceResult[]>(
          (resolve, reject) => {
            service.nearbySearch(createRequest(type), (results, status) => {
              if (
                status === google.maps.places.PlacesServiceStatus.OK ||
                status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS
              ) {
                resolve(results || []);
              } else {
                reject(
                  new Error(
                    `Failed to fetch results for type ${type}: ${status}`
                  )
                );
              }
            });
          }
        );
        allResults = [...allResults, ...results];
      } catch (error) {
        console.error(error);
        throw error;
      }
    }

    // Exclude specific types from results
    const excludeTypes = ['doctor', 'veterinary_care']; // Example types to exclude
    const filteredResults = allResults.filter(
      (result) => !result.types?.some((type) => excludeTypes.includes(type))
    );

    // Fetch details for each place
    const detailedResults = await Promise.all(
      filteredResults.map(
        (result) =>
          new Promise<PlaceResultWithDistance>((resolve, reject) => {
            service.getDetails(
              { placeId: result.place_id || '' },
              async (place, status) => {
                if (status === google.maps.places.PlacesServiceStatus.OK) {
                  if (place) {
                    const placeWithDistance = place as PlaceResultWithDistance;
                    // Calculate the distance to the place
                    if (place.geometry?.location) {
                      const destination = {
                        lat: place.geometry.location.lat(),
                        lng: place.geometry.location.lng(),
                      };
                      const distance = await getDistanceToPlace(
                        userLocation,
                        destination
                      );

                      placeWithDistance.distance = distance;
                    }
                    resolve(placeWithDistance);
                  } else {
                    reject(
                      new Error(
                        `Failed to fetch details for place ${result.place_id}: Place is null`
                      )
                    );
                  }
                } else {
                  reject(
                    new Error(
                      `Failed to fetch details for place ${result.place_id}: ${status}`
                    )
                  );
                }
              }
            );
          })
      )
    );

    return [...injectableResults, ...detailedResults]; //injectableResults is an array of MentalHealthAndTelemedicine objects in case the healthNeedSearch is 'Anger issues' or 'Anxiety'
  }
}

/**
 * Calling getPlaceDetails from the google maps places service is required to get the FULL details of a place.
 *
 * A place search will return a limited set of details (even though it is the same {@link
 * google.maps.places.PlaceResult}), but to get the full details of a place, you need to call getPlaceDetails using the placeId.
 */
export async function getPlaceDetails(
  placeId: string
): Promise<google.maps.places.PlaceResult | undefined> {
  return new Promise((resolve, reject) => {
    const map = new google.maps.Map(document.createElement('div')); // Create a dummy map
    const service = new google.maps.places.PlacesService(map);

    const request = {
      placeId: placeId,
    };

    service.getDetails(request, (place, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        resolve(place || undefined);
      } else {
        reject(new Error(`Failed to get place details: ${status}`));
      }
    });
  });
}

export async function getDistanceToPlace(
  origin: google.maps.LatLngLiteral,
  destination: google.maps.LatLngLiteral
): Promise<string> {
  const distanceService = new google.maps.DistanceMatrixService();

  return new Promise((resolve, reject) => {
    distanceService.getDistanceMatrix(
      {
        origins: [origin],
        destinations: [destination],
        travelMode: google.maps.TravelMode.DRIVING,
        unitSystem: google.maps.UnitSystem.IMPERIAL,
      },
      (response, status) => {
        if (status === google.maps.DistanceMatrixStatus.OK) {
          const distance = response?.rows[0].elements[0].distance;
          resolve(distance?.text || 'N/A');
        } else {
          reject(new Error(`Failed to get distance matrix: ${status}`));
        }
      }
    );
  });
}
