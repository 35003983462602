import { useMemo } from 'react';
import { AboutMainBanner } from './components/AboutMainBanner';
import { MissionStatement } from './components/MissionStatement';
import OurTeam from './components/ourTeam/OurTeam';
import { useContentful } from '../../hooks/Contentful/useContentful';
import { useTrackPageName } from '../../hooks/GoogleAnalytics/googleAnalytics';

function AboutUs() {
  useTrackPageName();

  const mediaKitUrl =
    'https://cms.rokketmed.com/media/15/_orig/1646193429-1098.pdf';

  const queries = useMemo(() => ['aboutRokketMed', 'teamMembers'], []);

  const { contentfulData, isLoading } = useContentful(queries);

  if (isLoading) return null;

  return (
    <div className="flex flex-col w-full px-10 lg:px-28 py-14 animate-fadeIn">
      <AboutMainBanner data={contentfulData.aboutRokketMed} />

      <MissionStatement />

      <OurTeam data={contentfulData.teamMembers} />

      <div className="flex flex-col text-center items-center gap-6 mb-10">
        <h3 className="font-bold xs:text-2xl text-center text-3xl">
          Download the RokketMed Media Kit
        </h3>
        <p className="text-graySubtitle">
          Unlock insights into healthcare innovation and our visionary journey –
          everything you need to know is inside.
        </p>
        <a
          href={mediaKitUrl}
          download
          target="_blank"
          rel="noopener noreferrer"
          className="text-white bg-rokketmedMain px-5 py-2 rounded-xl hover:shadow-custom3 ease-in-out transition-all duration-200"
        >
          <i className="fas fa-download font-bold text-lg mr-2"></i>
          Download
        </a>
      </div>
    </div>
  );
}

export default AboutUs;
