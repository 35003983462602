import { MouseEvent } from 'react';

/**
 * Represents an item in the dropdown of the search bar.
 *
 * @description is the main display text of the item.
 * @props is an optional object that can be used to pass additional props to the item (for Google Places Autocomplete).
 */
export interface IDropDownItem {
  description: string;
  props?: any;
}

export interface ISearchBarDropDownProps {
  filterText?: string;
  dropDownItems: IDropDownItem[];
  headerLabel?: string;
  onDropDownItemSelected: (
    healthNeed: string,
    e: MouseEvent<HTMLDivElement>
  ) => void;
  onCurrentLocationSelected?: () => void;
  isLocation?: boolean;
  width?: string;
  topMargin?: string;
  maxHeight?: string;
}

export function SearchBarDropDown(props: ISearchBarDropDownProps): JSX.Element {
  // if the filterText is not empty, filter the DropDownItems array to only include the DropDownItems that include the filterText
  function filterDropDownItems(filterText: string) {
    if (!props.dropDownItems) {
      return [];
    }
    return props.dropDownItems
      .filter((item) => {
        return item.description
          .toLowerCase()
          .includes(filterText.toLowerCase());
      })
      .sort((a, b) => a.description.localeCompare(b.description));
  }

  return (
    <div
      style={{
        width: props.width ? props.width : '',
        maxHeight: props.maxHeight || '200px',
        overflowY: 'auto',
        marginTop: props.topMargin ? props.topMargin : '1rem',
      }}
      className={`absolute bg-white hover:cursor-pointer shadow-main overflow-auto z-10 rounded-xl ml-0 xs:text-xs lg:md:text-sm w-40 md:w-60`}
    >
      {props.headerLabel && (
        <div className='px-8 py-4 w-100 font-semibold'>{props.headerLabel}</div>
      )}

      {props.isLocation && (
        <div
          className='px-8 py-4 hover:bg-neutral-300 m-0 w-100 border-b-2 border-gray-300'
          onClick={(e) => {
            e.stopPropagation();
            props.onCurrentLocationSelected?.();
          }}
        >
          <i
            className='fa fa-map-marker-alt mr-2 text-gray-400'
            aria-hidden='true'
          ></i>
          Current Location
        </div>
      )}

      {filterDropDownItems(props.filterText || '').map((item, index) => (
        <div
          key={index}
          className='px-4 md:px-8 py-4 hover:bg-neutral-300 m-0 w-100'
          {...item.props}
          onClick={(e) => {
            e.stopPropagation();
            props.onDropDownItemSelected(item.description, e);
          }}
        >
          {item.description}
        </div>
      ))}

      {/* if the user has not entered any text and there are no available dropdown items, show a message to search */}
      {!props.filterText && props.dropDownItems?.length === 0 && (
        <div className='px-8 py-4 m-0 w-100'>Start Typing to Search</div>
      )}
    </div>
  );
}
