import { useState } from 'react';
import { SearchableDropDownInput } from '../../../components/utils/SearchableDropDownInput';
import { EmailData, sendEmail } from '../../../hooks/sendEmail';
import {
  FormErrorMessage,
  FormInput,
} from '../../../components/utils/SearchBar/FormInput';
import { listOfEveryUsState } from '../../../assets/Data/StateData';

export interface IContactUsFormProps {}

export interface ContactUsInfo {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  companyName: string;
  city: string;
  state: string;
  question: string;
}

export function ContactUsForm(props: IContactUsFormProps) {
  const [formKey, setFormKey] = useState(0);

  const [confirmed, setConfirmed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [city, setCity] = useState('');
  const [stateValue, setStateValue] = useState('');
  const [question, setQuestion] = useState('');

  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    companyName: '',
    city: '',
    stateValue: '',
    question: '',
  });

  function triggerConfirmation() {
    setConfirmed(true);
    setTimeout(() => {
      setConfirmed(false);
    }, 5000);
  }

  function resetForm() {
    setFirstName('');
    setLastName('');
    setEmail('');
    setPhoneNumber('');
    setCompanyName('');
    setCity('');
    setStateValue('');
    setQuestion('');
  }

  function handleLocationSelected(location: string): void {
    setStateValue(location);
  }

  function validateForm(): boolean {
    let formErrors = {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      companyName: '',
      city: '',
      stateValue: '',
      question: '',
    };

    if (!firstName) {
      formErrors.firstName = 'First Name is required';
    }
    if (!lastName) {
      formErrors.lastName = 'Last Name is required';
    }
    if (!email) {
      formErrors.email = 'Email is required';
    }
    if (!phoneNumber) {
      formErrors.phoneNumber = 'Phone Number is required';
    }
    if (!companyName) {
      formErrors.companyName = 'Company Name is required';
    }
    if (!city) {
      formErrors.city = 'City is required';
    }
    if (!stateValue) {
      formErrors.stateValue = 'State is required';
    }
    if (!question) {
      formErrors.question = 'Question is required';
    }

    setErrors(formErrors);

    return !Object.values(formErrors).some((error) => error);
  }

  async function handleSubmit(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
      const emailData: EmailData = {
        subject: 'Contact Us Form Submission',
        data: {
          firstName,
          lastName,
          email,
          phoneNumber,
          companyName,
          city,
          state: stateValue,
          question,
        },
      };

      setLoading(true);

      await sendEmail(emailData);

      setLoading(false);
      triggerConfirmation();
      resetForm();
      setFormKey((prevKey) => prevKey + 1);
    } catch (error) {
      setLoading(false);
      setHasError(true);
      console.error(error);
    }
  }

  return (
    <form
      key={formKey}
      action="#"
      autoComplete="new-password"
      className="bg-white p-10 rounded-xl mt-10 lg:mt-0 lg:ml-20 inline-block lg:w-96"
    >
      <div className="flex gap-2">
        <FormInput
          onChange={setFirstName}
          placeholder="First Name*"
          errorMessage={errors.firstName}
          width="1/2"
        />

        <FormInput
          onChange={setLastName}
          placeholder="Last Name*"
          errorMessage={errors.lastName}
          width="1/2"
        />
      </div>

      <FormInput
        onChange={setEmail}
        placeholder="Email*"
        errorMessage={errors.email}
        type="email"
      />

      <FormInput
        onChange={(value) => setPhoneNumber(value)}
        placeholder="Phone Number*"
        errorMessage={errors.phoneNumber}
        type="tel"
      />

      <FormInput
        onChange={setCompanyName}
        placeholder="Company Name*"
        errorMessage={errors.companyName}
      />

      <div className="flex gap-2">
        <FormInput
          onChange={setCity}
          placeholder="City*"
          errorMessage={errors.city}
          width="1/2"
        />

        <div className="w-1/2 mb-3">
          <SearchableDropDownInput
            inputPlaceholder="State*"
            handleDropDownItemSelected={handleLocationSelected}
            dropDownItems={listOfEveryUsState}
          />
          <FormErrorMessage message={errors.stateValue} />
        </div>
      </div>

      <div className="mb-3">
        <textarea
          onChange={(e) => setQuestion(e.target.value)}
          className="w-full border-2 border-gray-200 rounded-md px-5 py-2 text-xs"
          placeholder="Question*"
          cols={30}
          rows={4}
        />
        <FormErrorMessage message={errors.question} />
      </div>

      <button
        onClick={(e) => {
          handleSubmit(e);
        }}
        disabled={loading}
        className="bg-rokketmedMain px-5 py-2 text-white w-full rounded-lg"
      >
        Submit
      </button>

      {loading && (
        <p className="text-[#E30C79] font-bold w-full text-center p-2 animate-fadeIn">
          Loading...
        </p>
      )}

      {confirmed && (
        <p className="text-[#E30C79] font-bold w-full text-center p-2 animate-fadeIn">
          Your request has been sent!
        </p>
      )}

      {hasError && (
        <p className="text-red-500 font-bold w-full text-center p-2 animate-fadeIn">
          There was an error sending your request. Please try again.
        </p>
      )}
    </form>
  );
}
