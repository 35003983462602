import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

const LocationContext = createContext<{
  location: google.maps.LatLngLiteral | null;
  getUserLocation: () => Promise<google.maps.LatLngLiteral>;
}>({
  location: null,
  getUserLocation: () => {
    throw new Error('useUserLocation must be used within a LocationProvider');
  },
});

export const UserLocationProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [location, setLocation] = useState<google.maps.LatLngLiteral | null>(
    null
  );

  const getUserLocation =
    useCallback((): Promise<google.maps.LatLngLiteral> => {
      return new Promise((resolve, reject) => {
        if (!navigator.geolocation) {
          reject(new Error('Geolocation is not supported by your browser'));
        } else {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              const newLocation = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              };
              resolve(newLocation);
            },
            (error) => {
              console.error(error);
              reject(error);
            },
            { enableHighAccuracy: false, timeout: 5000, maximumAge: 0 }
          );
        }
      });
    }, []);

  useEffect(() => {
    let isMounted = true;

    getUserLocation()
      .then((location) => {
        if (isMounted) {
          setLocation(location);
        }
      })
      .catch((error) => {
        console.error('Error getting user location:', error);
      });

    return () => {
      isMounted = false;
    };
  }, [getUserLocation]);

  return (
    <LocationContext.Provider
      value={{
        location,
        getUserLocation,
      }}
    >
      {children}
    </LocationContext.Provider>
  );
};

export function useUserLocation(): {
  location: google.maps.LatLngLiteral | null;
  getUserLocation: () => Promise<google.maps.LatLngLiteral>;
} {
  const location = useContext(LocationContext);
  if (location === undefined || location === null) {
    throw new Error('useUserLocation must be used within a LocationProvider');
  }
  return location;
}
