import {
  ISearchBarProps,
  SelectedLocation,
} from '../../../components/utils/SearchBar/SearchBar';
import { useUserLocation } from '../../../providers/UserLocationProvider';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';
import cutsIcon from '../../../assets/Icons/cuts.svg';
import earInfectionIcon from '../../../assets/Icons/earAche.svg';
import fluIcon from '../../../assets/Icons/cold.svg';
import mentalHealthIcon from '../../../assets/Icons/mentalHealth.svg';
import rapidTestsIcon from '../../../assets/Icons/tests.svg';
import rashIcon from '../../../assets/Icons/rash.svg';
import soreThroatIcon from '../../../assets/Icons/soreThroat.svg';
import sprainsIcon from '../../../assets/Icons/sprains.svg';
import stiIcon from '../../../assets/Icons/std.svg';
import telemedicineIcon from '../../../assets/Icons/telemedicine.svg';
import { getHumanReadableLocation } from '../../../components/utils/SearchBar/services';
import { useState } from 'react';

export interface ILandingPagehealthcareGridProps {}

export function LandingPageHealthcareGrid(
  props: ILandingPagehealthcareGridProps
) {
  const navigate = useNavigate();

  let { getUserLocation } = useUserLocation();

  const [isLoading, setIsLoading] = useState<{ [key: string]: boolean }>({});

  async function navigateToMarketplace(searchTerm: string): Promise<void> {
    try {
      setIsLoading((prevState) => ({ ...prevState, [searchTerm]: true }));

      console.time('getUserLocation');
      let userLocation = await getUserLocation();
      console.timeEnd('getUserLocation');

      console.time('getHumanReadableLocation');
      const locationName = await getHumanReadableLocation(userLocation);
      console.timeEnd('getHumanReadableLocation');

      const location: SelectedLocation = {
        latLong: userLocation,
        description: locationName,
      };

      const state: ISearchBarProps = {
        healthNeed: searchTerm,
        searchLocation: location,
      };

      console.time('navigate');
      navigate('/marketplace', { state: state });
      console.timeEnd('navigate');
    } catch (error) {
      console.time('errorHandling');
      const location: SelectedLocation = {
        latLong: { lat: 37.7749, lng: -122.4194 },
        description: 'San Francisco, CA',
      };

      const state: ISearchBarProps = {
        healthNeed: searchTerm,
        searchLocation: location,
      };

      navigate('/marketplace', { state: state });

      console.error('Error navigating to marketplace', error);
      console.timeEnd('errorHandling');
    } finally {
      setIsLoading((prevState) => ({ ...prevState, [searchTerm]: false }));
    }
  }

  function handleNavigateToMarketplace(searchTerm: string): void {
    ReactGA.event({
      category: 'Healthcare Grid',
      action: 'Healthcare Grid Button Clicked',
      label: searchTerm,
    });
    navigateToMarketplace(searchTerm);
  }
  return (
    <div className="grid max-w-[120rem] grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 lg:gap-8 gap-6 lg:px-20 xl:px-36 2xl:px-[10vw] p-5 items-center justify-items-center align-items-center">
      <HealthServicesButton
        title="Telemedicine"
        price="$29 - $125"
        icon={telemedicineIcon}
        onClick={() => handleNavigateToMarketplace('Telemedicine')}
        isLoading={isLoading['Telemedicine']}
      />
      <HealthServicesButton
        title="Mental Health"
        price="$59 - $250"
        icon={mentalHealthIcon}
        onClick={() => handleNavigateToMarketplace('Mental Health')}
        isLoading={isLoading['Mental Health']}
      />
      <HealthServicesButton
        title="Cold, Cough, & Flu"
        price="$30 - $250"
        icon={fluIcon}
        onClick={() => handleNavigateToMarketplace('Cold, Cough, & Flu')}
        isLoading={isLoading['Cold, Cough, & Flu']}
      />
      <HealthServicesButton
        title="Ear Infection"
        price="$30 - $300"
        icon={earInfectionIcon}
        onClick={() => handleNavigateToMarketplace('Ear Infection')}
        isLoading={isLoading['Ear Infection']}
      />
      <HealthServicesButton
        title="Rapid Test"
        price="$75 - $300"
        icon={rapidTestsIcon}
        onClick={() => handleNavigateToMarketplace('Rapid Test')}
        isLoading={isLoading['Rapid Test']}
      />
      <HealthServicesButton
        title="Rash"
        price="$30 - $250"
        icon={rashIcon}
        onClick={() => handleNavigateToMarketplace('Rash')}
        isLoading={isLoading['Rash']}
      />
      <HealthServicesButton
        title="Severe Cuts"
        price="$99 - $350"
        icon={cutsIcon}
        onClick={() => handleNavigateToMarketplace('Severe Cuts')}
        isLoading={isLoading['Severe Cuts']}
      />
      <HealthServicesButton
        title="Sore Throat"
        price="$30 - $250"
        icon={soreThroatIcon}
        onClick={() => handleNavigateToMarketplace('Sore Throat')}
        isLoading={isLoading['Sore Throat']}
      />
      <HealthServicesButton
        title="Sprains & Strains"
        price="$150 - $450"
        icon={sprainsIcon}
        onClick={() => handleNavigateToMarketplace('Sprains & Strains')}
        isLoading={isLoading['Sprains & Strains']}
      />
      <HealthServicesButton
        title="STI"
        price="$40 - $150"
        icon={stiIcon}
        onClick={() => handleNavigateToMarketplace('STI')}
        isLoading={isLoading['STI']}
      />
    </div>
  );
}

export interface IHealthServicesButtonProps {
  title: string;
  price: string;
  icon: string;
  onClick?: () => void;
  isLoading?: boolean;
}

export function HealthServicesButton(props: IHealthServicesButtonProps) {
  return (
    <button
      onClick={props.onClick}
      className="bg-white lg:w-48 w-40 aspect-square rounded-lg shadow-main flex flex-col items-center justify-between border border-gray-100 hover:shadow-custom3 ease-in-out transition-all duration-200"
      disabled={props.isLoading}
    >
      <div className="flex-grow flex items-center justify-center">
        {props.isLoading ? (
          <div className="flex flex-col items-center text-xm text-blue-500 font-semibold gap-2">
            <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-blue-500"></div>
            Loading...
          </div>
        ) : (
          <img
            src={props.icon}
            alt=""
            className="text-center text-5xl font-light"
          />
        )}
      </div>
      <div className="text-center pb-4">
        <p>{props.title}</p>
        <p className="text-rokketmedMain">{props.price}</p>
      </div>
    </button>
  );
}
