import { useEffect, useMemo, useRef, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import {
  ISearchBarProps,
  SearchBar,
  SelectedLocation,
} from '../../components/utils/SearchBar/SearchBar';
import { HealthcareQuickLinks } from '../../components/ui/HealthcareQuickLinks';
import { MarketplaceResults } from './components/MarketplaceResults';
import { MentalHealthAndTelemedicine } from './Services/telemedicineAndMentalHealthResults';
import {
  PlaceResultWithDistance,
  getMarketplaceResults,
} from './Services/services';
import {
  useTrackMarktetplaceSearch,
  useTrackPageName,
} from '../../hooks/GoogleAnalytics/googleAnalytics';

export enum loadingType {
  Loading,
  Complete,
  Error,
}

function MarketPlace() {
  useTrackPageName();
  const navigate = useNavigate();
  const location = useLocation();

  const [results, setResults] = useState<
    (PlaceResultWithDistance | MentalHealthAndTelemedicine)[]
  >([]);
  const [loading, setLoading] = useState<loadingType>(loadingType.Loading);
  const searchbarData1 = location.state as ISearchBarProps;
  const [searchbarData, setSearchbarData] = useState<
    ISearchBarProps | undefined
  >(searchbarData1);

  useTrackMarktetplaceSearch(searchbarData?.healthNeed || '');

  const defaultLocation = useMemo(() => ({ lat: 0, lng: 0 }), []);

  const searchBarRef = useRef<HTMLDivElement>(null);
  const healthcareQuickLinksRef = useRef<HTMLDivElement>(null);
  const [resultsHeight, setResultsHeight] = useState('0px');

  useEffect(() => {
    const handleResize = () => {
      const searchBarHeight = searchBarRef.current?.clientHeight || 0;
      const healthcareQuickLinksHeight =
        healthcareQuickLinksRef.current?.clientHeight || 0;
      const windowHeight = window.innerHeight;
      const resultsHeight =
        windowHeight - searchBarHeight - healthcareQuickLinksHeight;
      setResultsHeight(`${resultsHeight - 24}px`); // 24px to account for the margin between the search bar and the results
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    try {
      if (searchbarData?.healthNeed && searchbarData?.searchLocation) {
        setLoading(loadingType.Loading);
        getMarketplaceResults(
          searchbarData?.searchLocation.latLong || defaultLocation,
          searchbarData?.searchLocation.latLong || defaultLocation,
          searchbarData?.healthNeed
        )
          .then(setResults)
          .catch((error) => {
            console.error(error);
            setLoading(loadingType.Error);
          })
          .finally(() => setLoading(loadingType.Complete));
      } else {
        // Redirect to the landing page if the user didn't select a health need and location
        console.error('No search data', searchbarData);
        navigate('/');
      }
    } catch (error) {
      console.error(error);
      setLoading(loadingType.Error);
    }
  }, [searchbarData, navigate, defaultLocation]);

  function handleSearchSubmit(healthNeed: string, location: SelectedLocation) {
    const state: ISearchBarProps = {
      healthNeed,
      searchLocation: location,
    };
    setSearchbarData(state);
  }

  return (
    <div className='flex w-full flex-col items-center mt-6 animate-fadeIn'>
      <div className='flex flex-col items-center w-full' ref={searchBarRef}>
        <div className='h-[4.25rem] w-[90%] md:w-7/12 flex'>
          <SearchBar
            healthNeed={searchbarData?.healthNeed}
            searchLocation={searchbarData?.searchLocation}
            onSubmit={handleSearchSubmit}
          />
        </div>

        <div ref={healthcareQuickLinksRef}>
          <HealthcareQuickLinks />
        </div>
      </div>

      <div className={`w-full`} style={{ height: resultsHeight }}>
        <MarketplaceResults
          results={results}
          loading={loading}
          userLocation={
            searchbarData?.searchLocation?.latLong || defaultLocation
          }
        />
      </div>
    </div>
  );
}

export default MarketPlace;
