import * as React from 'react';
export interface IFormInputProps {
  onChange: (companyName: string) => void;
  errorMessage?: string;
  height?: string;
  padding?: number;
  placeholder: string;
  type?: 'text' | 'email' | 'number' | 'tel';
  value?: string;
  width?: string;
}

export function FormInput(props: IFormInputProps) {
  // handle pattern for email and phone number
  const pattern =
    props.type === 'email'
      ? '[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$'
      : props.type === 'tel'
      ? '[0-9]{3}-[0-9]{3}-[0-9]{4}'
      : undefined;

  return (
    <div className={`mb-3 ${props.width ? `w-${props.width}` : 'w-full'} `}>
      <input
        onChange={(e) => props.onChange(e.target.value)}
        type={props.type ?? 'text'}
        className={`w-full ${
          props.height ? `w-${props.height}` : 'w-12'
        } border-2 border-gray-200 rounded-md ${
          props.padding ? `p-${props.padding}` : 'p-5'
        } text-xs`}
        placeholder={props.placeholder}
        pattern={pattern}
        value={props.value}
      />
      {props.errorMessage && <FormErrorMessage message={props.errorMessage} />}
    </div>
  );
}

export interface IFormErrorMessageProps {
  message: string;
}

export function FormErrorMessage(props: IFormErrorMessageProps) {
  if (!props.message) return null;
  return (
    <div className="text-red-500 text-xs ml-2 animate-fadeIn">
      {props.message}
    </div>
  );
}
