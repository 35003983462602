import * as React from 'react';
import { HealthcareQuickLinks } from '../../../components/ui/HealthcareQuickLinks';
import { LandingPageHealthcareGrid } from './LandingPageHealthcareGrid';

export interface ILandingPageTophealthcareNeedsProps {
  sectionRef: React.RefObject<HTMLDivElement>;
}

export function LandingPageTophealthcareNeeds(
  props: ILandingPageTophealthcareNeedsProps
) {
  return (
    <div
      className="flex flex-col items-center px-5 pt-12 lg:pt-24 w-full"
      ref={props.sectionRef}
    >
      <h2 className="text-center lg:text-3xl text-2xl font-semibold">
        Top 10 Healthcare Needs
      </h2>
      <HealthcareQuickLinks size="lg" />
      <LandingPageHealthcareGrid />
    </div>
  );
}
