export interface ITestimonialProps {
  quote?: string;
  author?: string;
  authorTitle?: string;
  company?: string;
  image?: string;
  textColor?: string;
  minHeight?: string;
}

export function Testimonial(props: ITestimonialProps) {
  return (
    <div
      style={{
        color: props.textColor ? props.textColor : 'black',
        minHeight: props.minHeight ? props.minHeight : '24rem',
      }}
      className="w-full px-4 lg:px-16 mb-24 lg:mb-16 items-center justify-center flex flex-col"
    >
      <div className="flex flex-col items-center text-center h-24 w-24 m-5 self-center rounded-2xl">
        {props.image ? (
          <img
            src={props.image}
            className="h-full w-full object-fit rounded-2xl"
            alt=""
          />
        ) : (
          <div className="bg-rokketmedMain aspect-square h-16 rounded-full m-5 text-4xl text-white flex items-center justify-center">
            <i className="fa-solid fa-user-tie"></i>
          </div>
        )}
      </div>

      <div className="text-center w-8/12">
        <p className="text-sm lg:text-2xl font-normal mb-5">{props.quote}</p>

        <p className="text-sm">
          <span className="font-bold">{props.author}</span>
          {props.authorTitle && ' - ' + props.authorTitle}

          {props.company && ' / ' + props.company}
        </p>
      </div>
    </div>
  );
}
