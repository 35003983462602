import * as React from 'react';
import { MarketplaceResult } from './marketplaceResult/MarketplaceResult';
import { useEffect, useRef } from 'react';
import { PlaceResultWithDistance } from '../../Services/services';
import { MentalHealthAndTelemedicine } from '../../Services/telemedicineAndMentalHealthResults';

export interface IMarketplaceResultsListProps {
  results: (PlaceResultWithDistance | MentalHealthAndTelemedicine)[];
  focusPlaceId?: string;
}

export function MarketplaceResultsList(props: IMarketplaceResultsListProps) {
  const selectedPlaceRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (props.focusPlaceId) {
      selectedPlaceRef.current?.scrollIntoView({
        behavior: 'smooth',
      });
    } else {
      console.log('No focus place id', props.focusPlaceId);
    }
  }, [props.focusPlaceId]);

  return (
    <div id='results' className='w-full h-full overflow-y-scroll'>
      {props.results.map((result, index) => {
        return (
          <MarketplaceResult
            resultDetails={result}
            key={index}
            isClosestMatch={index === 0 ? true : false}
            ref={
              'place_id' in result &&
              result.place_id !== undefined &&
              result.place_id === props.focusPlaceId
                ? selectedPlaceRef
                : null
            }
            isFocused={
              'place_id' in result &&
              result.place_id !== undefined &&
              result.place_id === props.focusPlaceId
            }
          />
        );
      })}
    </div>
  );
}
