import { SearchBarDropDown } from './SearchBarDropDown';

// HealthNeedInput component
export interface HealthNeedInputProps {
  healthNeedInput: string;
  setHealthNeedInput: (value: string) => void;
  healthNeedValid: boolean;
  setHealthNeedValid: (value: boolean) => void;
  healthNeedsOpen: boolean;
  setHealthNeedsOpen: (value: boolean) => void;
  handleHealthNeedSelected: (healthNeed: string) => void;
  healthNeeds: string[];
}

export function HealthNeedInput(props: HealthNeedInputProps) {
  return (
    <div className="pl-8 lg:pl-10 py-1 w-[45%]">
      <label htmlFor="conditionInput" className="color-textLight text-xs p-0">
        Health Need
      </label>
      <input
        id="conditionInput"
        type="text"
        placeholder="Cold, Cough, & Flu"
        className={`flex-1 w-full h-8 xs:text-xs lg:md:text-sm lg:text-lg outline-none rounded-xl p-0 font-normal focus:border-red-500 overflow-x-hidden ${
          !props.healthNeedValid
            ? 'border border-red-500 bg-red-50'
            : 'border-none focus:ring-0'
        }`}
        onClick={() => {
          props.setHealthNeedValid(true);
          props.setHealthNeedsOpen(true);
        }}
        onBlur={() => setTimeout(() => props.setHealthNeedsOpen(false), 200)}
        onChange={(e) => props.setHealthNeedInput(e.target.value ?? '')}
        value={props.healthNeedInput}
        autoComplete="off"
      />

      {props.healthNeedsOpen && (
        <SearchBarDropDown
          filterText={props.healthNeedInput}
          onDropDownItemSelected={props.handleHealthNeedSelected}
          dropDownItems={
            // convert healthNeeds to an array of IDropDownItem objects
            props.healthNeeds.map((healthNeed) => ({
              description: healthNeed,
            }))
          }
        />
      )}
    </div>
  );
}
