import { NavLink } from 'react-router-dom';

export interface IPageNotFoundProps {}

export function PageNotFound(props: IPageNotFoundProps) {
  return (
    <div className='w-full flex flex-col items-center justify-center align-middle gap-5'>
      <h1 className='text-4xl font-bold'>Page Not Found...</h1>

      <p>The page you are looking for does not exist.</p>

      <NavLink to='/' className='text-blue-500 underline'>
        Go back to the home page
      </NavLink>
    </div>
  );
}
