import { NavLink } from 'react-router-dom';
enum Size {
  small = 'small',
  medium = 'normal',
}

interface FooternavLinkProps {
  to: string;
  display: string;
  size?: Size.small | Size.medium;
}
function FooternavLink(props: FooternavLinkProps) {
  return (
    <NavLink
      to={props.to}
      className="text-xs md:p-4 xl:p-4 lg:p-4 xs:p-3 lg:text-sm hover:underline"
      style={{
        alignSelf: 'start',
        color: 'rgba(255, 255, 255, 1)',
      }}
    >
      {props.display}
    </NavLink>
  );
}

export { FooternavLink, Size };
