import { TestimonialCarousel } from '../../components/utils/TestimonialCarousel';
import { HealthCardForm } from './components/HealthCardForm';
import { HealthCardInfo } from './components/HealthCardInfo';
import { Testimonial } from '../../components/utils/Testimonial';

import { useContentful } from '../../hooks/Contentful/useContentful';
import { Key, useMemo } from 'react';
import { useTrackPageName } from '../../hooks/GoogleAnalytics/googleAnalytics';
import { TestimonialData } from '../../hooks/Contentful/Types/ContentfulTypes';

function BecomeACardholder() {
  useTrackPageName();

  const queries = useMemo(() => ['becomeACardHolder', 'testimonials'], []);

  const { contentfulData, isLoading } = useContentful(queries);

  if (isLoading) {
    return null;
  }

  return (
    <div className="bg-rokketmedMain w-full flex flex-col items-center animate-fadeIn p-10 lg:p-20">
      <div className="flex flex-col lg:flex-row items-start mb-10">
        <HealthCardInfo data={contentfulData.becomeACardHolder} />
        <HealthCardForm />
      </div>

      <TestimonialCarousel
        rounded
        customControls={{
          leftControl: (
            <div className="p-5 h-8 w-8 rounded-full flex items-center justify-center hover:bg-gray-200 ease-in-out transition-all duration-200">
              <i className="fas fa-arrow-left text-rokketmedMain text-2xl"></i>
            </div>
          ),
          rightControl: (
            <div className="p-5 h-8 w-8 rounded-full flex items-center justify-center hover:bg-gray-200 ease-in-out transition-all duration-200">
              <i className="fas fa-arrow-right text-rokketmedMain text-2xl"></i>
            </div>
          ),
        }}
      >
        {contentfulData.testimonials &&
          contentfulData.testimonials.map(
            (testimonial: TestimonialData, index: Key | null | undefined) => (
              <Testimonial
                key={index}
                image={testimonial.logo}
                quote={testimonial.testimonial}
                author={testimonial.name}
                authorTitle={testimonial.position}
                textColor="black"
              />
            )
          )}
      </TestimonialCarousel>
    </div>
  );
}

export default BecomeACardholder;
