import * as React from 'react';

export interface IContactUsInfoProps {}

export function ContactUsInfo(props: IContactUsInfoProps) {
  return (
    <div>
      <div className="mb-14">
        <h1 className="text-white xs:text-3xl lg:text-4xl font-bold pb-2">
          How can we help you?
        </h1>
        <h3 className="text-white xs:text-xl lg:text-2xl">
          Fill out the form to reach us at any time.
        </h3>
      </div>

      <div className="list-none text-white">
        <div className="my-6 flex gap-5">
          <i className="fa-regular fa-credit-card text-2xl"></i>
          <div>
            <h5 className="text-xl font-medium">Health Card</h5>
            <p className="text-sm mt-2">Cardholder with questions?</p>
            <p className="text-sm mt-2">Ask here.</p>
          </div>
        </div>

        <div className="my-6 flex gap-5">
          <i className="fa-solid fa-shop text-2xl"></i>
          <div>
            <h5 className="text-xl font-medium">Provider</h5>
            <p className="text-sm mt-2">Interested in joining our network?</p>
            <p className="text-sm mt-2">Let's connect.</p>
          </div>
        </div>

        <div className="my-6 flex gap-5">
          <i className="fa-solid fa-clipboard text-3xl"></i>
          <div>
            <h5 className="text-xl font-medium">General Inquiries</h5>
            <p className="text-sm mt-2">We’re here to help.</p>
            <p className="text-sm mt-2">Reach out.</p>
          </div>
        </div>

        <div className="my-6 flex gap-5">
          <i className="fa-solid fa-phone text-2xl"></i>
          <div>
            <h5 className="text-xl font-medium">Support Line</h5>
            <p className="text-sm mt-2">
              Call (213) 221-4707 to reach us during office hours.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
