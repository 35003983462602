import { useEffect, useRef, useState } from 'react';
import { SearchBarDropDown } from './SearchBar/components/SearchBarDropDown';

export interface ISearchableDropDownInputProps {
  inputPlaceholder: string;
  handleDropDownItemSelected: (location: string) => void;
  dropDownItems: string[];
}

export function SearchableDropDownInput(props: ISearchableDropDownInputProps) {
  const input = useRef<HTMLInputElement | null>(null); // reference to the input element (for width calculation)
  const [inputWidth, setInputWidth] = useState<null | string>(null); // width of the input element to pass to the dropdown
  const [inputValue, setInputValue] = useState<string>(''); // value to display in the input element
  const [dropDownOpen, setDropDownOpen] = useState<boolean>(false); // whether the dropdown is open

  useEffect(() => {
    if (input.current) {
      setInputWidth(`${input.current.offsetWidth}px`);
    }
  }, [input]);

  function handleDropDownItemSelected(value: string) {
    setInputValue(value);
    props.handleDropDownItemSelected(value);
    setDropDownOpen(false);
  }

  return (
    <div>
      <input
        ref={input}
        placeholder={props.inputPlaceholder ?? ''}
        autoComplete='new-password'
        className={`h-12 border-2 border-gray-200 rounded-md p-5 text-xs w-full`}
        onClick={() => {
          setDropDownOpen(true);
        }}
        onBlur={() => setTimeout(() => setDropDownOpen(false), 200)}
        onChange={(e) => setInputValue(e.target.value)}
        value={inputValue}
      />

      {dropDownOpen && (
        <SearchBarDropDown
          filterText={inputValue}
          onDropDownItemSelected={handleDropDownItemSelected}
          dropDownItems={props.dropDownItems.map((state) => ({
            description: state,
          }))}
          width={inputWidth ?? undefined}
          topMargin='0em'
          maxHeight='12em'
        />
      )}
    </div>
  );
}
